import React from 'react';
import { Box, ThemeProvider } from '@material-ui/core';
import L from 'leaflet';
import MomentUtils from '@date-io/moment';
import ReactDOM from 'react-dom';
import themes from 'src/themes';

import { humanReadableArrayOfStrings as hraos } from 'src/utils/util';
import { getRandomNviroColor, transparentize } from 'src/utils/util';
import { SmaAnalyticMapLegend } from 'src/scenes/Project/scenes/Analytics/SmaAnalyticMapLegend';
import { truncateWithEllipsis as twe } from 'src/utils/util';
import { renderToStaticMarkup } from 'react-dom/server';
import { OccurrencePopup } from 'src/scenes/Project/scenes/Analytics/components/OccurrencePopup';


const { moment } = new MomentUtils();

const _getNames = optionsList => hraos(optionsList.map(option => option.label));

const buildChartTitle = props => {

  const { hasResults, selectedSpecies, selectedReplicaStations, selectedMonitoringInstances,
    valuesToChartLabel, selectedChartType } = props;

  const hasReadableSpeciesNames = 1 <= selectedSpecies.length && selectedSpecies.length <= 3;
  const hasReadableReplicaStationNames = 1 <= selectedReplicaStations.length && selectedReplicaStations.length <= 3;
  const hasReadableMonitoringNames = 1 <= selectedMonitoringInstances.length && selectedMonitoringInstances.length <= 3;

  const valueText = hasResults ? `${hraos(valuesToChartLabel)}` : 'Sin resultados';

  if (selectedChartType.value === 'instances') {
    const speciesText = `de ${hasReadableSpeciesNames ? _getNames(selectedSpecies) : 'especies'}`;
    const replicaStationText = `en ${hasReadableReplicaStationNames ? _getNames(selectedReplicaStations) : 'todas las estaciones replica'}`;
    const monitoringInstanceText = hasReadableMonitoringNames ? `para ${_getNames(selectedMonitoringInstances)}` : 'por monitoreo';

    return [ valueText, speciesText, replicaStationText, monitoringInstanceText ].join(' ');
  } else if (selectedChartType.value === 'stations') {
    const speciesText = `de ${hasReadableSpeciesNames ? _getNames(selectedSpecies) : 'especies'}`;
    const monitoringInstanceText = `en ${hasReadableMonitoringNames ? _getNames(selectedMonitoringInstances) : 'todos los monitoreos'}`;
    const replicaStationText = hasReadableReplicaStationNames ?
      `para ${hraos(selectedReplicaStations.map(s => s.label))}` : 'por estación replica';

    return [ valueText, speciesText, monitoringInstanceText, replicaStationText ].join(' ');
  } else if (selectedChartType.value === 'species') {
    const speciesText = hasReadableSpeciesNames ? `de ${hraos(selectedSpecies.map(ss => ss.label))}` : 'por especie';
    const replicaStationText = `en ${hasReadableReplicaStationNames ?
      hraos(selectedReplicaStations.map(s => s.label)) : 'todas las estaciones replica'}`;
    const monitoringInstanceText = hasReadableMonitoringNames ?
      `para ${hraos(selectedMonitoringInstances.map(s => s.label))}` : 'para todos los monitoreos';

    return [ valueText, speciesText, replicaStationText, monitoringInstanceText ].join(' ');
  } else {
    const speciesText = `de ${hasReadableSpeciesNames ? _getNames(selectedSpecies) : 'especies'}`;
    const replicaStationText = `en ${hasReadableReplicaStationNames ? _getNames(selectedReplicaStations) : 'todas las estaciones replica'}`;
    const monitoringInstanceText = `para ${hasReadableMonitoringNames ? _getNames(selectedMonitoringInstances) : 'todos los monitoreos'}`;

    return [ valueText, speciesText, replicaStationText, monitoringInstanceText, 'por', selectedChartType.label.toLowerCase() ].join(' ');
  }
};

const _datasetDefaultOptions = {
  borderRadius: 1,
  borderWidth: 2,
  barPercentage: 0.5,
};

const sortData = ({ sortType, labels, datasets }) => {
  let sortedData;
  if (datasets.length === 1) {
    const dataAndLabel = datasets[0].data.map((d, idx) => ({ label: labels[idx], data: d }));
    sortedData = dataAndLabel.sort((a, b) => sortType === 'asc' ? b.data - a.data : a.data - b.data);

  }
  return { sortedLabels: sortedData.map(sd => sd.label), sortedDatasets: [ { ...datasets[0], data: sortedData.map(sd => sd.data) } ] };
};

const buildDataset = ({ label, data, options = {} }) => {
  const color = getRandomNviroColor();
  return ({
    label,
    data,
    backgroundColor: transparentize(color, 0.4),
    borderColor: color,
    ..._datasetDefaultOptions,
    ...options,
  });
};

const richnessToChartOption = { label: 'Riqueza', value: 'richness' };
const richnessToChartOptionDisabled = { ...richnessToChartOption, disabled: true };

const buildUrl = ({ monitoringInstances, species, replicaStations, columnName }) => {
  const query = new URLSearchParams();
  monitoringInstances.forEach(mi => query.append('monitoringInstanceId', mi.value));
  species.forEach(ss => query.append('species', ss.value));
  replicaStations.forEach(rs => query.append('stationReplicateId', rs.data.id));
  columnName && query.append('columnName', columnName);
  return query.toString();
};

const buildChartData = ({ valuesToChart, separatedKey, separatedInfo, dataToChart, hasSomeSeparatedData, sortType }) => {

  const initialSeparatedData = hasSomeSeparatedData ? Object.keys(separatedInfo).reduce((a, v) => ({ ...a, [v]: [] }), {}) : [];

  const isMultiVariable = valuesToChart.length > 1;

  let { labels, all, separatedData } = dataToChart.reduce(({ labels, all, separatedData }, d) => {

    labels.push(d.name);

    if (isMultiVariable) {
      Object.keys(separatedInfo).forEach(value => {
        d.parameters[value] ? separatedData[value].push(d.parameters[value].sumTotal) : separatedData[value].push(0);
      });
    } else {
      const parameter = d.parameters[valuesToChart[0]];
      const parameterValue = parameter?.sumTotal || 0;
      all.push(parameterValue);
      if (hasSomeSeparatedData) {
        Object.keys(separatedInfo).forEach(value => {
          separatedData[value].push(parameter ?
            parameter[separatedKey].find(x => separatedKey === 'species' ?
              value === x.id : parseInt(value, 10) === parseInt(x.id, 10))?.sum : undefined,
          );
        });
      }
    }
    return { labels, all, separatedData };
  }, { labels: [], all: [], separatedData: initialSeparatedData });

  const translateChartType = chartType => {
    const translated = {
      occurrences: 'Ocurrencias',
      richness: 'Especies',
    };
    return translated[chartType] || chartType;
  };

  let datasets = (
    hasSomeSeparatedData ?
      Object.keys(separatedData).map(key => buildDataset({ label: separatedInfo[key], data: separatedData[key] }))
      : [ buildDataset({ label: translateChartType(valuesToChart[0]), data: all }) ]
  );

  if (sortType) {
    const { sortedLabels, sortedDatasets } = sortData({ sortType, labels, datasets });
    labels = sortedLabels;
    datasets = sortedDatasets;
  }

  return { labels, datasets };

};

const buildDatasetWitExtraData = ({ label, data, showExtraData, options = {} }) => {
  const elements = Object.keys(data);
  const datasets = [];

  elements.forEach(element => {
    const color = getRandomNviroColor();
    const dataElement = data[element];
    datasets.push({
      label: `${label} ${element === 'datos' ? '' : element}`,
      data: dataElement.map(value => value.mean),
      backgroundColor: transparentize(color, 0.4),
      borderColor: color,
      ..._datasetDefaultOptions,
      ...options,
    });
    if (showExtraData) {
      if (dataElement[0].stdDev) {
        const supLimit = dataElement.map(valor => valor.mean + valor.stdDev);
        const infLimit = dataElement.map(valor => valor.mean - valor.stdDev);
        datasets.push({
          data: supLimit,
          type: 'line',
          fill: false,
          backgroundColor: transparentize(color, 0.7),
          borderColor: 'transparent',
          borderWidth: 2,
          pointRadius: 0,
        });
        datasets.push({
          data: infLimit,
          type: 'line',
          backgroundColor: transparentize(color, 0.7),
          borderColor: 'transparent',
          borderWidth: 2,
          pointRadius: 0,
          fill: '-1',
        });
      }
    }
  });
  return datasets;
};

const prepareGeoData = (geoData, chartTypes, indicatorTypes) => {
  const preparedData = {};
  chartTypes.forEach(chartType => {
    preparedData[chartType] = { labels: [ ...geoData[chartType].labels ], data: {} };
    indicatorTypes.forEach(indicatorType => {
      const data = geoData[chartType].data[indicatorType];
      const datasets = buildDatasetWitExtraData({ label: indicatorType, data, showExtraData: chartType === 'historical' });
      preparedData[chartType].data[indicatorType] = datasets;
    });
  });
  return preparedData;
};

const getChartsFromPreparedData = (preparedData, chartType, selectedIndicators) => {
  const labels = preparedData[chartType].labels;
  const resultDatasets = [];
  selectedIndicators.forEach(indicator => {
    const datasets = preparedData[chartType].data[indicator];
    datasets.forEach(dataset => resultDatasets.push(dataset));
  });
  return { labels, datasets: resultDatasets };
};

const chartDefaultOptions = {
  scales: {
    x: {
      ticks: {
        maxRotation: 85,
        minRotation: 85,
        callback: function(value) {
          const label = this.getLabelForValue(value);
          return twe(label, 10);
        },
      },
      barPercentage: 0.4,
      stacked: false,
      offset: true,
    },
    y: {
      stacked: false,
    },
  },
  plugins: {
    tooltip: {
      intersect: false,
    },
    htmlLegend: {
      containerId: 'legend-container',
    },
    legend: {
      display: false,
    },
  },
};

const geoChartOptions = {
  ...chartDefaultOptions,
  responsive: true,
  maintainAspectRatio: false,
};

const geojsonMarkerOptions = {
  radius: 6,
  fillOpacity: 1,
  fillColor: 'rgb(51, 136, 255)',
  opacity: 1,
  weight: 2,
  color: 'rgb(51, 136, 255)',
};

const buildLeafletLayer = ({ features, id, name, color }) =>
  L.geoJSON(
    {
      type: 'FeatureCollection',
      features,
    },
    {
      id,
      name,
      style: () => ({
        color: color,
        weight: 5,
        fill: false,
        fillOpacity: 0,
      }),
      pointToLayer: (feature, latlng) => L.circleMarker(latlng, color ? {
        radius: 6,
        fillOpacity: 1,
        fillColor: color,
        opacity: 1,
        weight: 2,
        color: color,
      } : geojsonMarkerOptions),
      color,
    },
  );

const buildLeafletLayerFromTile = (url, options = {}) =>
  L.tileLayer(url, options);

const buildOccurrencesTimedimensionLayer = (occurrences, token) => {
  const features = [];
  occurrences.forEach(o => features.push({
    type: 'Feature',
    properties: {
      times: o.dates.map(o => moment(o, 'DD/MM/YYYY').valueOf()),
      occurrencesByDate: o.occurrencesByDate,
    },
    geometry: {
      coordinates: [ o.lng, o.lat ],
      type: 'Point',
    },
  }));

  const occurrencesGeojson = {
    type: 'FeatureCollection',
    features,
  };

  const occurrencesLeafletLayer = L.geoJSON(occurrencesGeojson, {
    pointToLayer: (feature, latLng) => L.circleMarker(
      latLng, { radius: 6, fillOpacity: 0.2, weight: 2, color: '#5BD6E6' }),
    onEachFeature: (feature, marker) => {
      const div = L.DomUtil.create('div');
      render(() => <OccurrencePopup data={feature.properties.occurrencesByDate} token={token} />, div);
      marker.bindPopup(div);
    },
  });

  const occurrencesTimedimensionLayer = L.timeDimension.layer.geoJson(occurrencesLeafletLayer, {
    updateTimeDimension: true,
    duration: 'P1D',
    updateTimeDimensionMode: 'union',
  });

  return occurrencesTimedimensionLayer;
};

const getLegend = props => {
  const legend = L.control({ position: 'bottomleft' });
  legend.onAdd = () => {
    const legendContainer = L.DomUtil.create('div', 'info bcw-legend nv-legend-small');
    render(() =>
      <ThemeProvider theme={themes['nviro']}>
        <SmaAnalyticMapLegend {...props} />
      </ThemeProvider>, legendContainer);
    return legendContainer;
  };
  return legend;
};

const render = (Component, element) => {
  ReactDOM.render(
    <ThemeProvider theme={themes['nviro']}>
      <Component />
    </ThemeProvider>,
    element,
  );
};

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'row';
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;
    listContainer.style.flexWrap = 'wrap';
    listContainer.style['justify-content'] = 'center';

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const _buildLi = item => {
  const boxSpan = document.createElement('span');
  const boxStatic = renderToStaticMarkup(
    <Box component="span" display="inline-block" flexShrink={0} height={16} mr={0.5} width={16} style={{
      background: item.fillStyle,
      border: `${item.lineWidth}px solid ${item.strokeStyle}`,
    }} />,
  );
  boxSpan.innerHTML = boxStatic;

  const textP = document.createElement('span');
  const textStatic = renderToStaticMarkup(
    <Box component="p" m={0} p={0}
      style={{ color: item.fontColor, fontSize: '12px', textDecoration: item.hidden ? 'line-through' : '' }}>
      {item.text}
    </Box>,
  );
  textP.innerHTML = textStatic;
  return { boxSpan, textP };
};

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerId);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    if (items.length > 1) {
      items.forEach(item => {
        const li = document.createElement('li');
        li.style.alignItems = 'center';
        li.style.cursor = 'pointer';
        li.style.display = 'flex';
        li.style.flexDirection = 'row';
        li.style.margin = '2px';

        li.onclick = () => {
          const { type } = chart.config;
          if (type === 'pie' || type === 'doughnut') {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(item.index);
          } else {
            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
          }
          chart.update();
        };
        const { boxSpan, textP } = _buildLi(item);
        li.appendChild(boxSpan);
        li.appendChild(textP);
        ul.appendChild(li);
      });
    }
  },
};

const geoDataLegendPlugin = type => ({
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerId);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const allItems = chart.options.plugins.legend.labels.generateLabels(chart);
    const items = allItems.filter(item => item.text);

    if (items.length > 1) {
      items.forEach(item => {
        const li = document.createElement('li');
        li.style.alignItems = 'center';
        li.style.cursor = 'pointer';
        li.style.display = 'flex';
        li.style.flexDirection = 'row';
        li.style.margin = '2px';

        li.onclick = () => {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
          if (type.value === 'historical') {
            chart.setDatasetVisibility(item.datasetIndex + 1, !chart.isDatasetVisible(item.datasetIndex + 1));
            chart.setDatasetVisibility(item.datasetIndex + 2, !chart.isDatasetVisible(item.datasetIndex + 2));
          }
          chart.update();
        };
        const { boxSpan, textP } = _buildLi(item);
        li.appendChild(boxSpan);
        li.appendChild(textP);
        ul.appendChild(li);
      });
    }
  },
});


export {
  buildChartTitle,
  buildDataset,
  richnessToChartOption,
  richnessToChartOptionDisabled,
  buildUrl,
  buildChartData,
  chartDefaultOptions,
  geoChartOptions,
  buildLeafletLayer,
  buildOccurrencesTimedimensionLayer,
  getLegend,
  htmlLegendPlugin,
  geoDataLegendPlugin,
  sortData,
  prepareGeoData,
  getChartsFromPreparedData,
  buildLeafletLayerFromTile,
};