import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  subtitle: {
    fontSize: '14px',
    fontWeight: 500,
  },
  field: {
    lineHeight: 1.5,
  },
  cardContent: {
    padding: theme.spacing(0.5),
  },
  description: {
    fontWeight: 500,
  },
}));

//eslint-disable-next-line react/prop-types
const Field = ({ label, field }) =>
  <Typography variant="overline" className={useStyles().field}>
    {label}: {field}
  </Typography>;

const ReplicaStationPopup = ({ stationName, monitoringType, length, width, radius, area, description, ecosystemLvl1, ecosystemLvl2 }) => {
  const classes = useStyles();
  return (
    <Card elevation={0}>
      <CardContent className={classes.cardContent}>
        <Typography variant="h6">
          {stationName}
        </Typography>
        <Box mt={2} display="flex" alignItems="center">
          <Typography variant="body2" component="span" className={classes.subtitle} color="textPrimary">
            Tipo de monitoreo:
          </Typography>
          <Typography variant="body2" component="span">
            &nbsp;{monitoringType}
          </Typography>
        </Box>
        <Box mt={2} display="flex" flexDirection="column">
          {length && <Field label="Largo" field={length} />}
          {width && <Field label="Ancho" field={width} />}
          {radius && <Field label="Radio" field={radius} />}
          {area && <Field label="Área" field={area} />}
          {ecosystemLvl1 && <Field label="Ecosistema 1" field={ecosystemLvl1} />}
          {ecosystemLvl2 && <Field label="Ecosistema 2" field={ecosystemLvl2} />}
        </Box>
        <Box mt={2}>
          <Typography className={classes.description} variant="body2" component="div">
            Descripción:
          </Typography>
          <Typography variant="body2" component="span">
            {description || 'Sin observaciones'}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

ReplicaStationPopup.propTypes = {
  stationName: PropTypes.string,
  monitoringType: PropTypes.string,
  length: PropTypes.number,
  width: PropTypes.number,
  radius: PropTypes.number,
  area: PropTypes.number,
  description: PropTypes.string,
  ecosystemLvl1: PropTypes.string,
  ecosystemLvl2: PropTypes.string,
};


export {
  ReplicaStationPopup,
};