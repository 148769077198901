import { useApi } from 'src/hooks/useApi';
import { urlJoin } from 'src/utils/util';


const resourceName = 'folder';

const useProjectApi = () => {
  const { get, post, del, patch } = useApi();
  return {
    getProjects: async () => (await get(resourceName)).data,
    getLatestProjects: async () => (await get(urlJoin(resourceName, 'by-last-event'))).data,
    getProject: async projectId => (await get(resourceName, { folderId: projectId })).data,
    createProject: async newProject => (await post(resourceName, newProject)).data,
    deleteProject: async projectId => (await del(urlJoin(resourceName, projectId))).data,
    updateProject: async ({ projectId, ...params }) => (await patch(urlJoin(resourceName, projectId), params)).data,

    getLinkedAuditableUnits: async projectId => (await get(urlJoin(resourceName, projectId, 'title-holder-and-units'))).data,
    linkAuditableUnit: async ({ projectId, auditableUnitId, titleHolderId }) =>
      (await post(urlJoin(resourceName, projectId, 'link-by-title-holder'), { auditableUnitId, titleHolderId })).data,
    unlinkAuditableUnit: async ({ projectId, auditableUnitId, titleHolderId }) =>
      (await del(urlJoin(resourceName, projectId, 'unlink-by-title-holder'), { auditableUnitId, titleHolderId })).data,
    getIssues: async projectId => (await get(urlJoin(resourceName, projectId, 'warning-data'))).data,
    rcaSearch: async ({ projectId, ...params }) => (await get(urlJoin(resourceName, projectId, 'search/rca'), params)).data,
    monitoringPlanSearch: async ({ projectId, ...params }) =>
      (await get(urlJoin(resourceName, projectId, 'search/monitoring-plan'), params)).data,
    getUnassignedCampaigns: async projectId => (await get(urlJoin(resourceName, projectId, 'unassigned-campaigns'))).data,

    getGeneralInfo: async ({ projectId, ...params }) =>
      (await get(urlJoin(resourceName, projectId, 'info'), { ...params })).data,
    getSpeciesInfo: async ({ projectId, ...params }) =>
      (await get(urlJoin(resourceName, projectId, 'species-info'), { ...params })).data,
    getSpeciesAllocationInfo: async ({ projectId, ...params }) =>
      (await get(urlJoin(resourceName, projectId, 'species-allocation-info'), { ...params })).data,
    getEeccOccurrences: async ({ projectId, ...params }) =>
      (await get(urlJoin(resourceName, projectId, 'eecc-occurrences'), { ...params })).data,

    getLinkedCaptureCompanies: async projectId => (await get(urlJoin(resourceName, projectId, 'linked-capture-companies'))).data,
    getLinkedCampaigns: async projectId => (await get(urlJoin(resourceName, projectId, 'linked-capture-campaigns'))).data,
    importCampaigns: async ({ projectId, companyId, campaignIds }) => (await post(
      urlJoin(resourceName, projectId, 'link-capture-campaign'), { captureCampaignIds: campaignIds, captureCompanyId: companyId },
    )).data,
    removeImportedCampaign: async ({ projectId, campaignId }) =>
      (await post(urlJoin(resourceName, projectId, 'unlink-capture-campaign'), { captureCampaignId: campaignId })).data,
    getLinkedProjects: async projectId => (await get(urlJoin(resourceName, projectId, 'linked-capture-projects'))).data,
    importProjects: async ({ projectId, companyId, projectIds }) => (await post(
      urlJoin(resourceName, projectId, 'link-capture-project'), { captureProjectIds: projectIds, captureCompanyId: companyId },
    )).data,
    removeImportedProject: async ({ projectId, captureProjectId }) =>
      (await post(urlJoin(resourceName, projectId, 'unlink-capture-project'), { captureProjectId })).data,

    getAnalyticsMaps: async ({ projectId }) => (await get(urlJoin(resourceName, projectId, 'area-of-interest'))).data,
    importInterestArea: async ({ projectId, interestArea }) =>
      (await post(urlJoin(resourceName, projectId, 'area-of-interest'), interestArea, { isMultipart: true })).data,
    deleteInterestArea: async ({ projectId, areaOfInterestKmlId }) =>
      (await del(urlJoin(resourceName, projectId, 'area-of-interest'), { areaOfInterestKmlId })).data,
    getGeoInfo: async ({ projectId }) => (await get(urlJoin(resourceName, projectId, 'geo-info'))).data,
    getTileData: async ({ projectId }) => (await get(urlJoin(resourceName, projectId, 'tile-data'))).data,
    processInterestArea: async({ projectId }) => (await post(urlJoin(resourceName, projectId, 'prepare-terrain-data'))).data,
  };
};


export { useProjectApi };
