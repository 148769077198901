import React from 'react';
import { Chip as BaseChip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { darkenize } from 'src/utils/util';


const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.shape.borderRadius,
  },
  color: {
    backgroundColor: ({ color = 'gray600' }) => {
      const colorStr = color.split('.');
      return (
        colorStr.length === 1 ? theme.palette.common[colorStr[0]]
        : colorStr.length === 2 ? theme.palette[colorStr[0]][colorStr[1]]
        : colorStr.length === 3 ? theme.palette[colorStr[0]][colorStr[1]][colorStr[2]]
        : theme.palette.common.gray600
      );
    },
    '&:hover': {
      backgroundColor: ({ color = 'gray600' }) => {
        const colorStr = color.split('.');
        return (
          colorStr.length === 1 ? darkenize(theme.palette.common[colorStr[0]], 0.1)
          : colorStr.length === 2 ? darkenize(theme.palette[colorStr[0]][colorStr[1]], 0.1)
          : colorStr.length === 3 ? darkenize(theme.palette[colorStr[0]][colorStr[1]][colorStr[2]], 0.1)
          : darkenize(theme.palette.common.gray600, 0.1)
        );
      },
    },
    '&:focus': {
      backgroundColor: ({ color = 'gray600' }) => {
        const colorStr = color.split('.');
        return (
          colorStr.length === 1 ? darkenize(theme.palette.common[colorStr[0]], 0.1)
          : colorStr.length === 2 ? darkenize(theme.palette[colorStr[0]][colorStr[1]], 0.1)
          : colorStr.length === 3 ? darkenize(theme.palette[colorStr[0]][colorStr[1]][colorStr[2]], 0.1)
          : darkenize(theme.palette.common.gray600, 0.1)
        );
      },
    },
    // TODO: poner letra oscura cuando el color del background es claro (hue < 500)
    color: theme.palette.common.white,
  },
}));

const Chip = ({ color, className = '', ...props }) => {
  const classes = useStyles({ color });
  const isThemeColor = [ 'default', 'primary', 'secondary' ].includes(color);
  return (
    <BaseChip
      className={clsx(className, classes.root, { [classes.color]: !isThemeColor })}
      {...(isThemeColor ? { color } : {})}
      {...props}
    />
  );
};

Chip.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};


export {
  Chip,
};