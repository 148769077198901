import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';

import { SmaMonitoringPlansContainer, SmaCampaignsContainer, SmaAnalyticsContainer, SmaMonitoringPlansComparisonContainer,
  ImportContainer, SmaSummaryContainer, SmaIssuesContainer, SmaGroupsContainer }
  from 'src/scenes/Project';


const routes = [
  {
    path: 'summary',
    Component: SmaSummaryContainer,
  },
  {
    path: 'campaigns',
    Component: SmaCampaignsContainer,
  },
  {
    path: 'monitoring-plans',
    Component: SmaMonitoringPlansContainer,
  },
  {
    path: 'monitoring-plans/comparison',
    Component: SmaMonitoringPlansComparisonContainer,
  },
  {
    path: 'monitoring-plans/:monitoringPlanId/analytics',
    Component: SmaAnalyticsContainer,
  },
  {
    path: 'monitoring-plans/:monitoringPlanId/groups',
    Component: SmaGroupsContainer,
  },
  {
    path: 'compromises',
    Component: () => <>Hola compromisos</>,
  },
  {
    path: 'rca',
    Component: () => <>Hola rcas</>,
  },
  {
    path: 'import/sma',
    Component: ImportContainer,
  },
  {
    path: 'import/capture',
    Component: ImportContainer,
  },
  {
    path: 'import',
    Component: ImportContainer,
  },
  {
    path: 'issues',
    Component: SmaIssuesContainer,
  },
];


const ProjectRoutes = props => {
  const match = useRouteMatch();
  const { path: basePath, url: routeUrl } = match;
  const redirectToProjectMainPage = () => <Redirect to={`${routeUrl}/summary`} />;

  return (
    <Switch>
      {routes.map(({ path, Component, hasNestedRoutes }, index) =>
        <Route
          key={index}
          exact={!hasNestedRoutes}
          path={`${basePath}/${path}`}
          render={rProps => <Component {...rProps} {...props} />}
        />)
      }
      <Route path={basePath} component={redirectToProjectMainPage} />
    </Switch>
  );
};


export { ProjectRoutes };