import React, { useEffect, useState } from 'react';
import Header from 'src/components/header/Header';
import PropTypes from 'prop-types';
import { Box, CssBaseline } from '@material-ui/core';

import { WebContext } from 'src/scenes/web-context';
import { WebRoutes } from 'src/scenes/WebRoutes';
import { MaintenanceAlert } from 'src/components';
import { useUserApi, usePermissionApi } from 'src/services';

const WebContainer = () => {

  const userApi = useUserApi();
  const permissionApi = usePermissionApi();

  const [ webState, setWebState ] = useState({
    currentUser: {},
    currentPath: undefined,
    changeCurrentPath: currentPath => setWebState({ ...webState, currentPath }),
    currentCompany: {},
  });

  useEffect(() => {
    const fetch = async () => {
      const [ userInfoResult, permissions ] = await Promise.all([
        userApi.getUserInfo(),
        permissionApi.getAll(),
      ]);
      const { companyId, companyName, ...userInfo } = userInfoResult;
      setWebState(prev => ({ ...prev, currentUser: userInfo, currentCompany: { companyId, companyName }, permissions }));
    };
    fetch();
  }, []);  //eslint-disable-line

  return (
    <WebContext.Provider value={webState}>
      <CssBaseline />
      <Header />
      <MaintenanceAlert />
      <Box display="flex" justifyContent="center"
        style={{ minHeight: 'calc(100vh - 97px)', paddingTop: '97px', backgroundColor: '#f4f6f6' }}>
        <WebRoutes />
      </Box>
    </WebContext.Provider>
  );
};

WebContainer.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.any,
  theme: PropTypes.object,
};


export { WebContainer };
