import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import urlJoin from 'url-join';

import { ReplicaStationsContainer, SmaMonitoringContainer, OccurrencesContainer, DownloadablesContainer }
  from 'src/scenes/Project/scenes/Campaign/scenes';


const routes = [
  {
    path: 'monitoring',
    Component: SmaMonitoringContainer,
  },
  {
    path: 'replica-stations',
    Component: ReplicaStationsContainer,
  },
  {
    path: 'occurrences',
    Component: OccurrencesContainer,
  },
  {
    path: 'downloadables',
    Component: DownloadablesContainer,
  },
];

const SmaCampaignRoutes = commonProps => {
  const match = useRouteMatch();
  const { path: basePath, url: routeUrl } = match;

  const isBaseline = Boolean(commonProps.campaign?.isBaseline);

  const redirectPath = isBaseline ? 'occurrences' : 'monitoring';
  const redirectToCampaignMainPage = () => <Redirect to={urlJoin(routeUrl, redirectPath)} />;

  const routesFiltered = isBaseline ? routes.filter(r => ![ 'replica-stations', 'monitoring' ].includes(r.path)) : routes;

  return (
    <Switch>
      {routesFiltered.map(({ path, Component }, index) =>
        <Route
          key={index}
          exact
          path={`${basePath}/${path}`}
          render={ props => <Component {...commonProps} {...props} /> }
        />)
      }
      <Route path={basePath} component={redirectToCampaignMainPage} />
    </Switch>
  );
};

SmaCampaignRoutes.propTypes = {
  commonProps: PropTypes.object,
};


export { SmaCampaignRoutes };