import { createTheme } from '@material-ui/core';


const nviroMonitorPalette = {
  primary: {
    primary50: '#fbe4ed', //  50
    primary100: '#f5bbd2', // 100
    primary200: '#f08fb4', // 200
    primary300: '#eb6196', // 300
    primary400: '#e73f7f', // 400
    light: '#e41c69', //      500
    primary600: '#d31a65', // 600
    main: '#BD1860', //       700
    primary800: '', //        800
    dark: '#821151', //       900
    contrastText: '#fff',
  },
  secondary: {
    light: 'rgba(118, 255, 255, 1)',
    main: '#ED1E79',
    dark: '#f486b0',
    contrastText: '#fff',
  },
};

const capturePalette = {
  primary: {
    light: 'rgba(138, 195, 255, 1)',
    main: 'rgba(81, 147, 235, 1)', // #5193EB
    dark: 'rgba(0, 102, 184, 1)', // #2BBFCC
    contrastText: '#fff',
  },
  secondary: {
    light: 'rgba(118, 255, 255, 1)',
    main: 'rgba(47, 214, 229, 1)',
    dark: '#2BBFCC',
    contrastText: '#fff',
  },
};

const floraPalette = {
  primary: {
    main: '#8BC63D',
    light: '#bef96e',
    dark: '#599500',
    contrastText: '#000000',
  },
  secondary: {
    main: '#C0D72E',
    light: 'rgb(204, 223, 87)',
    dark: 'rgb(134, 150, 32)',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
};

const faunaPalette = {
  secondary: {
    main: '#FBB03B',
    light: 'rgb(251, 191, 98)',
    dark: 'rgb(175, 123, 41)',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  primary: {
    main: '#F7931E',
    light: 'rgb(248, 168, 75)',
    dark: 'rgb(172, 102, 21)',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
};

const commonPalette = {
  common: {
    white: 'rgba(255, 255, 255, 1)', // 100
    lightGray: 'rgba(244,245,246, 1)', // 200
    gray300: 'rgba(234, 235, 238, 1)', // 300
    gray400: 'rgba(223, 225, 230, 1)', // 400
    gray: 'rgba(196,196,196, 1)', // 500
    gray600: 'rgba(106, 117, 123, 1)', // 600
    gray700: 'rgba(90, 90, 90, 1)', // 700
    gray800: 'rgba(74, 82, 87, 1)', // 800
    black: 'rgba(64, 71, 75, 1)', // 900
  },
  background: {
    paper: 'rgba(255, 255, 255, 1)',
    default: 'rgba(250, 250, 250, 1)',
  },
  error: {
    light: '#e57373',
    main: 'rgba(235, 60, 70, 1)',
    dark: '#d32f2f',
    contrastText: '#fff',
  },
  success: {
    main: '#4caf50',
  },
  text: {
    primary: 'rgba(64, 71, 75, 1)',
    secondary: 'rgba(90, 90, 90, 1)',
    disabled: 'rgba(171, 171, 171, 1)',
    hint: 'rgba(155, 155, 155, 1)',
  },
  nviro: { ...nviroMonitorPalette },
  capture: { ...capturePalette },
  flora: { ...floraPalette },
  fauna: { ...faunaPalette },
};

const nviroMonitorLogoWhite = '/img/logos/monitor-white-logo.png';
const nviroMonitorLogo = '/img/logos/monitor-black-logo.png';
const nviroLogo = '/img/logos/nviro-logo.svg';
const nviroLogoWhite = '/img/logos/nviro-white-logo.svg';

const commonTheme = {
  overrides: {
    MuiTableCell: {
      root: {
        padding: '8px',
      },
    },
  },
  typography: {
    fontFamily: [
      'Asap',
      'sans-serif',
    ].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  logo: nviroMonitorLogo,
  whiteLogo: nviroMonitorLogoWhite,
  logos: {
    nviro: nviroLogo,
    nviroWhite: nviroLogoWhite,
  },
};

// En cada tema los colores primarios y secundarios se reemplazan, sin embargo,
// igual se deben enviar los colores de cada tema en commonPalette para accederles desde otro tema.
const nviroTheme = createTheme({ palette: { ...commonPalette, ...nviroMonitorPalette }, ...commonTheme });

const floraTheme = createTheme({ palette: { ...commonPalette, ...floraPalette }, ...commonTheme });

const faunaTheme = createTheme({ palette: { ...commonPalette, ...faunaPalette }, ...commonTheme });

const themes = {
  nviro: nviroTheme,
  flora: floraTheme,
  fauna: faunaTheme,
};


export default themes;