import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { ListIndicator }
  from 'src/scenes/Project/scenes/Summary/components';


const useStyles = makeStyles(theme => ({
  container: {
    width: '70%',
  },
  title: {
    fontWeight: 500,
  },
  icon: {
    marginLeft: theme.spacing(0.5),
    cursor: 'pointer',
    fontSize: '14px',
    color: theme.palette.common.gray600,
  },
  labelDate: {
    width: 'fit-content',
  },
}));

const SmaMonitoringPlanCard = ({ name, rcaId }) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" flexGrow={1} className={classes.container}>
      <Box display='flex' flexDirection="column" sx={{ p: 1, width: '100%' }}>
        <Box display="flex" alignItems="center">
          <Typography noWrap className={classes.title} variant="h6" color="textPrimary">
            {name}
          </Typography>
        </Box>
        <Box pb={0.5} display="flex">
          {rcaId.length > 0 &&
            <ListIndicator
              list={rcaId.map(id => `RCA | ${id}`)}
              hideCount
              maxItems={5}
              color='primary'
            />
          }
        </Box>
      </Box>
    </Box>
  );
};

SmaMonitoringPlanCard.propTypes = {
  name: PropTypes.string,
  rcaId: PropTypes.array,
};


export { SmaMonitoringPlanCard };