import React, { useContext, useEffect, useState } from 'react';
import { Grid, Box, Button } from '@material-ui/core';
import { ArrowForward, CreateNewFolder, Delete, Edit } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import { WebContext } from 'src/scenes/web-context';
import { TitleContainer, ContentContainer, Cards, ActionsBox, DialogWrap, AccessControl } from 'src/components';
import { useProjectApi } from 'src/services';

import { ProjectCreateDialog, ProjectDeleteDialog, ProjectEditDialog } from 'src/scenes/CompanySummary/components';


const useStyles = makeStyles(theme => ({
  mainContainer: {
    width: theme.spacing(180),
  },
}));

const ProjectsContainer = () => {

  const classes = useStyles();
  const history = useHistory();
  const projectApi = useProjectApi();
  const [ projects, setProjects ] = useState();

  const { currentCompany: { companyName } } = useContext(WebContext);


  useEffect(() => {
    const fetchData = async () => {
      const fetchedProjects = await projectApi.getProjects();
      setProjects(fetchedProjects);
    };
    fetchData();
  }, []); // eslint-disable-line

  const [ dialog, setDialog ] = useState({ isOpen: false, type: '' });
  const closeDialog = () => setDialog({ isOpen: false, type: '' });
  const openDialog = (type, data = {}) => setDialog({ isOpen: true, type, data });

  const openCreateProjectDialog = () => openDialog('create-project');
  const openDeleteProjectDialog = data => openDialog('delete-project', data);
  const openEditProjectDialog = data => openDialog('edit-project', data);

  const getDialogType = () => {
    switch (dialog.type) {
      case 'create-project':
        return <ProjectCreateDialog actions={{ closeDialog, createProject }} />;
      case 'delete-project':
        return <ProjectDeleteDialog project={dialog.data} actions={{ closeDialog, deleteProject }} />;
      case 'edit-project':
        return <ProjectEditDialog project={dialog.data} actions={{ closeDialog, editProject }} />;
      default:
        break;
    }
  };

  const goToProject = project => history.push(`/web/projects/${project.id}`);

  const cardActions = itemData => ([
    {
      Icon: Edit,
      tooltip: 'Editar',
      onClick: () => openEditProjectDialog(itemData),
      accessPermission: 'project:edit',
    },
    {
      Icon: Delete,
      tooltip: 'Eliminar',
      onClick: () => openDeleteProjectDialog(itemData),
      accessPermission: 'project:delete',
    },
    {
      Icon: ArrowForward,
      tooltip: 'Ver proyecto',
      onClick: () => goToProject(itemData),
    },
  ]);

  const createProject = async newProject => {
    const [ createdProject ] = await projectApi.createProject(newProject);
    setProjects([ createdProject, ...projects ]);
  };

  const deleteProject = async projectId => {
    await projectApi.deleteProject(projectId);
    setProjects(projects.filter(project => project.id !== projectId));
  };

  const editProject = async editProject => {
    await projectApi.updateProject({ projectId: editProject.id, name: editProject.name });
    setProjects(projects.map(project => editProject.id === project.id ? editProject : project));
  };

  return (
    <Grid container justifyContent="center" className={classes.mainContainer}>
      <TitleContainer>
        Proyectos de {companyName}
      </TitleContainer>
      <ContentContainer>
        <AccessControl action='project:create'>
          <ActionsBox>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={openCreateProjectDialog}
              startIcon={<CreateNewFolder fontSize="small" />}
            >
              Crear proyecto
            </Button>
          </ActionsBox>
        </AccessControl>
        <Box p={4}>
          <Cards values={projects} actions={cardActions} />
        </Box>
      </ContentContainer>
      <DialogWrap maxWidth='sm' fullWidth onClose={closeDialog} aria-labelledby='form-dialog-title' open={dialog.isOpen}>
        {getDialogType()}
      </DialogWrap>
    </Grid>
  );
};


export {
  ProjectsContainer,
};
