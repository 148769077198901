import React, { useEffect, useState } from 'react';
import { Grid, Box, Button, Divider, Paper, Tabs, Tab, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { AddBox, ArrowBack, Grain, Spa } from '@material-ui/icons';

import { TitleContainer, ContentContainer, ActionsBox, DialogWrap, AccessControl } from 'src/components';
import { useMonitoringPlanApi } from 'src/services';
import { upperCaseOnlyFirstLetter as ucofl, upperCaseFirstLetter as ucfl } from 'src/utils/formatters';
import { useFetchStatus } from 'src/hooks';
import { Terrain } from 'src/utils/mdIcons';
import { SmaGroupCreateDialog, SmaGroupDeleteDialog, SmaGroupList } from 'src/scenes/Project/scenes/Groups/components';


const useStyles = makeStyles(theme => ({
  tabs: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 'fit-content',
  },
  content: {
    maxWidth: theme.spacing(160),
  },
  backArrow: {
    color: theme.palette.common.gray700,
    padding: 0,
    marginRight: theme.spacing(1),
  },
}));

const optionsTypeLabels = [ 'Monitoreos', 'Estaciones replica', 'Especies' ];

const optionsTypesIds = [ 'instances', 'stations', 'species' ];

const SmaGroupsContainer = props => {
  const classes = useStyles();

  const history = useHistory();
  const match = useRouteMatch();
  const monitoringPlanApi = useMonitoringPlanApi();

  const { fetchStatus, setFetchStatus, fetchStatusEnum, setErrorMessage } = useFetchStatus({});
  const { LOADING, SUCCESS, ERROR } = fetchStatusEnum;

  const { monitoringPlanId, projectId } = match.params;

  const [ groups, setGroups ] = useState([]);
  const [ monitoringPlan, setMonitoringPlan ] = useState();
  const [ tab, setTab ] = useState(0);

  const [ options, setOptions ] = useState();

  const plansUrl = `/web/projects/${projectId}/monitoring-plans`;

  useEffect(() => {

    const fetchData = async () => {
      try {
        setFetchStatus(LOADING);

        const [ monitoringPlan, groups, monitoringInstances, replicaStations, species ] = await Promise.all([
          monitoringPlanApi.getMonitoringPlans({ monitoringPlanId }),
          monitoringPlanApi.getGroups(monitoringPlanId),
          monitoringPlanApi.getMonitoringPlanInstances({ monitoringPlanId }),
          monitoringPlanApi.getReplicaStationsByMonitoringPlan({ monitoringPlanId }),
          monitoringPlanApi.searchSpecies({ queryText: '', monitoringPlanId }),
        ]);

        setMonitoringPlan(monitoringPlan);

        const speciesOptions = species.map(r => ({ label: ucfl(r.occurrenceSpecies), value: r.occurrenceSpecies }));
        const replicaStationOptions = replicaStations.map(rs => ({ label: rs.stationName, value: rs.id, data: rs }));
        const sortedData = monitoringInstances.sort((a, b) => b.position - a.position);
        const monitoringInstancesOptions = sortedData.map(m => ({ label: m.name, value: m.id, data: m }));

        const options = [ monitoringInstancesOptions, replicaStationOptions, speciesOptions ];
        setOptions(options);

        const groupsWithNames = groups.map(g => ({
          ...g,
          names: options[optionsTypesIds.indexOf(g.type)].filter(o => g.values.includes(o.value)).map(o => o.label),
        }));
        setGroups(groupsWithNames);
        setFetchStatus(SUCCESS);

      } catch (e) {
        console.error(e);
        setErrorMessage(e.serverMessage || e.message);
        setFetchStatus(ERROR);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [ monitoringPlanId ]);

  const [ dialog, setDialog ] = useState({ isOpen: false, data: {}, type: '' });

  const closeDialog = () => setDialog({ isOpen: false, type: '' });
  const openCreateGroup = () => setDialog({ isOpen: true, type: 'create' });
  const openDeleteGroup = group => setDialog({ isOpen: true, type: 'delete', data: group });

  const getDialogType = () => {
    switch (dialog.type) {
      case 'create': {
        return <SmaGroupCreateDialog
          optionsTypeLabel={optionsTypeLabels[tab]}
          options={options[tab]}
          actions={{ createGroup, closeDialog }}
        />;
      }
      case 'delete': {
        return <SmaGroupDeleteDialog actions={{ deleteGroup, closeDialog }} group={dialog.data} />;
      }
      default:
        break;
    }
  };

  const createGroup = async group => {
    const [ { id } ] = await monitoringPlanApi.createGroup({ monitoringPlanId, newGroup: { ...group, type: optionsTypesIds[tab] } });

    const newGroup = {
      id,
      alias: group.alias,
      type: optionsTypesIds[tab],
      values: group.values,
      names: options[tab].filter(o => group.values.includes(o.value)).map(o => o.label),
    };
    setGroups([ newGroup, ...groups ]);
  };

  const deleteGroup = async groupId => {
    await monitoringPlanApi.deleteGroup({ monitoringPlanId, groupId });
    setGroups(groups.filter(group => group.id !== groupId));
  };

  return (
    <>
      <Grid container>
        <TitleContainer
          maxWidth="xl"
          breadcrumbs={[
            'projects',
            { name: `Planes de monitoreo de ${ucofl(props.name)}`, url: plansUrl },
            { name: `Grupos de ${ucofl(monitoringPlan ? monitoringPlan[0].name : '')}` },
          ]}
        >
          <Box display="flex" alignItems="center">
            <IconButton className={classes.backArrow} onClick={() => history.push(plansUrl)}>
              <ArrowBack fontSize='large' color="inherit" />
            </IconButton>
            Grupos
          </Box>
        </TitleContainer>
        <ContentContainer maxWidth="xl" className={classes.content}>
          <Paper className={classes.tabs}>
            <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)} indicatorColor="primary" textColor="primary" variant="standard">
              <Tab label={
                <Box display="flex">
                  <Terrain />
                  <Box ml={1}>Monitoreos</Box>
                </Box>
              }/>
              <Tab label={
                <Box display="flex">
                  <Grain />
                  <Box ml={1}>Estaciones replica</Box>
                </Box>
              } />
              <Tab label={
                <Box display="flex">
                  <Spa />
                  <Box ml={1}>Especies</Box>
                </Box>
              } />
            </Tabs>
          </Paper>
          <Divider />
          <Box mt={2}></Box>
          <ActionsBox>
            <AccessControl action='monitoring-plan:create-group'>
              <Button
                disabled={fetchStatus === LOADING}
                size="small"
                color="primary"
                startIcon={<AddBox />}
                variant="contained"
                onClick={openCreateGroup}
              >
                Crear grupo
              </Button>
            </AccessControl>
          </ActionsBox>
          {options && <SmaGroupList groups={groups.filter(group => group.type === optionsTypesIds[tab])} actions={{ openDeleteGroup }}/>}
        </ContentContainer>
      </Grid>
      <DialogWrap maxWidth="sm" fullWidth onClose={closeDialog} open={dialog.isOpen}>
        {getDialogType()}
      </DialogWrap>
    </>
  );
};

SmaGroupsContainer.propTypes = {
  name: PropTypes.string,
};


export {
  SmaGroupsContainer,
};