import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';

import { DialogButton, AlertWrapper } from 'src/components';
import { useFetchStatus } from 'src/hooks';


const SmaMonitoringPlanDeleteDialog = props => {

  const { actions } = props;

  const { fetchStatus, errorMessage, hasError, handleConfirm } = useFetchStatus({
    confirmFn: actions.deleteMonitoringPlan,
    closeFn: actions.closeDialog,
  });

  return (
    <>
      <DialogTitle id="form-dialog-title">Borrar plan de monitoreo</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Typography variant="body1" color="textPrimary">
            Al eliminar este plan de monitoreo <strong>perderás las analíticas realizadas y el orden de los monitoreos que
            contiene</strong> por lo que tendrás que reconstruirlos manualmente.
          </Typography>
          <br />
          <Typography variant="body1" color="textPrimary">
            ¿Realmente deseas eliminar el plan de monitoreo?
          </Typography>
          <br />
          {hasError && <AlertWrapper severity="error">{errorMessage}</AlertWrapper>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color="primary">Borrar</DialogButton>
      </DialogActions>
    </>
  );
};

SmaMonitoringPlanDeleteDialog.propTypes = {
  point: PropTypes.object,
  actions: PropTypes.object,
  csLang: PropTypes.object,
};


export {
  SmaMonitoringPlanDeleteDialog,
};