import { useApi } from 'src/hooks/useApi';


const useUserApi = () => {
  const { get, patch } = useApi();
  return {
    getLabels: async filtersNames => (await get('web/filters-labels', filtersNames)).data,
    getCompanies: async companyId => (await get(`smaData/companies`, { ...(companyId ? { companyId } : {}) })).data,
    getUserInfo: async () => (await get('user/user-info')).data,
    getUserCompanies: async () => (await get('user/companies')).data,
    changeCurrentCompany: async companyId => (await patch('user/companies', companyId)).data,
    changePassword: async () => (await patch('user/start-change-password')).data,
  };
};


export { useUserApi };
