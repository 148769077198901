import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Paper, Typography } from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Skeleton } from '@material-ui/lab';


const getColor = theme => ({ color }) => (
  color === 'primary' ? theme.palette.primary.main
  : color === 'secondary' ? theme.palette.secondary.main
  : color === 'flora' ? theme.palette.flora.primary.main
  : color === 'fauna' ? theme.palette.fauna.primary.main
  : theme.palette.primary.main
);

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    backgroundColor: ({ variant }) => (
      variant === 'dark' ? theme.palette.common.black
      : variant === 'light' ? theme.palette.common.white
      : theme.palette.common.white
    ),
  },
  title: {
    fontSize: theme.typography.subtitle1.fontSize,
    color: ({ variant }) => (
      variant === 'light' ? theme.palette.common.gray600
      : variant === 'dark' ? theme.palette.common.lightGray
      : theme.palette.common.gray600
    ),
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  number: {
    width: ({ isLoading }) => isLoading ? '40%' : '100%',
    fontSize: theme.typography.h4.fontSize,
    fontWeight: 600,
    color: ({ variant }) => (
      variant === 'light' ? theme.palette.common.black
      : variant === 'dark' ? theme.palette.common.white
      : theme.palette.common.black
    ),
  },
  icon: {
    color: getColor(theme),
  },
  iconBackground: {
    height: '40px',
    width: '40px',
    backgroundColor: ({ color }) => alpha(getColor(theme)({ color }), 0.15),
    borderRadius: '50%',
  },
}));

const IndicatorCard = props => {
  const { className, title = '', number = 0, color = 'primary', Icon = () => <></>, variant = 'light', isLoading } = props;
  const classes = useStyles({ color, variant, isLoading });
  return (
    <Paper className={clsx(classes.container, className)}>
      <Box display="flex">
        <Box display="flex" flexDirection="column" width="100%">
          <Box mb={1}>
            <Typography className={classes.title} noWrap>{title}</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography className={classes.number} noWrap>
              {isLoading ? <Skeleton animation="wave" variant="text" /> : number}
            </Typography>
            <Box flexGrow={1} />
            <Box p={1} display="flex" alignItems="center" justifyContent="center" className={classes.iconBackground}>
              <Icon className={classes.icon} />
            </Box>
          </Box>
          <Box display="flex" mt={1} justifyContent="left" width="100%">
            <Button color="primary" variant="outlined" size="small">Ver todas</Button>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

IndicatorCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  number: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  Icon: PropTypes.oneOfType([ PropTypes.object, PropTypes.func ]),
  color: PropTypes.string,
  variant: PropTypes.string,
  isLoading: PropTypes.bool,
};


export { IndicatorCard };
