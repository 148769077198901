import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { HomeContainer } from 'src/scenes/Home/HomeContainer';
import { WebContainer } from 'src/scenes/WebContainer';
import { MaintenanceContainer } from 'src/scenes/Maintenance/MaintenanceContainer';
import { ProtectedRoute } from 'src/components/auth0/ProtectedRoute';


const AppRoutes = () => {
  const { isLoading } = useAuth0();
  if (isLoading) {
    return (
      <>Cargando...</>
    );
  }

  return (
    <Router>
      <Switch>
        <ProtectedRoute path="/web" component={WebContainer} />
        <Route path="/maintenance" component={MaintenanceContainer} />
        <Route path="/" component={HomeContainer} />
      </Switch>
    </Router>
  );
};


export { AppRoutes };