import { useContext } from 'react';
import { WebContext } from 'src/scenes/web-context';
import PropTypes from 'prop-types';
import { checkPermission } from 'src/utils/checkPermission';


const AccessControl = (
  { action, extraCondition, noAccessRender, children, userRole },
) => {
  const webContext = useContext(WebContext);
  const finalExtraCondition = extraCondition;

  // if you call this with no action, it just checks the extra conditions (and emailVerified if necessary)
  const canAccess = !action ? finalExtraCondition
    : checkPermission({ action, extraCondition: finalExtraCondition, webContext, userRole });
  return canAccess ? children : noAccessRender({ webContext });
};

AccessControl.defaultProps = {
  noAccessRender: () => null,
  extraCondition: true,
  checkEmailVerified: false,
};

AccessControl.propTypes = {
  action: PropTypes.string,
  noAccessRender: PropTypes.func,
  extraCondition: PropTypes.bool,
  checkOnCampaignRole: PropTypes.bool,
  notEvenGods: PropTypes.bool,
  checkEmailVerified: PropTypes.bool,
};


export {
  AccessControl,
};