import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import PropTypes from 'prop-types';

import 'react-image-gallery/styles/scss/image-gallery.scss';


const descriptionMaker = (pic, title) => {
  const finalTitle = pic.title || title;
  const eeccString = pic.eeccCategory ? ` Categoría de conservación: ${pic.eeccCategory}.` : '';
  return `${finalTitle}.${eeccString}`;
};

const DialogImagePreviewer = ({ index, pictures, title, ...props }) => {
  const [ images, setImages ] = useState([]);

  useEffect(() => {
    const loadImages = () => {
      try {
        const imagesData = pictures;
        const formattedImages = imagesData.map(image => ({
          original: image.url || image,
          thumbnail: image.url || image,
          originalTitle: image.title || title,
          description: descriptionMaker(image, title),
          originalAlt: descriptionMaker(image, title),
        }));
        setImages(formattedImages);
      } catch (error) {
        console.error(error);
      }
    };

    loadImages();
  // eslint-disable-next-line
  }, []);


  return (
    pictures.length > 0 &&
      <ImageGallery
        items={images}
        useBrowserFullscreen={ false }
        showBullets
        showIndex
        startIndex={ index }
        slideDuration={0}
        slideInterval={1000}
        { ...props }
      />
  );
};

DialogImagePreviewer.defaultProps = {
  index: 0,
};

DialogImagePreviewer.propTypes = {
  pictures: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
    }),
  ])),
  index: PropTypes.number,
  title: PropTypes.string,
};


export { DialogImagePreviewer };