import { useApi } from 'src/hooks/useApi';
import { urlJoin } from 'src/utils/util';

const resourceName = 'capture';

const useCaptureApi = () => {
  const { get } = useApi();
  return {
    getLinkedCompanies: async () => (await get(urlJoin(resourceName, 'company'))).data,
    getImportableCampaigns: async params => (await get(urlJoin(resourceName, 'campaign'), params)).data,
    getImportableProjects: async params => (await get(urlJoin(resourceName, 'project'), params)).data,
  };
};


export { useCaptureApi };




