import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'typeface-asap';
import 'src/index.css';
import '../node_modules/leaflet-draw/dist/leaflet.draw.css';
import '../node_modules/leaflet-easybutton/src/easy-button.css';
import App from 'src/App.js';
import 'moment/locale/es';
import 'leaflet-timedimension';
import 'leaflet-timedimension/dist/leaflet.timedimension.control.min.css';
import 'react-indiana-drag-scroll/dist/style.css';
import 'leaflet/dist/leaflet.css';


if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
);