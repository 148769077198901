import React, { useState } from 'react';
import { Box, Typography, Collapse } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';


const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const Collapser = props => {
  const { children, title } = props;
  const classes = useStyles();
  const [ expanded, setExpanded ] = useState(true);

  const handleExpandClick = () => setExpanded(!expanded);

  return (
    <Box display="flex" flexDirection="column">
      <Box className={classes.root} display="flex" alignItems="center" >
        <ExpandMore
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
        />
        <Typography variant="h6" onClick={handleExpandClick}>{title}</Typography>
      </Box>
      <Box mt={1}>
        <Collapse in={expanded} timeout="auto">{children}</Collapse>
      </Box>
    </Box>
  );
};

Collapser.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
};


export { Collapser } ;
