
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';

import { Cards } from 'src/components';
import { PhotoAlbum } from '@material-ui/icons';


const OccurrencesList = ({ values, actions }) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const columns = [
    { title: 'Genero', field: 'genus' },
    { title: 'Especie', field: 'epithet' },
    { title: 'Familia', field: 'family' },
    { title: 'Clase', field: 'class' },
    { title: 'Nombre común', field: 'commonNames' },
    { title: 'Estación replica', field: 'stationReplicateId' },
    { title: 'Protocolo', field: 'protocol' },
    { title: 'Parámetro', field: 'parameter' },
    { title: 'Tipo de cuantificación', field: 'quantification' },
    { title: 'Valor', field: 'value' },
    { title: 'Unidad', field: 'valueUnit' },
    {
      title: 'Fecha',
      render: r => <Box whiteSpace="nowrap">{[ r.eventDay, r.eventMonth, r.eventYear ].filter(x => x).join('/')}</Box>,
    },
    { title: 'Latitud', field: 'recordLatitude' },
    { title: 'Longitud', field: 'recordLongitude' },
    { title: 'Tamaño de la muestra', field: 'sampleSize' },
    { title: 'Unidad del tamaño de la muestra', field: 'sampleSizeUnit' },
    { title: 'Esfuerzo de la muestra', field: 'samplingEffort' },
    { title: 'Estado del organismo', field: 'organismState' },
  ];

  const fetchImage = async pictures => {
    try {
      let token;
      try {
        token = await getAccessTokenSilently();
      } catch (e) {
        console.error(e);
        await loginWithRedirect();
        return;
      }
      const result = [];
      for (let i = 0; i < pictures.length; i++) {
        const response = await fetch(pictures[i].url, { headers: { 'Authorization': `Bearer ${token}` } });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        const imageObjectURL = URL.createObjectURL(blob);
        result.push(imageObjectURL);
      }
      return result;
    } catch (error) {
      console.error('Error fetching the image:', error);
    }
  };

  const cardActions = ({ images = [], ...itemData }) => {

    const hasPictures = images.length > 0;
    return ([
      {
        disabled: !hasPictures,
        Icon: () => <PhotoAlbum color={hasPictures ? 'action' : 'disabled'} />,
        tooltip: hasPictures ? 'Ver fotos' : 'No hay fotos',
        ...(hasPictures ? { onClick: async () => {
          const bla = await fetchImage(images.map(image => ({ url: image })));
          actions.openViewPhotoDialog({
            pictures: bla,
            title: `${itemData?.genus} ${itemData?.epithet} ${itemData?.family}`,
          });
        },
        } : {}),
      },
    ]);
  };

  return (
    <>
      <Cards values={values} variant="condensed" columns={columns} actions={cardActions} />
      {values.length > 0 && <Box textAlign="right"><Typography variant="body2">Se encontraron {values.length} elementos</Typography></Box>}
    </>
  );
};

OccurrencesList.propTypes = {
  values: PropTypes.array.isRequired,
  actions: PropTypes.object,
};


export { OccurrencesList };