import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, IconButton, Paper, Typography, InputLabel, Tooltip, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useRouteMatch } from 'react-router';
import { ArrowBack, Edit, Delete, Check, Close, AddBox, HighlightOff, AddCircleOutline, OpenInNew } from '@material-ui/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import clsx from 'clsx';

import { useProjectApi, useMonitoringPlanApi } from 'src/services';
import { TitleContainer, ContentContainer, ActionsBox, LabelChip, AutocompleteWrapper, Editable, DialogWrap,
  AccessControl } from 'src/components';
import { SmaMonitoringInstanceAddCampaignDialog, SmaMonitoringPlanCreateDialog }
  from 'src/scenes/Project/scenes/MonitoringPlans/components';
import { AutoFix } from 'src/utils/mdIcons';
import { upperCaseOnlyFirstLetter } from 'src/utils/formatters';


const grid = 8;
const planWidth = 250;

const useStyles = makeStyles(theme => ({
  backArrow: {
    color: theme.palette.common.gray700,
    padding: 0,
    marginRight: theme.spacing(1),
  },
  planContainer: {
    padding: grid,
    width: planWidth,
  },
  planDragging: {
    background: 'lightblue',
  },
  monitoringContainer: {
    userSelect: 'none',
    margin: `0 0 ${grid}px 0`,
    '&:hover': {
      '& $removeInstance': {
        visibility: 'visible',
        animationName: '$fadeIn',
        animation: 'linear',
        animationDuration: '300ms',
      },
      // '& $addCampaign': {
      //   visibility: 'visible',
      //   animationName: '$fadeIn',
      //   animation: 'linear',
      //   animationDuration: '300ms',
      // },
    },
  },
  monitoringDragging: {
    background: theme.palette.common.gray,
  },
  dragNDropContainer: {
    borderTop: `1px solid ${theme.palette.common.gray400}`,
    width: theme.spacing(32),
    margin: '0px 8px',
  },
  dragNDropContainerBorder: {
    borderStyle: 'solid dashed',
    borderColor: theme.palette.common.gray,

  },
  labelChip: {
    maxWidth: planWidth - theme.spacing(4),
    backgroundColor: 'white',
    '&:hover': {
      '& $campaignLink': {
        visibility: 'visible',
        animationName: '$fadeIn',
        animation: 'linear',
        animationDuration: '300ms',
      },
    },
    height: 'auto',
    color: theme.palette.common.black,
  },
  labelChipCampaign: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  unassignedCampaignBox: {
    '&:hover': {
      '& $unassignedCampaignLink': {
        visibility: 'visible',
        animationName: '$fadeIn',
        animation: 'linear',
        animationDuration: '300ms',
      },
    },
  },
  unassignedCampaignName: {
    marginLeft: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontWeight: 600,
  },
  monitoringFilter: {
    width: 'fit-content',
    maxWidth: '80vw',
  },
  planActionIcons: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.gray600,
    cursor: 'pointer',
    visibility: 'hidden',
    margin: theme.spacing(0.25),
    '&:hover': {
      backgroundColor: theme.palette.common.gray400,
    },
  },
  monitoringPlanContainer: {
    height: theme.spacing(10),
    '&:hover': {
      '& $planActionIcons': {
        visibility: 'visible',
        animationName: '$fadeIn',
        animation: 'linear',
        animationDuration: '300ms',
      },
    },
  },
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  editableAction: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.gray600,
    cursor: 'pointer',
    margin: theme.spacing(0.25),
    fontSize: '20px',
    '&:hover': {
      backgroundColor: theme.palette.common.gray400,
    },
  },
  separatorBox: {
    marginTop: theme.spacing(1) * -1,
    alignItems: 'center',
    '&:hover': {
      '& $addInstance': {
        animationName: '$heightIn',
        animation: 'linear',
        animationDuration: '100ms',
        height: '24px',
      },
    },
  },
  '@keyframes heightIn': {
    from: { height: '0px' },
    to: { height: '24px' },
  },
  separator: {
    height: '15px',
    width: '1px',
    flexGrow: 1,
    backgroundColor: theme.palette.common.gray,
  },
  separator2: {
    height: '14px',
  },
  addInstance: {
    cursor: 'pointer',
    color: theme.palette.common.gray700,
    height: 0,
    marginTop: theme.spacing(0.5) * -1,
    marginBottom: theme.spacing(0.5) * -1,
  },
  removeInstance: {
    color: theme.palette.common.gray600,
    cursor: 'pointer',
    visibility: 'hidden',
    fontSize: '18px',
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
  addCampaign: {
    color: theme.palette.common.gray600,
    cursor: 'pointer',
    visibility: 'hidden',
    fontSize: '18px',
    '&:hover': {
      color: theme.palette.success.main,
    },
  },
  campaignLink: {
    margin: '0 5px 0 -19px',
    width: '14px',
    height: '14px',
    visibility: 'hidden',
    backgroundColor: 'white',
    boxShadow: '-7px 0px 3px -2px white',
    color: theme.palette.common.gray600,
  },
  unassignedCampaignLink: {
    marginLeft: theme.spacing(-2),
    width: '14px',
    height: '14px',
    visibility: 'hidden',
    backgroundColor: 'white',
    boxShadow: '-7px 0px 3px -2px white',
    color: theme.palette.common.gray600,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [ removed ] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const reorder = (list, startIndex, endropzoneDestinationIdex) => {
  const result = Array.from(list);
  const [ removed ] = result.splice(startIndex, 1);
  result.splice(endropzoneDestinationIdex, 0, removed);
  return result;
};

const SmaMonitoringPlansComparisonContainer = props => {
  const match = useRouteMatch();
  const history = useHistory();
  const classes = useStyles();
  const projectApi = useProjectApi();
  const monitoringPlanApi = useMonitoringPlanApi();

  const { projectId } = match.params;

  const [ monitoringPlans, setMonitoringPlans ] = useState([]);
  const [ monitoringPlansOptions, setMonitoringPlansOptions ] = useState([]);
  const [ selectedMonitoringPlans, setSelectedMonitoringPlans ] = useState([]);

  useEffect(() => {
    const fetchData = async () => {

      const query = new URLSearchParams();
      selectedMonitoringPlans.forEach(mp => query.append('monitoringPlanId', mp.value));
      query.append('folderId', projectId);
      const params = query.toString();

      const [ allMonitoringPlans, monitoringPlans, unassignedCampaigns ] = await Promise.all([
        monitoringPlanApi.getMonitoringPlans({ folderId: projectId }),
        monitoringPlanApi.getMonitoringPlans(params),
        projectApi.getUnassignedCampaigns(projectId),
      ]);

      setMonitoringPlans([
        ...(unassignedCampaigns.length > 0 ? [ {
          id: 'not-monitoring-plan',
          name: 'Campañas sin planes de monitoreo',
          instances: unassignedCampaigns.map((c, index) => ({ id: `uc-${index}`, position: index + 1, name: c.name, campaigns: [ c ] })),
        } ] : []),
        ...monitoringPlans,
      ]);

      setMonitoringPlansOptions(allMonitoringPlans.map(mp => ({ label: mp.name, value: mp.id })));
    };
    fetchData();
  // eslint-disable-next-line
  }, [ selectedMonitoringPlans ]);

  const plansUrl = `/web/projects/${projectId}/monitoring-plans`;

  const campaignUrl = campaignId => `/web/projects/${projectId}/campaigns/${campaignId}`;

  const onDragEnd = async result => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const dropzoneSourceId = parseInt(source.droppableId, 10);
    const dropzoneDestinationId = parseInt(destination.droppableId, 10);

    const hasUnassignedCampaigns = monitoringPlans[0].id === 'not-monitoring-plan';

    const isUnassignedCampaignsDropzoneSource = dropzoneSourceId === 0 && hasUnassignedCampaigns;
    const isUnassignedCampaignsDropzoneDestination = dropzoneDestinationId === 0 && hasUnassignedCampaigns;

    if (isUnassignedCampaignsDropzoneSource && isUnassignedCampaignsDropzoneDestination) {
      return;
    }

    const instanceDragged = monitoringPlans[dropzoneSourceId].instances[source.index];

    if (dropzoneSourceId === dropzoneDestinationId) {
      const reorderedInstances = reorder(monitoringPlans[dropzoneSourceId].instances, source.index, destination.index);
      const newState = [ ...monitoringPlans ];
      newState[dropzoneSourceId].instances = reorderedInstances;
      const newPosition = (reorderedInstances.length - 1) - destination.index;
      await monitoringPlanApi.updateMonitoringInstance({ monitoringInstanceId: instanceDragged.id, position: newPosition });
      setMonitoringPlans(newState);
    } else {
      const result = move(
        monitoringPlans[dropzoneSourceId].instances,
        monitoringPlans[dropzoneDestinationId].instances,
        source,
        destination,
      );
      const newState = [ ...monitoringPlans ];
      newState[dropzoneSourceId].instances = result[dropzoneSourceId];
      newState[dropzoneDestinationId].instances = result[dropzoneDestinationId];
      const newPosition = (result[dropzoneDestinationId].length - 1) - destination.index;

      const instance = (isUnassignedCampaignsDropzoneSource) ?
        await monitoringPlanApi.createMonitoringInstance({
          monitoringPlanId: newState[dropzoneDestinationId].id,
          position: newPosition,
          campaignId: instanceDragged.campaigns.map(c => c.id),
        })
        : isUnassignedCampaignsDropzoneDestination ?
          await monitoringPlanApi.deleteMonitoringInstance({ monitoringInstanceId: instanceDragged.id })
          :
          await monitoringPlanApi.updateMonitoringInstance({
            monitoringInstanceId: instanceDragged.id,
            position: newPosition,
            monitoringPlanId: newState[dropzoneDestinationId].id,
          });

      if (isUnassignedCampaignsDropzoneSource) {
        instanceDragged.id = instance.id;
      }

      isUnassignedCampaignsDropzoneSource && newState[dropzoneSourceId].instances.length === 0 ?
        setMonitoringPlans(newState.filter((n, i) => i !== 0)) :
        setMonitoringPlans(newState);

    }
  };

  const deleteMonitoringPlan = async monitoringPlanId => {
    await monitoringPlanApi.deleteMonitoringPlan(monitoringPlanId);
    const campaignsFromDeletedMonitoringPlan = monitoringPlans
      .find(mp => mp.id === monitoringPlanId).instances
      .map(i => i.campaigns.map(c => ({ id: c.id, startDate: c.startDate, endDate: c.endDate, name: c.name, instances: [] })))
      .flat();

    setMonitoringPlans(prevMonitoringPlans => {
      const hasUnassignedCampaigns = prevMonitoringPlans[0].id === 'not-monitoring-plan' && prevMonitoringPlans[0].instances.length > 0;
      const prevUnassignedCampaigns = prevMonitoringPlans[0].instances;

      const monitoringPlans = hasUnassignedCampaigns ? [
        ...prevMonitoringPlans
          .filter(mp => mp.id !== monitoringPlanId)
          .map((mp, idx) => idx === 0 ? ({
            ...mp,
            instances: [
              ...mp.instances.map((i, index) => ({ ...i, id: `uc-${index}` })),
              ...campaignsFromDeletedMonitoringPlan.map((c, index) => ({
                id: `uc-${index + mp.instances.length}`,
                position: index + 1 + mp.instances.length, name:
                c.name,
                campaigns: [ c ],
              })),
            ],
          }) : mp),
      ] :
        [
          ...campaignsFromDeletedMonitoringPlan.length > 0 ? [ {
            id: 'not-monitoring-plan',
            name: 'Campañas sin planes de monitoreo',
            instances: [
              ...campaignsFromDeletedMonitoringPlan
                .map((c, index) => ({
                  id: `uc-${prevUnassignedCampaigns.length + index}`,
                  position: prevUnassignedCampaigns.length + index + 1,
                  name: c.name,
                  campaigns: [ c ],
                })),
            ],
          } ] : [],
          ...prevMonitoringPlans.filter(mp => mp.id !== monitoringPlanId),
        ];

      return monitoringPlans;
    });

  };

  const openEditMonitoringPlan = async monitoringPlan => {
    setMonitoringPlan({ name: monitoringPlan.name, id: monitoringPlan.id });
  };

  const updateMonitoringPlan = async () => {
    const { id: monitoringPlanId, name } = monitoringPlan;
    await monitoringPlanApi.updateMonitoringPlan({ monitoringPlanId, name });
    setMonitoringPlans(prevMonitoringPlans => prevMonitoringPlans.map(mp => mp.id === monitoringPlanId ? { ...mp, name } : mp));
    setEmptyMonitoringPlan();
  };

  // const createMonitoringInstance = async (monitoringPlanId, position) => {
  //   const resp = await monitoringPlanApi.createMonitoringInstance({ monitoringPlanId, position });
  //   const newInstance = { id: parseInt(resp.id), position: parseInt(resp.position, 10), campaigns: [] };
  //   setMonitoringPlans(prevMonitoringPlans => prevMonitoringPlans
  //     .map(mp => {
  //       let instances;
  //       const startIndex = mp.instances.length - position;
  //       if (mp.id === monitoringPlanId) {
  //         const clone = Array.from(mp.instances);
  //         clone.splice(startIndex, 0, newInstance);
  //         instances = clone;
  //       } else {
  //         instances = mp.instances;
  //       }
  //       return { ...mp, instances };
  //     }),
  //   );
  // };

  const removeMonitoringInstance = async (monitoringPlanId, monitoringInstanceId) => {
    await monitoringPlanApi.deleteMonitoringInstance({ monitoringInstanceId });
    setMonitoringPlans(prevMonitoringPlans => {

      const instance = prevMonitoringPlans.find(mp => mp.id === monitoringPlanId).instances.find(i => i.id === monitoringInstanceId);

      const hasUnassignedCampaigns = prevMonitoringPlans[0].id === 'not-monitoring-plan';
      const monitoringPlans = hasUnassignedCampaigns ? [
        ...prevMonitoringPlans
          .map((mp, idx) =>
            idx === 0 ? ({
              ...mp,
              instances: [
                ...mp.instances.map((i, index) => ({ ...i, id: `uc-${index}` })),
                { ...instance, id: `uc-${mp.instances.length}` },
              ],
            })
            : mp.id === monitoringPlanId ? ({
              ...mp,
              instances: mp.instances.filter(i => i.id !== monitoringInstanceId),
            })
            : mp,
          ),
      ] :
        [
          {
            id: 'not-monitoring-plan',
            name: 'Campañas sin planes de monitoreo',
            instances: [ { ...instance, id: `uc-0` } ],
          },
          ...prevMonitoringPlans.map(mp => mp.id === monitoringPlanId ?
            ({
              ...mp,
              instances: mp.instances.filter(i => i.id !== monitoringInstanceId),
            })
            : mp),
        ];

      return monitoringPlans;
    });
  };

  const emptyPlan = { id: '', name: '' };
  const [ monitoringPlan, setMonitoringPlan ] = useState(emptyPlan);
  const setEmptyMonitoringPlan = () => setMonitoringPlan(emptyPlan);

  const [ dialog, setDialog ] = useState({ isOpen: false, data: {}, type: '' });

  const closeDialog = () => setDialog({ isOpen: false, type: '' });

  const openAddCampaignDialog = data => setDialog({ isOpen: true, data, type: 'add-campaign' });

  const openCreateMonitoringPlanDialog = () => setDialog({ isOpen: true, type: 'create' });

  const getDialogType = () => {
    switch (dialog.type) {
      case 'add-campaign' : {
        return <SmaMonitoringInstanceAddCampaignDialog
          actions={{ addCampaignToInstance: campaign => addCampaignToInstance(dialog.data, campaign), closeDialog }}
          options={{
            unassignedCampaignsOptions: monitoringPlans
              .find(mp => mp.id === 'not-monitoring-plan')
              ?.instances.map(i => ({ label: i.name, value: i.id })),
          }}
        />;
      }
      case 'create' : {
        return <SmaMonitoringPlanCreateDialog
          actions={{ createMonitoringPlan, closeDialog }}
        />;
      }
      default:
        break;
    }
  };

  const createMonitoringPlan = async name => {
    const [ monitoringPlan ] = await monitoringPlanApi.createMonitoringPlan({ name, folderId: projectId });
    setMonitoringPlans(prevMonitoringPlans => [ ...prevMonitoringPlans, monitoringPlan ]);
  };

  const addCampaignToInstance = async (monitoringInstanceId, campaign) => {
    await monitoringPlanApi.addCampaignToInstance({ monitoringInstanceId, campaignId: campaign.id });
    // TODO: falta el manejo de que desaparezca de la columna de campañas sin asignar (por eso esta "hideado en los estilos & $addCampaign")
    setMonitoringPlans(prevMonitoringPlans => prevMonitoringPlans
      .map(mp => {
        const instances = mp.instances.map(i =>
          i.id === monitoringInstanceId ? { ...i, campaigns: [ ...i.campaigns, campaign ] } : i,
        );
        return { ...mp, instances };
      }),
    );
  };

  const autoAssignCampaigns = async () => {
    await monitoringPlanApi.autoAssignCampaigns({ folderId: projectId });
    const monitoringPlans = await monitoringPlanApi.getMonitoringPlans({ folderId: projectId });
    setMonitoringPlans(monitoringPlans);
  };

  const hasUnassignedCampaigns = monitoringPlans[0]?.id === 'not-monitoring-plan' && monitoringPlans[0].instances.length > 0;
  const isUnassignedSection = index => index === 0 && hasUnassignedCampaigns;

  return (
    <>
      <Grid container>
        <TitleContainer
          maxWidth="xl"
          breadcrumbs={[
            'projects',
            { name: `Planes de monitoreo de ${upperCaseOnlyFirstLetter(props.name)}`, url: plansUrl },
            { name: 'Comparar' },
          ]}
        >
          <Box display="flex" alignItems="center">
            <IconButton className={classes.backArrow} onClick={() => history.push(plansUrl)}>
              <ArrowBack fontSize='large' color="inherit" />
            </IconButton>
            Gestionar planes de monitoreo
          </Box>
        </TitleContainer>
        <ContentContainer maxWidth="xl">
          <ActionsBox mt={2} mb={1} display="flex" alignItems="flex-end">
            <Box flexGrow={1} display="flex" height="30px">
              <AccessControl action='monitoring-plan:create'>
                <Button size="small" color="primary" startIcon={<AddBox />}
                  variant="contained" onClick={openCreateMonitoringPlanDialog}>
                  Crear plan de monitoreo
                </Button>
              </AccessControl>
              <Box ml={1} />
              <Tooltip title="Agregar campañas automaticamente a nuevos planes de monitoreo">
                <Box component="span">
                  <Button size="small" color="primary" disabled={!hasUnassignedCampaigns}
                    variant="outlined" onClick={autoAssignCampaigns}>
                    <AutoFix fontSize="small" />
                  </Button>
                </Box>
              </Tooltip>
            </Box>
            <Box className={classes.monitoringFilter}>
              <InputLabel shrink>Planes de monitoreo (opcional)</InputLabel>
              <AutocompleteWrapper
                multiple
                options={monitoringPlansOptions}
                onChange={(_, selectedOptions) => setSelectedMonitoringPlans(selectedOptions)}
                getOptionSelected={(option, value) => option.value === value.value}
                clearable
              />
            </Box>
          </ActionsBox>
          <Box display="flex">
            <DragDropContext onDragEnd={onDragEnd}>
              {monitoringPlans.map((plan, ind) => (
                <Box
                  key={ind}
                  className={clsx(classes.dragNDropContainer, { [classes.dragNDropContainerBorder]: isUnassignedSection(ind) })}
                >
                  <Box p={1} className={classes.monitoringPlanContainer} mt={2} display="flex" alignItems="center" justifyContent="center">
                    <Editable
                      isEditing={parseInt(monitoringPlan.id, 10) === parseInt(plan.id, 10)}
                      Component={() =>
                        <Box ml={isUnassignedSection(ind) ? 0 : '48px'}
                          display="flex" flexDirection="column" justifyContent="center" flexGrow={0.5}
                        >
                          {!isUnassignedSection(ind) && <Typography variant="caption" align="center">Plan de monitoreo</Typography>}
                          <Typography variant="subtitle2" color={isUnassignedSection(ind) ? 'primary' : 'textPrimary'} align="center">
                            <strong>{plan.name} {isUnassignedSection(ind) && <>({plan.instances.length})</>}</strong>
                          </Typography>
                        </Box>
                      }
                      closeEdition={setEmptyMonitoringPlan}
                    >
                      <Box display="flex" alignItems="center">
                        <TextField
                          value={monitoringPlan.name}
                          variant="outlined"
                          size="small"
                          onChange={e => setMonitoringPlan({ name: e.target.value, id: plan.id })}
                          autoComplete="off"
                          autoFocus
                          InputProps={{ endAdornment:
                            <Box ml="-51px" display="flex" alignItems="center">
                              <Check className={classes.editableAction} onClick={updateMonitoringPlan} />
                              <Close className={classes.editableAction} onClick={setEmptyMonitoringPlan} />
                            </Box>,
                          }}
                        />
                      </Box>
                    </Editable>
                    {parseInt(monitoringPlan.id, 10) !== parseInt(plan.id, 10) && !isUnassignedSection(ind) &&
                      <Box display="flex">
                        <Edit fontSize="small" className={classes.planActionIcons} onClick={() => openEditMonitoringPlan(plan)} />
                        <Delete fontSize="small" className={classes.planActionIcons} onClick={() => deleteMonitoringPlan(plan.id)} />
                      </Box>
                    }
                  </Box>
                  <Droppable droppableId={ind.toString()}>
                    {(provided, planSnapshot) => (
                      <Box
                        ref={provided.innerRef}
                        className={clsx(classes.planContainer, { [classes.planDragging]: planSnapshot.isDraggingOver })}
                        {...provided.droppableProps}
                      >
                        {plan.instances.map((monitoringInstance, index) => (
                          <Draggable key={monitoringInstance.id} draggableId={monitoringInstance.id.toString()} index={index}>
                            {(provided, snapshot) => (
                              <>
                                <Box
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={clsx(
                                    classes.monitoringContainer,
                                    provided.draggableProps.style,
                                    { [classes.monitoringDragging]: snapshot.isDragging },
                                  )}
                                >
                                  <Paper className={clsx({ [classes.monitoringDragging]: snapshot.isDragging })}>
                                    <Box p={isUnassignedSection(ind) ? 0.1 : 1} display="flex" flexDirection="column"
                                      alignItems="center" textAlign="center">
                                      {isUnassignedSection(ind) ?
                                        <Typography variant="caption">Campaña</Typography>
                                        :
                                        <Box display="flex" alignItems="center" width="100%">
                                          <Box flexGrow={1} mb={0.5} ml="36px">
                                            Monitoreo {parseInt(monitoringInstance.position, 10) + 1}
                                          </Box>
                                          <AddCircleOutline
                                            className={classes.addCampaign}
                                            onClick={() => openAddCampaignDialog(monitoringInstance.id)}
                                          />
                                          <HighlightOff
                                            className={classes.removeInstance}
                                            onClick={() => removeMonitoringInstance(plan.id, monitoringInstance.id)}
                                          />
                                        </Box>
                                      }
                                      {monitoringInstance.campaigns.map(c =>
                                        <Tooltip key={c.id} title={c.name}>
                                          <Box>
                                            {isUnassignedSection(ind) ?
                                              <Box display="flex" alignItems="center" className={classes.unassignedCampaignBox} >
                                                <Box display="flex" flexDirection="column" width={208}>
                                                  <Typography className={classes.unassignedCampaignName} variant="caption">
                                                    {c.name}
                                                  </Typography>
                                                  <Typography variant="caption">{c.startDate} - {c.endDate}</Typography>
                                                </Box>
                                                <OpenInNew
                                                  className={classes.unassignedCampaignLink}
                                                  onClick={() => window.open(campaignUrl(c.id), '_blank')}
                                                />
                                              </Box>
                                              : <LabelChip
                                                size="medium"
                                                label={
                                                  <Box display="flex" flexDirection="column">
                                                    <Typography className={classes.labelChipCampaign} variant="caption">
                                                      {c.name}
                                                    </Typography>
                                                    <Typography variant="caption">{c.startDate} - {c.endDate}</Typography>
                                                  </Box>
                                                }
                                                className={classes.labelChip}
                                                variant="outlined"
                                                color="default"
                                                onDelete={() => window.open(campaignUrl(c.id), '_blank')}
                                                deleteIcon={<OpenInNew className={classes.campaignLink} />}
                                              />
                                            }
                                          </Box>
                                        </Tooltip>,
                                      )}
                                    </Box>
                                  </Paper>
                                </Box>
                                {
                                  (plan.instances.length !== index + 1)
                                    && ((!planSnapshot.isDraggingOver && plan.id !== 'not-monitoring-plan') ?
                                      <Box display="flex" flexDirection="column" alignItems="center">
                                        <Box className={classes.separatorBox} display="flex" flexDirection="column" px={3}>
                                          <Box
                                            display="flex"
                                            justifyContent="center"
                                            className={classes.separator}
                                          />
                                          {/* {plan.id !== 'not-monitoring-plan' &&
                                            <AddBox
                                              className={classes.addInstance}
                                              fontSize='small'
                                              onClick={() => createMonitoringInstance(plan.id, monitoringInstance.position)}
                                            />
                                          } */}
                                          <Box
                                            display="flex"
                                            justifyContent="center"
                                            className={classes.separator}
                                          />
                                        </Box>
                                      </Box> : <Box height="16px" />
                                    )
                                }
                              </>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </Box>
              ))}
            </DragDropContext>
          </Box>
          <br />
        </ContentContainer>
      </Grid>
      <DialogWrap maxWidth='sm' fullWidth onClose={closeDialog} open={dialog.isOpen}>
        {getDialogType()}
      </DialogWrap>
    </>
  );
};

SmaMonitoringPlansComparisonContainer.propTypes = {
  name: PropTypes.string,
};


export {
  SmaMonitoringPlansComparisonContainer,
};