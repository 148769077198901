import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useRouteMatch } from 'react-router';

import { ProjectDrawer } from 'src/components';
import { ProjectRoutes } from 'src/scenes/Project/ProjectRoutes';
import { useProjectApi } from 'src/services';


const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  mainContainer: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
}));

const ProjectContainer = () => {
  const [ name, setName ] = useState('');
  const classes = useStyles();
  const match = useRouteMatch();
  const projectApi = useProjectApi();

  const { projectId } = match.params;

  useEffect(() => {
    const fetchData = async () => {
      const [ { name } ] = await projectApi.getProject(projectId);
      setName(name);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ projectId ]);

  useEffect(() => {
    document.title = name ? `${name} - Nviro Monitor` : 'Nviro Monitor';
  }, [ name ]);

  return (
    <>
      <ProjectDrawer name={name} />
      <Box className={classes.mainContainer}>
        <ProjectRoutes name={name} />
      </Box>
    </>
  );
};


export { ProjectContainer };