import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useRouteMatch } from 'react-router';

import { SmaCampaignDrawer } from 'src/components';
import { useCampaignApi } from 'src/services';
import { SmaCampaignRoutes } from 'src/scenes/Project/scenes/Campaign/SmaCampaignRoutes';


const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  mainContainer: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
  maxMainContainer: {
    width: '100%',
  },
}));

const SmaCampaignContainer = () => {

  const classes = useStyles();
  const match = useRouteMatch();
  const campaignApi = useCampaignApi();

  const { campaignId } = match.params;

  const [ isOpenDrawer, setIsOpenDrawer ] = useState(true);
  const [ campaign, setCampaign ] = useState({ name: '', titleHolder: '' });

  const toggleDrawer = () => setIsOpenDrawer(prevState => !prevState);

  useEffect(() => {
    const fetchData = async () => {
      const [ campaign ] = await campaignApi.getCampaign(campaignId);
      setCampaign(campaign);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ campaignId ]);
  useEffect(() => {
    document.title = campaign ? `${campaign.name} - Nviro Monitor` : 'Nviro Monitor';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ campaign.name ]);

  return (
    <>
      <SmaCampaignDrawer
        width={drawerWidth}
        isOpenDrawer={isOpenDrawer}
        actions={{ toggleDrawer }}
        campaignId={campaignId}
        campaignName={campaign.name}
        isBaseline={campaign.isBaseline}
      />
      <Box className={isOpenDrawer ? classes.mainContainer : classes.maxMainContainer}>
        <SmaCampaignRoutes campaign={campaign} />
      </Box>
    </>
  );
};


export { SmaCampaignContainer };