import React from 'react';
import { Box, InputLabel, FormControlLabel, Checkbox, Fade, Switch, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { AutocompleteWrapper, LabelChip, SelectChip } from 'src/components';


const useStyles = makeStyles(theme => ({
  label: {
    fontSize: '0.875rem',
  },
  inputLabelShrink: {
    transformOrigin: 'bottom right',
    marginRight: theme.spacing(1) * -1,
    marginTop: '-10px',
    marginBottom: '2px',
  },
  groupLabel: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const sortOptions = [ { label: 'Ascendente', value: 'asc' }, { label: 'Descendente', value: 'desc' } ];

const SearchParam = ({ id, label, options, getters, handlers }) => {

  const classes = useStyles();

  const { selectedChartTypeValue, separatedKey, isStacked, selectedOptions, chartJsType,
    sortType, isHiding, isMultiVariableAllowed } = getters;
  const { setSelectedOptions, setSeparatedKey, handleSeparation, setIsStacked, setSortType, setIsHiding } = handlers;

  const isSelectedChartType = selectedChartTypeValue === id;
  const isDataSeparated = separatedKey === id;
  const hasAnotherDataSeparated = separatedKey !== undefined && separatedKey !== id || isMultiVariableAllowed;
  const isLine = chartJsType === 'line';

  const isSorted = Boolean(sortType);

  const handleSeparationChange = handleSeparation ? handleSeparation : () => setSeparatedKey(prevKey => prevKey ? undefined : id);

  const hasTwoOrMoreSelectedOptions = selectedOptions.length !== 1;
  const hasZeroOrAllSelectedOptions = selectedOptions.length === 0 || selectedOptions.length === options.length;

  return (
    <Box>
      <Box display="flex" alignItems="flex-end">
        <InputLabel shrink>
          {label} a {isSelectedChartType ? (isHiding ? 'excluir' : 'mostrar') : (isHiding ? 'excluir' : 'considerar') }
        </InputLabel>
        <Box flexGrow={1} />
        <InputLabel shrink classes={{ shrink: classes.inputLabelShrink }}>
          <FormControlLabel
            control={
              <Switch size="small" checked={isHiding} onChange={() => setIsHiding(ps => !ps)} disabled={hasZeroOrAllSelectedOptions} />
            }
            label="Excluir"
          />
        </InputLabel>
      </Box>
      <AutocompleteWrapper
        multiple
        value={selectedOptions}
        options={options}
        onChange={(_, selectedOptions) => {
          if (selectedOptions.length <= 1) {
            setSeparatedKey(undefined);
          }
          if (selectedOptions.length === 0 || selectedOptions.length === options.length) {
            setIsHiding(false);
          }
          setSelectedOptions(selectedOptions);
        }}
        getOptionSelected={(option, value) => option.value === value.value}
        clearable
        renderOption={option =>
          <Box display="flex" width="100%">
            <Typography variant="body1" noWrap title={option.label}>{option.label}</Typography>
            <Box flexGrow={1} mr={1}></Box>
            {option.data?.isAlias &&
              <Box display="flex" alignItems="center">
                <LabelChip size="small" label="Grupo" color="primary" />
              </Box>
            }
          </Box>
        }
      />
      {isSelectedChartType &&
        <Box display="flex">
          <FormControlLabel
            classes={{ label: classes.label }}
            control={
              <Checkbox
                checked={isSorted}
                size="small"
                onChange={() => setSortType(ps => ps ? undefined : sortOptions[0].value)}
                color="secondary"
                disabled={hasAnotherDataSeparated}
              />
            }
            label="¿Ordenar por cantidad?"
          />
          {isSorted &&
            <Fade direction="right" in={isSorted}>
              <SelectChip
                value={sortType}
                options={sortOptions}
                onChange={option => setSortType(option.value)}
                chipProps={{ size: 'small' }}
              />
            </Fade>
          }
        </Box>
      }
      {!isSelectedChartType &&
        <Box display="flex">
          <FormControlLabel
            classes={{ label: classes.label }}
            control={
              <Checkbox
                checked={isDataSeparated}
                size="small"
                onChange={handleSeparationChange}
                color="secondary"
                disabled={hasAnotherDataSeparated || !hasTwoOrMoreSelectedOptions || isSorted || isMultiVariableAllowed}
              />
            }
            label={`¿Separar ${label.toLowerCase()}?`}
          />
          {isDataSeparated && !isLine &&
            <Fade direction="right" in={isDataSeparated}>
              <FormControlLabel
                classes={{ label: classes.label }}
                control={
                  <Checkbox
                    checked={isStacked}
                    size="small"
                    onChange={() => setIsStacked(ps => !ps)}
                    color="secondary"
                    value={isStacked}
                  />
                }
                label={`¿Apilar barras?`}
              />
            </Fade>
          }
        </Box>
      }
    </Box>
  );
};

SearchParam.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  getters: PropTypes.object,
  handlers: PropTypes.object,
};


export { SearchParam };