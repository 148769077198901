import React from 'react';
import { Box, Container, Grid, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { HeaderUI, BrowserUI } from 'src/scenes/Home/components';


const useStyles = makeStyles(theme => ({
  analyticsSection: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(0),
  },
  imgContainer: {

  },
}));

// TODO: la foto no es muy "Plataforma de seguimiento y análisis de biodiversidad", podría ser mejor una foto del dashboard
// o algo que incluya más tipos de analíticas, cuando esas vistas estén más consolidadas.
const AnalyticsPageUI = () => {
  const classes = useStyles();
  return (
    <div aria-describedby='example-desc' aria-labelledby='example-label'>
      <div aria-hidden="true">
        <BrowserUI size="small">
          <HeaderUI user="Juan Von Hildebrand" />
          <Box display="flex">
            <Grid container className={classes.analyticsSection}>
              <Container maxWidth="xl">
                <Typography variant="h4" display="inline" color="primary">Analíticas</Typography>
                <Divider />
                <br />
                <Box className={classes.imgContainer}>
                  <img src="/img/landing/plan-area-example.png" width="100%"/>
                </Box>
              </Container>
            </Grid>
          </Box>
        </BrowserUI>
      </div>
      <p id="example-label" hidden>Visual demonstrativa del contenido de Nviro Monitor.</p>
      <p id="example-desc" hidden>{planAreaAlt}</p>
    </div>
  );
};

const planAreaAlt = 'Simulación de la página web de Nviro Monitor. Muestra dos paneles rectangulares,' +
  ' ambos cubriendo casi todo el ancho de la página.\n\nEl panel superior es un mapa a base de imágenes satélitales, mostrando un área' +
  ' con valles cubiertos de verde y un poco de nieve en una esquina. Al centro del mapa, cubriendo cerca de la mitad del área del mapa,' +
  ' hay una sección rectangular donde los colores del mapa cambian al de un mapa coloreado de NDVI (con colores verdes y' +
  ' rojos con alta saturación). En esta sección también hay un polígono casi rectangular celeste oscuro, cubriendo lo que parece ser un' +
  ' río y en el área del polígono varios puntos celestes oscuros rellenos, y celestes claros con relleno semi-transparente.' +
  ' En la leyenda del mapa se muestra que los puntos celestes claros representan "Ocurrencias". El polígono y los puntos celestes oscuros' +
  ' representan un "Área de interés" cuya capa del mapa es llamada "estudio-choshuenco.kml". Además en la leyenda hay una última sección' +
  ' que dice "Información satelital" que contiene una capa del mapa llamada "NDVI", y a la derecha de ese texto hay un botón con forma de' +
  ' ícono de ojo tachado (para ocultar el NDVI del mapa). En la parte inferior del mapa además hay una interfaz similar a los controles' +
  ' de un video, con íconos que representan acciones de atrás, reproducir y adelatar; al lado de aquellos botones, hay un sello' +
  ' de tiempo con un deslizador, y al lado de esto, hay un "0.1fps" con otro deslizador. \n\nEl panel inferior tiene una sección con un' +
  ' gráfico titulado "Evolución área de trabajo", el cual es un gráfico de líneas con 9 líneas de colores, el eje X son los 12 meses del' +
  ' año y en el eje Y números decimales del 0 al 0.8 a saltos de 0.1. La leyenda muestra que cada línea es el NDVI de un año desde el' +
  ' 2016 al 2024. Además, abajo del gráfico hay una sección con botones para controlar lo que se muestra en los ejes: un grupo de botones' +
  ' dice "Tipo de agrupación (Eje X)", donde hay un botón no marcado con el texto "Histórico" y un botón seleccionado con el texto' +
  ' "Por año"; a su lado hay otro grupo de botónes descritos con "Valor a graficar (Eje Y)" con dos botones que dicen NDVI y NDWI, con el' +
  ' que dice NDVI marcado, y justo abajo de este grupo hay una interfaz de interruptor (apagado) que dice "Habilitar graficar múltiples' +
  ' valores".';


export {
  AnalyticsPageUI,
};