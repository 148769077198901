import React from 'react';
import { Grid, Paper, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';

import { CoolBackground } from 'src/scenes/Home/components';
import { NviroButton } from 'src/components';
import { useAuth0 } from '@auth0/auth0-react';


const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh',
  },
  grid: {
    zIndex: 0,
  },
  paper: {
    paddingRight: theme.spacing(12),
    paddingLeft: theme.spacing(12),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    width: '344px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      width: '100%',
    },
  },
  title: {
    color: theme.palette.common.gray600,
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '1.125rem',
  },
}));

const MobileNotYetContainer = () => {
  const classes = useStyles();
  const { logout } = useAuth0();

  const history = useHistory();
  const handleLogout = async () => {
    logout({ logoutParams: window.location.origin });
    history.push(`/`);
    localStorage.clear();
  };

  return (
    <Grid className={classes.container} direction="column" container alignItems="center" justifyContent="center">
      <CoolBackground />
      <Grid item className={classes.grid} container justifyContent="center">
        <Paper className={classes.paper} elevation={5}>
          <Typography className={classes.title}>
            Para acceder a todas las herramientas y potencialidades de nuestra plataforma
            web es necesario que lo hagas desde un computador
          </Typography>
          <Box pt={{ xs: 2, md: 5 }} width="100%">
            <NviroButton size="small" onClick={handleLogout}>volver</NviroButton>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};


export {
  MobileNotYetContainer,
};