import React, { useEffect, useRef, useState } from 'react';
import { Box, Checkbox, Collapse, Divider, InputLabel, List, ListItem, ListItemIcon, ListItemText,
  Typography, Input, DialogContent, DialogTitle, DialogActions, Button, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import * as togeojson from '@tmcw/togeojson';
import L from 'leaflet';
import JsZip from 'jszip';

import { tryParseXml } from 'src/utils/util';
import config from 'src/config/local';
import { MapWrapper, AlertWrapper, NviroButton, DialogButton } from 'src/components';
import { useFetchStatus } from 'src/hooks';


const { maxSizeFileUpload, maxKmlPoints, maxKmlLines, maxKmlPolygons } = config;

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
  },
  textField: {
    height: theme.spacing(4),
    fontSize: theme.typography.body2.fontSize,
  },
  list: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.gray400,
    borderRadius: theme.shape.borderRadius,
  },

  listItem: {
    alignItems: 'center',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.common.gray400,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.lightGray,
    },
  },
  itemData: {
    fontWeight: 600,
    marginLeft: theme.spacing(1),
  },
  selector: {
    marginTop: theme.spacing(1),
  },
  hidden: {
    display: 'none',
  },
  fileName: {
    fontWeight: 'bold',
  },
  uploadButton: {
    height: '2.5rem',
  },
  configButton: {
    height: '2.3rem',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '10rem',
  },
  group: {
    borderRadius: theme.shape.borderRadius,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.gray,
    padding: theme.spacing(1),
  },
  map: {
    marginTop: theme.spacing(1),
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
}));

const geometriesMapper = {
  Point: 'punto(s)',
  Polygon: 'polígono(s)',
  LineString: 'línea(s)',
};

const SmaMapCreateDialog = ({ actions }) => {
  const mapRef = useRef();

  const fileInputRef = useRef();
  const layerCounterId = useRef(0);

  const classes = useStyles();

  const [ interestArea, setInterestArea ] = useState();

  const { fetchStatus, handleConfirm } = useFetchStatus({
    confirmFn: () => actions.saveAnalyticMap(interestArea),
    closeFn: actions.closeDialog,
  });

  const handleAreaImport = (kmlData, config) => {
    setInterestArea(() => {
      const id = layerCounterId.current++;
      const { showMarkers, showLines, showPolygons } = config;
      const filteredFeats = kmlData.geojson.features.filter(feat => {
        const type = feat.geometry?.type;
        return type && (type === 'Point' && showMarkers || type === 'LineString' && showLines || type === 'Polygon' && showPolygons);
      });
      const newLayer = { geojson: { ...kmlData.geojson, features: filteredFeats }, id, name: kmlData.fileName };
      const newInteresArea = { kmlData, config: { showLines, showMarkers, showPolygons }, layer: newLayer };
      return newInteresArea;
    });
  };

  const [ confirmingKml, setConfirmingKml ] = useState(false);
  const [ loadingKmlForImport, setLoadingKmlForImport ] = useState(false);
  const [ kmlFileData, setKmlFileData ] = useState(null); // datos del kml del archivo que se está importando actualmente
  const [ importConfig, setImportConfig ] = useState({ showMarkers: true, showLines: true, showPolygons: true, replaceAll: false });
  const [ importingKml, setImportingKml ] = useState(false);
  const [ kmlValidationErrorMessage, setKmlValidationErrorMessage ] = useState('');
  const [ invalidSettingsMessage, setInvalidSettingsMessage ] = useState('');

  const [ leafletLayer, setLeafletLayer ] = useState();

  const mapOptions = {
    scrollWheelZoom: false,
    zoom: 8,
    doubleClickZoom: true,
    attributionControl: false,
  };

  useEffect(() => {
    if (confirmingKml) {
      return;
    }
    let layer;
    if (interestArea) {

      layer = L.geoJSON(interestArea.layer.geojson, {
        id: interestArea.layer.id,
        name: interestArea.layer.name,
        pointToLayer: (feature, latlng) => new L.CircleMarker(latlng, { radius: 5, fillOpacity: 1 }),
      });
    }
    setLeafletLayer(layer);
    setImportingKml(false);
    // eslint-disable-next-line
  }, [ confirmingKml, interestArea ]);

  const handleOptionClick = optionType => () => setImportConfig(ps => ({ ...ps, [optionType]: !ps[optionType] }));

  const handleFileUpload = async e => {
    setInvalidSettingsMessage('');
    setImportConfig({ showMarkers: true, showLines: true, showPolygons: true, replaceAll: false });
    const file = e.target.files[0];
    let kmlFile;
    const propertyToCheck = file.type || file.name;
    setLoadingKmlForImport(true);
    try {
      if (file.size > maxSizeFileUpload) {
        handleBadUpload(`El tamaño del archivo supera el máximo permitido (${maxSizeFileUpload / 1000000} MB)`);
        return;
      }
      if (propertyToCheck.match(/\.kml/)) {
        kmlFile = file;
      } else if (propertyToCheck.match(/\.kmz/)) {
        const kmzZip = await new JsZip().loadAsync(file);
        const kmlZip = kmzZip.filter(relativePath => relativePath.match(/\.kml$/))[0];
        kmlFile = await kmlZip.async('blob');
      } else {
        handleBadUpload('Solo se aceptan archivos .kml o .kmz');
        return;
      }

      const kmlAsText = await new Response(kmlFile).text();
      const kmlDoc = tryParseXml(kmlAsText);
      if (!kmlDoc) {
        console.error('Error recibiendo archivo. El KML es inválido.');
        handleBadUpload('Error recibiendo archivo. El KML es inválido.');
        return;
      }

      const geojson = togeojson.kml(kmlDoc);
      // las geometrías pueden ser una geometría como se debe o null
      if (!geojson?.features?.length || geojson.features.some(feat => feat.geometry === undefined)) {
        console.error('KML parece no contener geometrías');
        handleBadUpload('Hubo un problema al intentar importar el KML recibido. Por favor revisa que el archivo sea válido.');
        return;
      }

      const featuresWithLocation = geojson.features.filter(feat => feat.geometry !== null);
      const dataByGeometry = featuresWithLocation.reduce((acc, obj) => {
        const key = obj.geometry.type;
        acc[key] = acc[key] + 1 || 1;
        return acc;
      }, {});

      if (!Object.keys(dataByGeometry).some(key => [ 'Point', 'LineString', 'Polygon' ].includes(key))) {
        handleBadUpload(`Solo se soportan geometrías de tipo punto, linea y/o polígono dentro del KML`);
        return;
      }
      const importPointsCount = dataByGeometry['Point'];
      const importLinesCount = dataByGeometry['LineString'];
      const importPolygonsCount = dataByGeometry['Polygon'];
      if (importPointsCount && importPointsCount > maxKmlPoints) {
        handleBadUpload(
          `El KML recibido supera el máximo de puntos permitidos (${importPointsCount} recibidos, ${maxKmlPoints} permitidos)`,
        );
        return;
      }
      if (importLinesCount && importLinesCount > maxKmlLines) {
        handleBadUpload(
          `El KML recibido supera el máximo de líneas permitidas (${importLinesCount} recibidas, ${maxKmlLines} permitidas)`,
        );
        return;
      }
      if (importPolygonsCount && importPolygonsCount > maxKmlPolygons) {
        handleBadUpload(
          `El KML recibido supera el máximo de polígonos permitidos (${importPolygonsCount} recibidos, ${maxKmlPolygons} permitidos)`,
        );
        return;
      }
      const data = {
        kmlFile,
        fileName: file.name,
        dataByGeometry,
        geojson: { ...geojson, features: featuresWithLocation },
      };

      setKmlFileData(data);
      setLoadingKmlForImport(false);
      setKmlValidationErrorMessage(false);
      setConfirmingKml(true); // ahora se pueden configurar los showCosas y confirmar para pasarle el kml al mapa
    } catch (e) {
      console.error(e);
      handleBadUpload('Ocurrió un error inesperado, por favor intenta más tarde');
    }
  };

  const handleImportClick = () => {
    try {
      if (importingKml) {
        return;
      }
      if (!importConfig.showMarkers && !importConfig.showLines && !importConfig.showPolygons) {
        setInvalidSettingsMessage('No tienes ningún tipo de geometría seleccionada.'
          + ' Debes importar por lo menos un tipo de geometría del KML/KMZ');
        return;
      }

      setInvalidSettingsMessage('');
      setImportingKml(true);
      handleAreaImport(kmlFileData, importConfig);
      setConfirmingKml(false);
    } catch (e) {
      console.error(e);
      handleBadUpload('Ocurrió un error inesperado, por favor intenta más tarde');
    }
  };

  const cancelImport = () => {
    setConfirmingKml(false);
    setImportConfig({ showMarkers: true, showLines: true, showPolygons: true, replaceAll: false });
    setInvalidSettingsMessage('');
  };

  const handleBadUpload = message => {
    setKmlValidationErrorMessage(message);
    setLoadingKmlForImport(false);
    setConfirmingKml(false);
    setImportingKml(false);
  };
  const disabledCheckboxes = Boolean(kmlValidationErrorMessage || importingKml);
  const disabledFileInput = interestArea > 4 || loadingKmlForImport || importingKml;

  return (
    <>
      <DialogTitle id="form-dialog-title">Agregar área de interés</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <InputLabel shrink>Área de interés</InputLabel>
          <Box px={1} width="100%" className={classes.group}>
            <Box>
              <Box width="100%" display="flex" alignItems="center">
                <InputLabel htmlFor="interesting-file">
                  <Box width="320px">
                    <NviroButton
                      color='primary'
                      className={classes.uploadButton}
                      onClick={() => fileInputRef.current.click()}
                      disabled={loadingKmlForImport}
                    >
                      Cargar área de interés (KML/KMZ)
                    </NviroButton>
                  </Box>
                  {!loadingKmlForImport &&
                    // Chrome tiene problemas reimportando el mismo archivo luego de cancelar o de importarlo y borrarlo del mapa
                    // creo que es porque el input queda con el valor y si ve que quieres resubir el mismo archivo dice "oh, ya lo tengo
                    // así que no haré nada". Por eso lo estoy condicionando con el loadingKML... TODO: ver si hay una solución menos parche
                    <Input className={classes.hidden} id="interesting-file" type="file" inputProps={ { accept: '.kml, .kmz' } }
                      ref={fileInputRef} onChange={handleFileUpload} disabled={disabledFileInput}/>
                  }
                </InputLabel>
                <Box ml={1}>
                  {confirmingKml ?
                    <small>
                      Archivo seleccionado:
                      <span className={classes.fileName}> {kmlFileData?.fileName ?? ''}</span>
                    </small>
                    : <small>
                      Seleccione un archivo...
                    </small>
                  }
                </Box>
              </Box>
            </Box>
            <Collapse in={confirmingKml} timeout={500}>
              {kmlFileData &&
                <Box py={2}>
                  <Typography variant="body2">
                    Se encontraron las siguientes geometrías, seleccione las que desea incorporar al área de interés
                  </Typography>
                  <Box px={3} className={classes.group}>
                    <List>
                      {kmlFileData.dataByGeometry['Point'] &&
                        <ListItem role={undefined} dense button onClick={handleOptionClick('showMarkers')}>
                          <ListItemIcon>
                            <Checkbox
                              disabled={disabledCheckboxes} edge="start" checked={Boolean(importConfig.showMarkers)} disableRipple />
                          </ListItemIcon>
                          <ListItemText primary={`${kmlFileData.dataByGeometry['Point']} ${geometriesMapper['Point']}`} />
                        </ListItem>}
                      {kmlFileData.dataByGeometry['LineString'] &&
                        <ListItem role={undefined} dense button onClick={handleOptionClick('showLines')}>
                          <ListItemIcon>
                            <Checkbox
                              disabled={disabledCheckboxes} edge="start" checked={Boolean(importConfig.showLines)} disableRipple />
                          </ListItemIcon>
                          <ListItemText primary={`${kmlFileData.dataByGeometry['LineString']} ${geometriesMapper['LineString']}`} />
                        </ListItem>}
                      {kmlFileData.dataByGeometry['Polygon'] &&
                        <ListItem role={undefined} dense button onClick={handleOptionClick('showPolygons')}>
                          <ListItemIcon>
                            <Checkbox
                              disabled={disabledCheckboxes} edge="start" checked={Boolean(importConfig.showPolygons)} disableRipple />
                          </ListItemIcon>
                          <ListItemText primary={`${kmlFileData.dataByGeometry['Polygon']} ${geometriesMapper['Polygon']}`} />
                        </ListItem>}
                    </List>
                    <Divider />
                    {invalidSettingsMessage &&
                      <AlertWrapper variant="outlined" severity='error'>
                        {invalidSettingsMessage}
                      </AlertWrapper>
                    }
                    <Divider />
                    <Box my={2} display="flex" justifyContent="center">
                      <Button className={classes.configButton} onClick={cancelImport} variant="outlined" color="default" size="small">
                        Cancelar
                      </Button>
                      <Button disabled={!confirmingKml || !kmlFileData} onClick={handleImportClick}
                        className={classes.configButton} color="primary" size="small" variant="contained">
                        Importar
                      </Button>
                    </Box>
                  </Box>
                </Box>
              }
            </Collapse>
            {kmlValidationErrorMessage &&
              <Box my={2}>
                <AlertWrapper variant="outlined" severity='error'>
                  {kmlValidationErrorMessage}
                </AlertWrapper>
              </Box>
            }
            <MapWrapper id='interest-area-step' className={classes.map} options={mapOptions}
              height={400} layers={leafletLayer ? [ leafletLayer ] : []} mapRef={mapRef} fitBoundsOnUpdate />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        {interestArea ?
          <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color="primary">
            Guardar área de interés
          </DialogButton>
          :
          <Tooltip title="Debes seleccionar un área de interés">
            <span>
              <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} disabled color="primary">
                Guardar área de interés
              </DialogButton>
            </span>
          </Tooltip>
        }
      </DialogActions>
    </>
  );
};

SmaMapCreateDialog.propTypes = {
  actions: PropTypes.object,
};


export {
  SmaMapCreateDialog,
};