import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { LabelChip, AccessControl } from 'src/components';
import { Delete } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
  label: {
    marginRight: theme.spacing(1),
    maxWidth: '100%',
  },
  paper: {
    height: '100%',
    '&:hover': {
      '& $actionIcons': {
        visibility: 'visible',
        animationName: '$fadeIn',
        animation: 'linear',
        animationDuration: '300ms',
      },
    },
  },
  title: {
    lineHeight: 1,
  },
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  actionIcons: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.gray600,
    cursor: 'pointer',
    visibility: 'hidden',
    margin: theme.spacing(0.25),
    '&:hover': {
      backgroundColor: theme.palette.common.gray400,
    },
  },
}));

const SmaGroup = ({ names, alias, actions }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Box p={2} display="flex" flexDirection="column">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography className={classes.title} variant="h6">{alias}</Typography>
          </Box>
          <AccessControl action='monitoring-plan:delete-group'>
            <Box display="flex" alignSelf="center">
              <Delete fontSize="small" className={classes.actionIcons} onClick={actions.openDeleteGroup} />
            </Box>
          </AccessControl>
        </Box>
        <Divider />
        <Box mt={2}>
          {names.map((name, idx) => <LabelChip key={idx} className={classes.label} variant="outlined" color="default" label={name} />)}
        </Box>
      </Box>
    </Paper>
  );
};

SmaGroup.propTypes = {
  alias: PropTypes.string,
  names: PropTypes.array,
  actions: PropTypes.object,
};


export {
  SmaGroup,
};