import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, InputLabel } from '@material-ui/core';

import { DialogButton, AlertWrapper, AutocompleteWrapper } from 'src/components';
import { useFetchStatus } from 'src/hooks';


const SmaMonitoringInstanceAddCampaignDialog = props => {

  const { actions, options: { unassignedCampaignsOptions = [] } } = props;

  const [ selectedCampaign, setSelectedCampaign ] = useState({ label: '', value: '' });

  const { fetchStatus, errorMessage, hasError, handleConfirm } = useFetchStatus({
    confirmFn: () => actions.addCampaignToInstance({ id: selectedCampaign.value, name: selectedCampaign.label }),
    closeFn: actions.closeDialog,
  });

  return (
    <>
      <DialogTitle id="form-dialog-title">Agregar campaña en monitoreo</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Box my={3}>
            <InputLabel required shrink>Campaña</InputLabel>
            <AutocompleteWrapper
              freeSolo
              value={selectedCampaign}
              options={unassignedCampaignsOptions}
              onChange={(_, selectedCampaign) => setSelectedCampaign(selectedCampaign)}
              getOptionSelected={(option, value) => option.value === value.value}
            />
          </Box>
          {hasError && <AlertWrapper severity="error">{errorMessage}</AlertWrapper>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog} color="secondary">Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Agregar</DialogButton>
      </DialogActions>
    </>
  );
};

SmaMonitoringInstanceAddCampaignDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
};


export {
  SmaMonitoringInstanceAddCampaignDialog,
};
