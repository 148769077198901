const checkPermission = (
  { action, extraCondition = true, webContext },
) => {
  const roleId = webContext?.currentUser?.roleId;

  const permissions = webContext?.permissions;

  const roleToCheck = roleId ?? 'consultant';

  const permCheck = roleToCheck && permissions && permissions.includes(action);

  const rolePermsCheck = permCheck;

  return (rolePermsCheck && extraCondition);
};


export {
  checkPermission,
};