import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/server';
import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import L from 'leaflet';
import PropTypes from 'prop-types';

import { TitleContainer, ContentContainer, MapWrapper } from 'src/components';
import { useRouteMatch } from 'react-router';
import { useCampaignApi } from 'src/services';
import { getMarkerIcon } from 'src/components/map/mapHelper';
import { ReplicaStationPopup } from 'src/scenes/Project/scenes/Campaign/scenes/Monitoring/ReplicaStationPopup';
import themes from 'src/themes';
import { getBaseBreadcrumbs } from 'src/scenes/Project/scenes/Campaign/SmaCampaignHelper';


const getElement = Component =>
  ReactDOM.renderToString(
    <ThemeProvider theme={themes['nviro']}>
      <Component />
    </ThemeProvider>,
  );

const getLatLng = replicaStation => {
  const { centralLatitude, centralLongitude, startLatitude, startLongitude, endLatitude, endLongitude } = replicaStation;
  if (centralLatitude && centralLongitude) {
    return { lat: centralLatitude, lng: centralLongitude };
  } else if (startLatitude && startLongitude) {
    return { lat: startLatitude, lng: startLongitude };
  } else if (endLatitude && endLongitude) {
    return { lat: endLatitude, lng: endLongitude };
  } else {
    return undefined;
  }
};

const createMarker = replicaStation => {
  const coords = getLatLng(replicaStation);
  if (coords) {
    const marker = new L.Marker(coords, { icon: getMarkerIcon('ER', 'nviro') });
    marker.metaData = replicaStation;
    marker.bindPopup(getElement(() => <ReplicaStationPopup {...replicaStation} />));
    return marker;
  } else {
    return undefined;
  }
};

const mapOptions = {
  center: [ -32.48431324745671, -71.25296320202341 ],
  zoomControl: true,
  dragging: true,
  zoom: 16,
  doubleClickZoom: false,
  attributionControl: false,
};

const useStyles = makeStyles(theme => ({
  map: {
    borderRadius: theme.shape.borderRadius,
  },
  mapContainer: {
    padding: theme.spacing(1),
    height: 'fit-content',
    position: 'relative',
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
  },
  content: {
    maxWidth: '100%',
  },
}));


const SmaMonitoringContainer = ({ campaign }) => {

  const mapRef = useRef();
  const classes = useStyles();
  const match = useRouteMatch();
  const campaignApi = useCampaignApi();

  const [ replicaStations, setReplicaStations ] = useState([]);

  const { projectId, campaignId } = match.params;

  const { name: campaignName } = campaign;

  useEffect(() => {
    const fetchData = async () => {
      const replicaStations = await campaignApi.getReplicaStations(campaignId);
      setReplicaStations(replicaStations);
    };
    fetchData();
  // eslint-disable-next-line
  }, [ campaignId ]);

  useEffect(() => {
    if (replicaStations.length > 0) {
      const latLngs = replicaStations.map(rs => {
        const coords = getLatLng(rs);
        return coords ? [ coords.lat, coords.lng ] : undefined ;
      }).filter(rs => rs);
      const bounds = new L.LatLngBounds(latLngs);
      mapRef.current.fitBounds(bounds);
    }
  }, [ replicaStations ]);

  const layers = replicaStations.map(rs => createMarker(rs)).filter(rs => rs);

  const height75vh = document.documentElement.clientHeight * 0.75;

  return (
    <Grid container>
      <TitleContainer
        maxWidth="xl"
        breadcrumbs={[
          ...getBaseBreadcrumbs({ projectId }),
          { name: `Mapa de ${campaignName}` },
        ]}
      >
        Mapa
      </TitleContainer>
      <ContentContainer className={classes.content}>
        <Paper className={classes.mapContainer}>
          <MapWrapper
            id="replica-stations-map"
            className={classes.map}
            options={mapOptions}
            height={height75vh}
            layers={[ ...layers ]}
            mapRef={mapRef}
          />
        </Paper>
      </ContentContainer>
    </Grid>
  );
};

SmaMonitoringContainer.propTypes = {
  campaign: PropTypes.object,
};


export {
  SmaMonitoringContainer,
};