import { useAuth0 } from '@auth0/auth0-react';
import { request } from 'src/services/request';


const useApi = ({ isPrivate = true } = {}) => {
  const { getAccessTokenSilently } = useAuth0();
  return {
    get: async (url, data, options) =>
      request({ url, method: 'GET', data, options: isPrivate ? { ...options, accessToken: await getAccessTokenSilently() } : options }),
    post: async (url, data, options) =>
      request({ url, method: 'POST', data, options: isPrivate ? { ...options, accessToken: await getAccessTokenSilently() } : options }),
    patch: async (url, data, options) =>
      request({ url, method: 'PATCH', data, options: isPrivate ? { ...options, accessToken: await getAccessTokenSilently() } : options }),
    del: async (url, data, options) =>
      request({ url, method: 'DELETE', data, options: isPrivate ? { ...options, accessToken: await getAccessTokenSilently() } : options }),
  };
};


export {
  useApi,
};
