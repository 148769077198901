import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { Triangle, TriangleDown } from 'src/utils/mdIcons';


const useStyles = makeStyles(theme => ({
  title: {
    fontSize: ({ size }) => size === 'small' ? theme.typography.subtitle2.fontSize : theme.typography.subtitle1.fontSize,
    color: ({ variant }) => (
      variant === 'light' ? theme.palette.common.gray600
      : variant === 'dark' ? theme.palette.common.lightGray
      : theme.palette.common.gray600
    ),
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  numberSkeleton: {
    minWidth: theme.spacing(2),
    maxWidth: theme.spacing(10),
    width: '-webkit-fill-available',
  },
  number: {
    fontSize: ({ size }) => size === 'small' ? theme.typography.h6.fontSize : theme.typography.h5.fontSize,
    fontWeight: 600,
    color: theme.palette.common.black,
  },
  totalNumber: {
    color: theme.palette.common.gray600,
    marginLeft: theme.spacing(0.25),
  },
  goToButton: {
    textWrap: 'nowrap',
  },
  percent: {
    fontSize: ({ size }) => size === 'small' ? theme.typography.subtitle2.fontSize : theme.typography.subtitle1.fontSize,
    color: ({ trend }) => (
      trend === 'up' ? theme.palette.success.main
      : trend === 'down' ? theme.palette.error.main
      : theme.palette.common.gray600
    ),
    fontWeight: 600,
  },
  trendIcon: {
    fontSize: ({ size }) => size === 'small' ? '0.5rem' : '1rem',
    fontWeight: 600,
    color: ({ trend }) => (
      trend === 'up' ? theme.palette.success.main
      : trend === 'down' ? theme.palette.error.main
      : theme.palette.common.gray600
    ),
  },
  trendText: {
    fontSize: ({ size }) => size === 'small' ? theme.typography.caption.fontSize : theme.typography.caption.fontSize,
    fontWeight: 500,
    marginLeft: theme.spacing(0.5),
  },
}));

const NumberIndicator = props => {
  const { title, value, isLoading, goTo, goToLabel, size = 'default', rate, total, hasRate = true } = props;
  const trend = (
    rate > 0 ? 'up'
    : rate === 0 ? 'none'
    : 'down'
  );

  const classes = useStyles({ size, trend });

  return (
    <Box display="flex" flexDirection="column">
      <Box mb={1}>
        <Typography className={classes.title} noWrap>{title}</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        {isLoading ?
          <Typography className={classes.numberSkeleton}>
            <Skeleton animation="wave" variant="text" />
          </Typography>
          :
          <>
            <Typography className={classes.number} display="inline" component="span">{value}</Typography>
            {total ? <Box display="inline" className={classes.totalNumber}>/</Box> : null}
            {total ? <Typography variant="body2" display="inline" className={classes.totalNumber}>{total}</Typography> : null}
          </>
        }
        <Box flexGrow={1} />
        {goTo &&
          <Box className={classes.goToButton} ml={1} mr={1}>
            <Button color="primary" variant="outlined" size="small" onClick={goTo}>{goToLabel}</Button>
          </Box>
        }
      </Box>
      {hasRate && (
        isLoading ?
          <Box width="80%" mt={1}>
            <Typography >
              <Skeleton animation="wave" variant="text" />
            </Typography>
          </Box>
          : rate === null ? false
          : (rate || rate === 0) &&
            <Box display="flex" alignItems="center" mt={1}>
              <Box display="flex" alignItems="center" mr={trend === 'none' ? 0 : 0.5}>
                {trend === 'up' && <Triangle className={classes.trendIcon} />}
                {trend === 'down' && <TriangleDown className={classes.trendIcon} />}
              </Box>
              <Typography className={classes.percent} component="span">
                {Math.abs(rate)}%
              </Typography>
              <Typography className={classes.trendText} noWrap>
                {trend === 'up' && <>sobre x̅ anual</>}
                {trend === 'down' && <>bajo x̅ anual</>}
                {trend === 'none' && <>c/r al x̅ anual</>}
              </Typography>
            </Box>
      )}
    </Box>
  );
};

NumberIndicator.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  total: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  rate: PropTypes.number,
  isLoading: PropTypes.bool,
  goTo: PropTypes.func,
  goToLabel: PropTypes.string,
  size: PropTypes.oneOf([ 'small', 'default' ]),
  hasRate: PropTypes.bool,
};


export { NumberIndicator };