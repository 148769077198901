import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { SmaGroup } from 'src/scenes/Project/scenes/Groups/components';


const SmaGroupList = ({ groups, actions }) =>
  <Grid container spacing={1}>
    {groups.map(group =>
      <Grid key={group.id} item xs={3} >
        <SmaGroup alias={group.alias} names={group.names} actions={{ openDeleteGroup: () => actions.openDeleteGroup(group) }} />
      </Grid>,
    )}
  </Grid>;

SmaGroupList.propTypes = {
  groups: PropTypes.array,
  actions: PropTypes.object,
};


export {
  SmaGroupList,
};