import { useApi } from 'src/hooks/useApi';
import { urlJoin } from 'src/utils/util';


const resourceName = 'monitoring-plan';
const smaResourceName = 'smaData';

const useMonitoringPlanApi = () => {
  const { get, post, del } = useApi();
  return {
    getMonitoringPlans: async params => (await get(resourceName, params)).data,
    createMonitoringPlan: async newMonitoringPlan => (await post(urlJoin(resourceName, 'create'), newMonitoringPlan)).data,
    deleteMonitoringPlan: async monitoringPlanId => (await del(urlJoin(resourceName, monitoringPlanId))),
    updateMonitoringPlan: async ({ monitoringPlanId, name }) => (await post(urlJoin(resourceName, monitoringPlanId, 'edit'), { name })),
    searchSpecies: async params => (await get(urlJoin(resourceName, 'species'), params)).data,
    getReplicaStationsByMonitoringPlan: async params => (await get(urlJoin(resourceName, 'station-replicate'), params)).data,
    getAnalyticsData: async ({ monitoringPlanId }) => (await get(urlJoin(resourceName, monitoringPlanId, 'charts-data'))).data,
    getOccurrencesByMonitoringInstance: async ({ monitoringPlanId, params, options }) =>
      (await get(urlJoin(resourceName, monitoringPlanId, 'occurrence-by-monitoring-instance'), params, options)).data,
    getOccurrencesByReplicaStation: async ({ monitoringPlanId, params, options }) =>
      (await get(urlJoin(resourceName, monitoringPlanId, 'occurrence-by-replica-station'), params, options)).data,
    getOccurrencesBySpecies: async ({ monitoringPlanId, params, options }) =>
      (await get(urlJoin(resourceName, monitoringPlanId, 'occurrence-by-species'), params, options)).data,
    getOccurrenceByFeature: async ({ monitoringPlanId, params, options }) =>
      (await get(urlJoin(resourceName, monitoringPlanId, 'occurrence-by-feature'), params, options)).data,
    getAnalyticsMaps: async ({ monitoringPlanId }) => (await get(urlJoin(resourceName, monitoringPlanId, 'area-of-interest'))).data,
    getTileData: async ({ monitoringPlanId }) => (await get(urlJoin(resourceName, monitoringPlanId, 'tile-data'))).data,
    getGroups: async monitoringPlanId => (await get(urlJoin(resourceName, monitoringPlanId, 'groups'))).data,
    getGeoInfo: async ({ monitoringPlanId }) => (await get(urlJoin(resourceName, monitoringPlanId, 'geo-info'))).data,
    processInterestArea: async({ monitoringPlanId }) => (await post(urlJoin(resourceName, monitoringPlanId, 'prepare-terrain-data'))).data,
    createGroup: async ({ monitoringPlanId, newGroup }) => (await post(urlJoin(resourceName, monitoringPlanId, 'groups'), newGroup)).data,
    getParametersByMonitoringPlan: async monitoringPlanId =>
      (await get(urlJoin(resourceName, '/parameter-value'), { monitoringPlanId })).data,
    saveAnalytic: async ({ monitoringPlanId, chartData }) =>
      (await post(urlJoin(resourceName, monitoringPlanId, 'save-chart'), chartData)).data,
    deleteAnalytic: async analyticId => (await del(urlJoin(resourceName, 'chart', analyticId))).data,

    importInterestArea: async ({ monitoringPlanId, interestArea }) =>
      (await post(urlJoin(resourceName, monitoringPlanId, 'area-of-interest'), interestArea, { isMultipart: true })).data,
    deleteGroup: async ({ monitoringPlanId, groupId }) => (await del(urlJoin(resourceName, monitoringPlanId, 'groups'), { groupId })).data,
    deleteInterestArea: async ({ monitoringPlanId, areaOfInterestKmlId }) =>
      (await del(urlJoin(resourceName, monitoringPlanId, 'area-of-interest'), { areaOfInterestKmlId })).data,
    autoAssignCampaigns: async params => (await post(urlJoin(resourceName, 'generate'), params)).data,

    updateMonitoringInstance: async ({ monitoringInstanceId, monitoringPlanId, position }) =>
      (await post(urlJoin(smaResourceName, 'monitoring-instance', monitoringInstanceId, 'edit'), { monitoringPlanId, position })),
    createMonitoringInstance: async ({ monitoringPlanId, position, campaignId }) =>
      (await post(urlJoin(smaResourceName, 'monitoring-instance/create'), { monitoringPlanId, position, campaignId })).data,
    deleteMonitoringInstance: async ({ monitoringPlanId, position, monitoringInstanceId }) =>
      (await post(urlJoin(smaResourceName, 'monitoring-instance', monitoringInstanceId, 'delete'), { monitoringPlanId, position })).data,
    addCampaignToInstance: async ({ monitoringInstanceId, campaignId }) =>
      (await post(urlJoin(smaResourceName, 'monitoring-instance', monitoringInstanceId, 'campaign'), { campaignId })).data,
    getChartTypes: async monitoringPlanId => (await get(urlJoin(smaResourceName, 'chart-types'), { monitoringPlanId })).data,
    getMonitoringPlanInstances: async params => (await get(urlJoin(smaResourceName, 'monitoring-instance'), params)).data,
  };
};

export { useMonitoringPlanApi };