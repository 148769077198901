import React from 'react';
import { Box, ClickAwayListener } from '@material-ui/core';
import PropTypes from 'prop-types';


const Editable = ({ Component, children, isEditing, closeEdition, ...props }) =>
  <Box {...props}>
    {isEditing ? (
      <ClickAwayListener onClickAway={closeEdition}>
        {children}
      </ClickAwayListener>
    ) : (<Component />)
    }
  </Box>;

Editable.propTypes = {
  Component: PropTypes.func,
  isEditing: PropTypes.bool,
  closeEdition: PropTypes.func,
  children: PropTypes.any,
};


export { Editable };