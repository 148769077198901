import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(7),
    paddingLeft: theme.spacing(50),
    paddingRight: theme.spacing(50),
    color: theme.palette.common.black,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  mainTitle: {
    fontSize: '4.375rem',
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
      lineHeight: '58px',
      textAlign: 'center',
    },
  },
  mainSubtitle: {
    fontSize: '1.25rem',
    color: theme.palette.common.black,
    textAlign: 'center',
    fontWeight: 500,
    paddingBottom: theme.spacing(3),
  },
  chapter: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  chapterTitle: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    textTransform: 'uppercase',
    paddingBottom: theme.spacing(2),
  },
  paragraph: {
    paddingBottom: theme.spacing(2),
    textAlign: 'justify',
  },
  subChapterNumber: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  subParagraph: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  subChapterTitle: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
}));

const TermsContainer = () => {
  const classes = useStyles();

  useEffect(() => {
    document.title = 'Términos y condiciones - Nviro Monitor';
  }, []);

  return (
    <Box className={classes.container}>
      <Grid container justifyContent="center" direction="column">
        <Grid item>
          <Typography className={classes.mainTitle} color="primary">Términos y condiciones</Typography>
          <Typography className={classes.mainSubtitle}>Actualizado marzo, 2022</Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.paragraph}>
            Bienvenido al sitio web de Nviro SpA (&quot;Nviro&quot;). Los términos y condiciones de servicio acá establecidos,
            rigen todo uso del sitio web Nviro y de sus aplicaciones, y todo el contenido, los servicios y los productos
            por estos medios disponibles (respectivamente, los “Términos y Condiciones” y el &quot;Sitio web&quot;).
          </Typography>
          <Typography className={classes.paragraph}>
            El Sitio web es propiedad de Nviro SpA. y se ofrece sujeto a su aceptación sin modificación de
            todos los Términos y Condiciones contenidos en este documento y todas las demás reglas y
            políticas operativas, incluyendo la Política de privacidad de Nviro en https://monitor.nviro.cl/privacy,
            y otros procedimientos que puedan ser publicados de vez en cuando en este Sitio por Nviro
            (conjuntamente, el &quot;Acuerdo&quot;).
          </Typography>
          <Typography className={classes.paragraph}>
            Lea y revise este Acuerdo detenidamente antes de acceder o utilizar el Sitio web. Al acceder o
            utilizar cualquier parte del Sitio web, cada usuario (en adelante el &quot;Usuario&quot;) acepta los
            términos de este Acuerdo. En caso de no estar de acuerdo con todos y cada uno de los términos
            de este Acuerdo, no debe acceder al Sitio web ni utilizar ningún servicio disponible a través
            del Sitio web.
          </Typography>
          <Typography className={classes.paragraph}>
            El Sitio web está disponible solo para personas que tengan al menos 18 años de edad, a
            menos que se haya obtenido el permiso de los padres, los que se entenderá se hacen
            responsables de las acciones u omisiones de sus hijos/as.
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            1.&nbsp;&nbsp;Su Cuenta
          </Typography>
          <Typography className={classes.paragraph}>
            Si el Usuario crea una cuenta en el Sitio web, es responsable de mantener la seguridad de los
            datos de ingreso a la misma y es totalmente responsable de todas las actividades que se
            realicen en la cuenta y de cualquier otra acción que se lleve a cabo en relación con la cuenta.
          </Typography>
          <Typography className={classes.paragraph}>
            El Usuario no debe publicar contenido en su cuenta de manera engañosa o ilegal, incluso de
            una manera destinada a comerciar con el nombre o la reputación de otros. Nviro puede, a su
            discreción, cambiar o eliminar cualquier contenido que considere inapropiado o ilegal. El
            Usuario debe notificar inmediatamente a Nviro sobre cualquier uso no autorizado de su
            cuenta o cualquier otra violación de seguridad. Nviro no será responsable de ningún acto u
            omisión por su parte, incluidos los daños de cualquier tipo incurridos como resultado de
            dichos actos u omisiones.
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            2.&nbsp;&nbsp;Responsabilidad de los usuarios
          </Typography>
          <Typography className={classes.paragraph}>
            El Usuario es totalmente responsable del contenido y de cualquier daño resultante de
            material que publique en el Sitio web, o que haga (o permita que un tercero haga) disponible
            a través del Sitio web (en adelante el &quot;Contenido&quot;). Ese es el caso independientemente de si
            el Contenido en cuestión constituye texto, gráficos, un archivo de audio o software de
            computadora.
          </Typography>
          <Typography className={classes.paragraph} component="div">
            <Box>
              <Box mr={3} display="inline" className={classes.subChapterNumber}>
                2.1.
              </Box>
              <Box display="inline">
                Al hacer que el Contenido esté disponible, el Usuario declara y se compromete
                contractualmente a que:
              </Box>
            </Box>
            <Box ml={{ xs: 2, md: 6 }} className={classes.subParagraph}>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  i.
                </Box>
                <Box display="inline">
                  Los detalles de su cuenta no conducen a error, confusión o engaño a otros
                  haciéndoles pensar que representan a otra persona u organización específica (se
                  permiten seudónimos únicos);
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  ii.
                </Box>
                <Box display="inline">
                  El Contenido no contiene ni instala virus, malware, u otro contenido dañino o destructivo;
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  iii.
                </Box>
                <Box display="inline">
                  El Contenido no es o incluye spam y no comprende elementos no éticos o no deseados orientados
                  a dirigir el tráfico a sitios de terceros o mejorar la clasificación de los motores de búsqueda
                  de sitios de terceros, o para otros actos ilegales (como phishing) o engañar a los destinatarios
                  en cuanto a la fuente del material (como suplantación de identidad);
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  iv.
                </Box>
                <Box display="inline">
                  Publicará solo Contenido que sea relevante a Nviro y a un ritmo y volumen que no
                  obstaculice la capacidad de otros Usuarios para usar Nviro;
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  v.
                </Box>
                <Box display="inline">
                  El Contenido no es calumnioso ni difamatorio, no contiene amenazas ni incita a la
                  violencia hacia personas o entidades, y no viola los derechos de privacidad o
                  publicidad de ningún tercero.
                </Box>
              </Box>
            </Box>
          </Typography>
          <Typography className={classes.paragraph}>
            El Usuario entiende y acepta que toda vez que envía Contenido a Nviro para su inclusión en el
            Sitio web, otorga a Nviro una licencia, libre de regalías y no exclusiva para procesar, sintetizar
            y gestionar la información en beneficio del Usuario. Nviro también podrá utilizar el Contenido
            para otros fines que no estén sujetos a restricciones legales en miras a lograr la mejora de la
            plataforma de Nviro y la capacitación de modelos de aprendizaje automático o “mejora de
            IA”. A este efecto se entenderá por “mejora” y por “mejora de AI” lo siguiente:
          </Typography>
          <Typography className={classes.paragraph}>
            “Mejora”. Nviro tiene el derecho de recopilar y analizar datos relacionados con la provisión,
            uso o desempeño del Sitio web, y para dicho efecto Nviro puede (a) usar dichos datos para
            mejorar el Sitio web o para otros propósitos de desarrollo, diagnóstico o correctivos en
            relación con el Sitio web o para desarrollar otras ofertas de Nviro; (b) divulgar dichos datos
            únicamente en forma agregada o anónima; y (c) utilizar cualquier mejora o hacer dicha
            divulgación sin limitación.
          </Typography>
          <Typography className={classes.paragraph}>
            “Mejoras de IA”. Nviro podrá mejorar el Sitio web mediante la aplicación de aprendizaje
            automático, ajuste o cualquier modificación, alteración o mejora del Sitio web (&quot;Mejoras de
            IA&quot;). El Usuario acepta que, en el curso normal del desarrollo del aprendizaje automático,
            como parte de dichas Mejoras de IA, Nviro puede extraer y retener patrones de datos
            asociados con una anotación que surja en relación con este Acuerdo que no identifique al
            Usuario y no permita la reconstrucción del Usuario. Cualquier Mejora de IA que surja del
            Acuerdo será propiedad única y exclusiva de Nviro y constituirá propiedad intelectual de
            Nviro.
          </Typography>
          <Typography className={classes.paragraph}>
            Por su parte, el Usuario declara que (a) posee y controla todos los derechos sobre el
            Contenido que publica o tiene derecho a publicar dicho Contenido en el Sitio; (b) el Contenido
            es preciso y no engañoso; y (c) el uso y la publicación del Contenido que usted suministra no
            viola estos Términos y Condiciones de servicio y no violará ningún derecho ni causará daños a
            ninguna persona o entidad. Si elimina el Contenido, Nviro hará todos los esfuerzos razonables
            para eliminarlo del Sitio web, pero el Usuario reconoce que el almacenamiento en caché o las
            referencias al Contenido no pueden dejar de estar disponibles de inmediato.
          </Typography>
          <Typography className={classes.paragraph} component="div">
            <Box>
              <Box mr={3} display="inline" className={classes.subChapterNumber}>
                2.2.
              </Box>
              <Box display="inline">
                Asimismo, el Usuario se compromete contractualmente a no:
              </Box>
            </Box>
            <Box ml={{ xs: 2, md: 6 }} className={classes.subParagraph}>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  i.
                </Box>
                <Box display="inline">
                  Acceder o utilizar al Sitio web a través de cualquier tecnología o medios distintos de los usualmente aceptados;
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  ii.
                </Box>
                <Box display="inline">
                  Copiar, traducir, modificar, crear un trabajo derivado de, o mostrar públicamente
                  cualquier contenido o cualquier parte del mismo que no sea el Contenido
                  incorporado al Sitio web por el Usuario -salvo que otro(s) Usuario(s)
                  le hayan autorizado al efecto-;
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  iii.
                </Box>
                <Box display="inline">
                  Acceder a recursos del Sitio web que no están disponibles al Usuario a través de un
                  navegador web estándar;
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  iv.
                </Box>
                <Box display="inline">
                  Realizar ingeniería inversa, descompilar o intentar extraer el código fuente del Sitio
                  web o cualquier parte del mismo;
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  v.
                </Box>
                <Box display="inline">
                  Usar el Sitio web de una manera que le permita al Usuario o a cualquier otra persona
                  acceso a descargas masivas o feeds masivos de cualquier contenido, incluidos, entre
                  otros, coordenadas numéricas de latitud o longitud, imágenes y datos de mapas
                  visibles;
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  vi.
                </Box>
                <Box display="inline">
                  Interferir o interrumpir los servicios del Sitio web, los servidores o las redes
                  conectadas a los servicios del Sitio web, o desobedecer cualquier requisito,
                  procedimiento, política o reglamentación de las redes conectadas a los servicios del
                  Sitio web;
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  vii.
                </Box>
                <Box display="inline">
                  Utilizar cualquier robot, araña, aplicación de búsqueda / recuperación de sitios u
                  otro dispositivo para recuperar o indexar cualquier parte de los servicios del Sitio
                  web o el Contenido o recopilar información sobre los Usuarios para cualquier
                  propósito no autorizado;
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  viii.
                </Box>
                <Box display="inline">
                  Mostrar contenido que exprese o implique falsamente que dicho contenido está
                  patrocinado o respaldado por Nviro;
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  ix.
                </Box>
                <Box display="inline">
                  Crear cuentas de usuario por medios automatizados o con pretensiones falsas o
                  fraudulentas, o con las mismas pretensiones obtener o intentar obtener varias
                  claves o cuentas para la misma URL;
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  x.
                </Box>
                <Box display="inline">
                  Utilizar el Sitio web para fines ilegales, inapropiados o inapropiados (que incluyen,
                  entre otros, la difamación o el acoso a otros; distribución de material obsceno o
                  indecente; acecho; o distribución de contenido en violación de los derechos de
                  propiedad de otros).
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  xi.
                </Box>
                <Box display="inline">
                  Utilizar el Sitio web de tal manera que afecte negativamente a los intereses
                  comerciales y/o la reputación de Nviro, o llevar a cabo acciones que sean contrarias
                  a las buenas prácticas en la gestión de información y comunicaciones de internet,
                  afectando los intereses de Nviro o de terceros relevantes.
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  xii.
                </Box>
                <Box display="inline">
                  Ocultar, alterar o enmascarar la identidad de Nviro mientras usa el servicio del Sitio
                  web;
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  xiii.
                </Box>
                <Box display="inline">
                  Violar estos Términos y Condiciones de servicio y otras políticas que Nviro pueda
                  desarrollar de vez en cuando.
                </Box>
              </Box>
            </Box>
          </Typography>
          <Typography className={classes.paragraph}>
            Sin limitar ninguna de esas representaciones o garantías, Nviro tiene el derecho (aunque no la
            obligación), por causas justificadas, de cancelar o denegar el acceso y el uso del Sitio web
            a cualquier individuo o entidad. Para los efectos de este Acuerdo, se entenderá que existe una
            causa justificada cuando ocurra un incumplimiento de estos Términos y Condiciones por parte
            del Usuario.
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            3.&nbsp;&nbsp;Responsabilidad de Nviro
          </Typography>
          <Typography className={classes.paragraph}>
            Nviro no ha revisado, y no puede revisar, todo el material, incluido el software de
            computadora, publicado en el Sitio Web y, por lo tanto, no puede ser responsable del
            contenido, uso o efectos de ese material. Al operar el sitio web, Nviro no representa ni
            implica que respalda el material publicado en el Sitio Web, o que cree que dicho material es
            preciso, útil, no infractor o no dañino.
          </Typography>
          <Typography className={classes.paragraph}>
            El Usuario es responsable de tomar las precauciones necesarias para protegerse y proteger
            sus sistemas informáticos de virus, gusanos, caballos de Troya y otros contenidos dañinos
            o destructivos. El Sitio web puede contener contenido que sea ofensivo, indecente o de otra
            manera objetable, así como contenido que contenga inexactitudes técnicas, errores
            tipográficos y otros errores.
          </Typography>
          <Typography className={classes.paragraph}>
            El Sitio web también puede contener material que viole los derechos de privacidad o
            publicidad, o infrinja la propiedad intelectual y otros derechos de propiedad de terceros, o
            cuya descarga, copia o uso está sujeto a términos y condiciones adicionales, declarados o no.
          </Typography>
          <Typography className={classes.paragraph}>
            Nviro se exime de cualquier responsabilidad por cualquier daño que resulte del uso por parte
            de los visitantes del Sitio web, cualquier etiquetado incorrecto de las observaciones por parte
            de los visitantes del Sitio web o de cualquier descarga por parte de esos visitantes del
            contenido publicado en el Sitio web.
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            4.&nbsp;&nbsp;Uso profesional
          </Typography>
          <Typography className={classes.paragraph}>
            El Usuario acepta no utilizar el Sitio web, o cualquier material de Nviro con fines comerciales
            no profesionales o para cualquier propósito o actividad ilegal, ilícita, inapropiada o no
            autorizada. Además, acepta no amenazar, abusar, solicitar, enviar spam, acosar, acechar,
            suplantar o intimidar a otros usuarios de Nviro y no recopilar ni almacenar ninguna ubicación
            u otra información personal sobre los usuarios de Nviro que obtenga del Sitio web, excepto
            para personas que no sean fines de investigación comercial, conservación y gestión de
            especies.
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            5.&nbsp;&nbsp;Infracción de derechos de autor
          </Typography>
          <Typography className={classes.paragraph}>
            Nviro respeta los derechos de propiedad intelectual de otros y solicita que los Usuarios del
            Sitio web hagan lo mismo.
          </Typography>
          <Typography className={classes.paragraph}>
            Si el Usuario entiende que su trabajo ha sido copiado en el Sitio web de una manera que
            constituye una infracción de derechos de autor, proporcione a Nviro la información a través
            del correo contacto@nviro.cl. En base a esta notificación Nviro se lo comunicará a la persona
            responsable de haber subido y publicado el referido contenido en el Sitio web o en otros Sitios,
            proporcionándole todos los datos relativos al reclamo por el posible uso no autorizado.
            Cuando la ley lo ordene o existan antecedentes plausibles de una posible infracción a derechos
            de autor de terceros, Nviro procederá a deshabilitar el contenido en cuestión, de acuerdo al
            procedimiento que se indica a continuación:
          </Typography>
          <Typography className={classes.paragraph} component="div">
            <Box>
              <Box mr={3} display="inline" className={classes.subChapterNumber}>
                5.1.
              </Box>
              <Box display="inline" className={classes.subChapterTitle}>
                Notificación judicial
              </Box>
            </Box>
          </Typography>
          <Typography className={classes.paragraph}>
            Si el Usuario detecta alguna infracción a sus derechos de autor en el Sitio Web, deberá seguir
            el mecanismo preceptuado en el artículo 85 Q de la Ley 17.336 de Propiedad Intelectual.
          </Typography>
          <Typography className={classes.paragraph} component="div">
            <Box>
              <Box mr={3} display="inline" className={classes.subChapterNumber}>
                5.2.
              </Box>
              <Box display="inline" className={classes.subChapterTitle}>
                Notificación Extrajudicial por Antecedentes Plausibles
              </Box>
            </Box>
          </Typography>
          <Typography className={classes.paragraph}>
            En caso que existan antecedentes plausibles de una infracción a sus derechos de autor o los
            de su representado, Nviro procederá a deshabilitar el contenido en cuestión, notificando para
            estos efectos a la persona responsable de haber subido y publicado el referido contenido,
            proporcionándole todos los datos relativos al reclamo por el posible uso no autorizado.
          </Typography>
          <Typography className={classes.paragraph} component="div">
            <Box ml={{ xs: 2, md: 6 }}>
              <Box mt={2}>
                <Box mr={3} mb={2}>
                  Existirán antecedentes plausibles cuando:
                </Box>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  i.
                </Box>
                <Box display="inline">
                  Los detalles de su cuenta no conducen a error, confusión o engaño a otros
                  haciéndoles pensar que representan a otra persona u organización específica (se
                  permiten seudónimos únicos).
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  ii.
                </Box>
                <Box display="inline">
                  El Contenido no contiene ni instala virus, malware, u otro contenido dañino o
                  destructivo,
                </Box>
              </Box>
            </Box>
          </Typography>
          <Typography className={classes.paragraph}>
            De no existir antecedentes plausibles, Nviro se limitará a comunicar el reclamo a la persona
            responsable de haber subido y publicado el contenido en el Sitio web. Para efectuar su
            reclamo, le solicitamos enviar un correo a contacto@nviro.cl.
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            6.&nbsp;&nbsp;Propiedad intelectual
          </Typography>
          <Typography className={classes.paragraph}>
            Todas las marcas comerciales, marcas de servicio, gráficos y logotipos utilizados en relación
            con Nviro o el Sitio web son marcas comerciales o marcas comerciales registradas de Nviro. El
            uso del Usuario del Sitio web no le otorga ningún derecho o licencia para reproducir o utilizar
            cualquier marca comercial de Nviro o de terceros. Este Acuerdo no le transfiere ninguna
            propiedad intelectual de Nviro o de terceros, y todos los derechos, títulos e intereses sobre
            dicha propiedad permanecerán únicamente con Nviro o dichos propietarios.
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            7.&nbsp;&nbsp;Modificaciones
          </Typography>
          <Typography className={classes.paragraph}>
            Nviro se reserva el derecho de razonablemente modificar o reemplazar cualquier parte de
            este Acuerdo, a su sola discreción (&quot;Términos Actualizados&quot;). Se comunicarán con 30 días
            de anticipación y por medio del mismo Sitio web, la entrada en vigencia de los Términos Actualizados.
            Su uso continuo o acceso al Sitio web después de la entrega en vigencia de los Términos Actualizados
            constituye la aceptación de dichos cambios. Nviro también puede, en el futuro, ofrecer nuevos servicios
            y / o características a través del Sitio web (incluido el lanzamiento de nuevas herramientas y recursos).
            Tales nuevas características y / o servicios estarán sujetos a los términos de este Acuerdo.
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            8.&nbsp;&nbsp;Terminación
          </Typography>
          <Typography className={classes.paragraph}>
            Nviro puede cancelar el acceso de un Usuario a todo o parte del Sitio web en cualquier
            momento, por causa justificada y previo aviso, con efecto inmediato.
          </Typography>
          <Typography className={classes.paragraph}>
            Si el Usuario desea rescindir este Acuerdo o su cuenta de Nviro, simplemente puede dejar de
            usar el Sitio web y eliminar su cuenta.
          </Typography>
          <Typography className={classes.paragraph}>
            En caso de terminación de este Acuerdo, el Contenido registrado en el Sitio web por el Usuario
            será eliminado por Nviro, y tal Contenido se pondrá a disposición del Usuario.
          </Typography>
          <Typography className={classes.paragraph}>
            Todas las disposiciones de este Acuerdo que por su naturaleza deberían sobrevivir a la
            terminación sobrevivirán a la terminación, incluidas, entre otras, las disposiciones de
            propiedad, las renuncias de garantía, la indemnización y las limitaciones de responsabilidad.
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            9.&nbsp;&nbsp;Renuncia de acciones
          </Typography>
          <Typography className={classes.paragraph}>
            El Sitio web se proporciona en su estado operacional actual. Los Usuarios renuncian a todas
            las acciones legales de cualquier tipo, expresas o implícitas, incluidas, entre otras, por falta de
            idoneidad para un propósito particular, por cualquier limitación en la funcionalidad del Sitio
            web, o por cualesquiera defectos o errores en la información disponible.
          </Typography>
          <Typography className={classes.paragraph}>
            Nviro no ofrece ninguna garantía de que el Sitio web esté libre de errores o que el acceso al
            mismo sea continuo o ininterrumpido.
          </Typography>
          <Typography className={classes.paragraph}>
            El Usuario comprende que descarga u obtiene contenido o servicios a través del Sitio web a su
            propia discreción y riesgo.
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            10.&nbsp;&nbsp;Limitación de responsabilidad
          </Typography>
          <Typography className={classes.paragraph}>
            En ningún caso Nviro será responsable con respecto a cualquier tema de este Acuerdo bajo
            cualquier criterio de responsabilidad contractual o extra-contractual , por:
          </Typography>
          <Typography className={classes.paragraph} component="div">
            <Box ml={{ xs: 2, md: 6 }} className={classes.subParagraph}>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  i.
                </Box>
                <Box display="inline">
                  cualquier daño indirecto;
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  ii.
                </Box>
                <Box display="inline">
                  daño moral;
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  iii.
                </Box>
                <Box display="inline">
                  todo lucro cesante;
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  iv.
                </Box>
                <Box display="inline">
                  el costo de adquisición de productos o servicios sustitutivos; o
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  v.
                </Box>
                <Box display="inline">
                  por interrupción del uso o pérdida o corrupción de datos. Nviro no tendrá ninguna
                  responsabilidad por cualquier falla o retraso debido a asuntos fuera de su control
                  razonable.
                </Box>
              </Box>
            </Box>
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            11.&nbsp;&nbsp;Representación general y garantía
          </Typography>
          <Typography className={classes.paragraph}>
            El Usuario declara y se compromete a que:
          </Typography>
          <Typography className={classes.paragraph} component="div">
            <Box ml={{ xs: 2, md: 6 }} className={classes.subParagraph}>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  i.
                </Box>
                <Box display="inline">
                  Su uso del Sitio web se realizará en estricta conformidad con la Política de privacidad
                  de Nviro ubicada en https://www.Nviro.cl/privacidad, con este Acuerdo y con todas
                  las leyes y regulaciones aplicables, y;
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  ii.
                </Box>
                <Box display="inline">
                  Su uso del sitio web no infringirá ni se apropiará indebidamente de los derechos de
                  propiedad intelectual de ningún tercero.
                </Box>
              </Box>
            </Box>
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            12.&nbsp;&nbsp;Indemnización
          </Typography>
          <Typography className={classes.paragraph}>
            El Usuario acepta indemnizar y eximir de responsabilidad a Nviro, sus contratistas, otorgantes
            de licencias y sus respectivos directores, funcionarios, empleados y agentes de y contra todos
            y cada uno de los reclamos y gastos, incluidos los honorarios de abogados, que surjan de su
            violación de este Acuerdo.
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            13.&nbsp;&nbsp;Acuerdo Completo, Legislación Aplicable, Jurisdicción y Varios
          </Typography>
          <Typography className={classes.paragraph}>
            Este Acuerdo constituye el acuerdo completo entre Nviro y el Usuario con respecto al uso del
            Sitio web y a todos los temas tratados en estos Términos y Condiciones de servicio, y solo
            puede ser modificado mediante una modificación expresa, escrita y firmada por un ejecutivo
            autorizado de Nviro, o mediante la publicación por Nviro de una versión revisada de estos
            Términos y Condiciones de servicio.
          </Typography>
          <Typography className={classes.paragraph}>
            El presente Acuerdo y estos Términos y Condiciones de servicio se regirán por el sistema legal
            de la República de Chile. Sin consideración a las normas sobre conflicto de leyes, los
            tribunales competentes para resolver cualquier diferencia relacionada con este Acuerdo y
            estos Términos y Condiciones de servicio serán los tribunales ordinarios de Santiago de Chile.
          </Typography>
          <Typography className={classes.paragraph}>
            La parte que prevalezca en cualquier acción o procedimiento para hacer cumplir este Acuerdo
            tendrá derecho a recibir compensación de los costos y honorarios de los abogados.
          </Typography>
          <Typography className={classes.paragraph}>
            Si alguna parte de este Acuerdo se considera inválida o inaplicable, esa parte se interpretará
            para reflejar la intención original de las partes, y las partes restantes permanecerán en pleno
            vigor y efecto. Una renuncia por cualquiera de las partes de cualquier término o condición de
            este Acuerdo o cualquier incumplimiento del mismo, en cualquier caso, no renunciará a dicho
            término o condición ni a cualquier incumplimiento posterior del mismo.
          </Typography>
          <Typography className={classes.paragraph}>
            El Usuario no puede ceder sus derechos en virtud de este Acuerdo. Nviro puede ceder sus
            derechos bajo este Acuerdo sin condición. Este Acuerdo será vinculante y redundará en
            beneficio de las partes, sus sucesores y cesionarios autorizados.
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Box mt={4}>
            <Typography className={classes.paragraph}>
              © Derecho de Autor de Nviro. Todos los derechos reservados.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};


export { TermsContainer };