import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Cards } from 'src/components';
import { ArrowForward } from '@material-ui/icons';


const LatestProjectList = ({ projects, actions }) => {

  const cardsRef = useRef();

  useEffect(() => {
    const cardPagination = cardsRef.current.getPagination();
    cardPagination.jump(0);
  }, [ projects ]);

  const cardActions = itemData => ([
    {
      Icon: ArrowForward,
      tooltip: 'Ver proyecto',
      onClick: () => actions.goToProject(itemData),
    },
  ]);

  return (
    <Cards ref={cardsRef} values={projects} variant="condensed" showPagination actions={cardActions} />
  );
};

LatestProjectList.propTypes = {
  projects: PropTypes.array,
  actions: PropTypes.object,
};


export { LatestProjectList };