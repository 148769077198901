import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography, FormHelperText } from '@material-ui/core';

import { AlertWrapper, AutocompleteWrapper, DialogButton, SelectAsync } from 'src/components';
import { useFetchStatus } from 'src/hooks';
import { upperCaseFirstLetter as ucfl } from 'src/utils/formatters';


const emptyOption = { label: '', value: '' };

const AuditableUnitLinkDialog = ({ actions }) => {

  const [ selectedHolder, setSelectedHolder ] = useState({});
  const [ auditableUnitsOptions, setAuditableUnitsOptions ] = useState([]);
  const [ selectedAuditableUnit, setSelectedAuditableUnit ] = useState(emptyOption);

  const { fetchStatus, setFetchStatus, errorMessage, fetchStatusEnum, handleConfirm, hasError, hasBadData } = useFetchStatus({
    confirmFn: () => actions.linkAuditableUnit({
      auditableUnitId: selectedAuditableUnit.value,
      titleHolder: selectedHolder.value,
      auditableUnitName: selectedAuditableUnit.label,
    }),
    closeFn: actions.closeDialog,
    validationFn: () => hasAuditableUnit && hasHolder,
  });

  const { NOT_STARTED } = fetchStatusEnum;

  useEffect(() => {
    if (selectedHolder?.value) {
      const fetchData = async () => {
        const auditableUnitsOptions = await actions.getAuditableUnits(selectedHolder.value);
        setSelectedAuditableUnit(emptyOption);
        setAuditableUnitsOptions(auditableUnitsOptions);
      };
      fetchData();
    }
  // eslint-disable-next-line
  }, [ selectedHolder?.value ]);

  const hasHolder = Boolean(selectedHolder.value);
  const hasAuditableUnit = Boolean(selectedAuditableUnit.value);

  const handleHolderChange = ({ label, value }) => {
    setSelectedHolder({ label, value });
    setFetchStatus(NOT_STARTED);
  };

  const handleAuditableUnitChange = (_, selectedAuditableUnit) => {
    setSelectedAuditableUnit(selectedAuditableUnit);
    setFetchStatus(NOT_STARTED);
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">
        <span>Vincular SMA Darwincore desde unidad fiscalizable</span>
      </DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Box my={2} >
            <Typography variant="body2" required>Titular</Typography>
            <SelectAsync
              id="select-holder"
              initialOptions={false}
              getOptions={actions.searchHolders}
              value={selectedHolder}
              onChange={handleHolderChange}
              placeholder={ucfl(selectedHolder?.label || 'Seleccionar un titular')}
            />
            {!hasHolder && hasBadData && <FormHelperText error>Debes seleccionar un titular</FormHelperText>}
          </Box>

          <Box my={2} >
            <Typography variant="body2" required>Unidad fiscalizable</Typography>
            <AutocompleteWrapper
              value={selectedAuditableUnit}
              options={auditableUnitsOptions}
              onChange={handleAuditableUnitChange}
              getOptionSelected={(option, value) => (option.value === value.value || value.value === '')}
              disabled={!hasHolder}
            />
            {!hasHolder && <FormHelperText>Debes seleccionar un titular</FormHelperText>}
            {!hasAuditableUnit && hasBadData && <FormHelperText error>Debes seleccionar una unidad fiscalizable</FormHelperText>}

          </Box>
          {hasError && <AlertWrapper severity="error">{errorMessage}</AlertWrapper>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Vincular</DialogButton>
      </DialogActions>
    </>
  );
};

AuditableUnitLinkDialog.propTypes = {
  actions: PropTypes.object,
};


export {
  AuditableUnitLinkDialog,
};
