import React, { useEffect, useCallback } from 'react';
import { Grid, Box, Typography, Link, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';

import { AnalyticsPageUI } from 'src/scenes/Home/components';
import { NviroButton, MaintenanceAlert } from 'src/components';
import { getMaintenanceStatus } from 'src/utils/util';
import { useServerMaintenanceOn } from 'src/hooks';
import config from 'src/config/local';


const captureUrl = config.captureUrl;

const useStyles = makeStyles(theme => ({
  mainContainer: {
    overflow: 'hidden',
  },
  introTextContainer: {
    marginTop: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  introSection: {
    fontSize: '4.375rem',
    fontWeight: 'bold',
    lineHeight: '90px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3.125rem',
      lineHeight: '58px',
    },
  },
  titleSection: {
    textAlign: 'center',
    marginBottom: theme.spacing(8),
    fontSize: '4.375rem',
    fontWeight: 'bold',
    lineHeight: '90px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3.125rem',
      lineHeight: '58px',
      marginBottom: theme.spacing(4),
    },
  },
  introSectionOne: {
    color: theme.palette.common.white,
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
  },
  introSectionTwo: {
    color: theme.palette.common.black,
    backgroundColor: '#f5f8fb',
    display: 'inline-block',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '6px',
    width: 'fit-content',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    marginLeft: theme.spacing(1),
  },
  subtitle: {
    color: theme.palette.common.white,
    fontSize: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.875rem',
      lineHeight: '34px',
      marginTop: theme.spacing(2),
    },
  },
  textSection: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    paddingRight: theme.spacing(21),
    paddingLeft: theme.spacing(21),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      textAlign: 'center',
    },
  },
  contentText: {
    fontSize: '2.2em',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
    fontWeight: 500,
  },
  whiteBgSection: {
    backgroundColor: theme.palette.common.lightGray,
    color: theme.palette.common.black,
  },
  magBgSection: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  blueBgSection: {
    backgroundColor: theme.palette.capture.primary.main,
    color: theme.palette.common.black,
  },
  darkBgSection: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  youCanContainer: {
    textAlign: 'center',
  },
  highlightedTextForDarkBg: {
    color: theme.palette.common.black,
    backgroundColor: '#f5f8fb',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '6px',
    width: 'fit-content',
  },
  whiteText: {
    color: theme.palette.common.white,
  },
  coverButtons: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignContent: 'center',
    },
  },
  button: {
    minWidth: theme.spacing(17),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(36),
    },
  },
  planAreaImgBox: {
    width: theme.spacing(130),
    position: 'relative',
    zIndex: 2,
    marginTop: -1 * theme.spacing(10),
    marginLeft: theme.spacing(18),
    fontSize: '0.3em',
    color: 'rgba(0, 0, 0, 0)',
  },
  mobileComponent: {
    position: 'relative',
    zIndex: 3,
    marginTop: -1 * theme.spacing(65),
    marginLeft: theme.spacing(12),
  },
  coverSection: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(18),
    paddingBottom: theme.spacing(20),
    paddingRight: theme.spacing(21),
    paddingLeft: theme.spacing(21),

    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(10),
      paddingRight: theme.spacing(5),
      paddingLeft: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
  captureDescContainer: {
    maxWidth: '70em',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(5),
      paddingLeft: theme.spacing(5),
      paddingBottom: theme.spacing(2),
      alignItems: 'center',
    },
  },
  subsectionTitle: {
    fontWeight: 'bold',
    fontSize: '2.4em',
    color: theme.palette.common.black,
    backgroundColor: '#f5f8fb',
    paddingLeft: '12px',
    paddingRight: '12px',
    borderRadius: '6px',
    paddingBottom: '2px',
    width: 'fit-content',
  },
  subsectionContentText: {
    fontSize: '2em',
    marginBottom: theme.spacing(6),
    fontWeight: 500,
  },
  plusSign: {
    verticalAlign: '4px',
  },
  alert: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 16%)',
    width: '98%',
  },
  icons: {
    fontSize: '20px',
  },
  captureLinkContainer: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  captureLink: {
    fontSize: '2em',
    fontWeight: 'bold',
    color: theme.palette.common.white,
    textTransform: 'uppercase',
  },
  captureLogo: {
    width: '20em',
    display: 'block',
    margin: 'auto',
    marginTop: theme.spacing(2),
  },
}));

const CoverPageContainer = () => {
  const classes = useStyles();
  const isServerMaintenanceOn = useServerMaintenanceOn();
  const history = useHistory();

  const goToDemo = useCallback(() => history.push(`/schedule-demo`), [ history ]);

  useEffect(() => {
    document.title = 'Nviro Monitor';
  }, []);

  const { isMaintenanceScheduledOrOn } = getMaintenanceStatus();

  return (
    <Box className={classes.mainContainer}>
      {(isMaintenanceScheduledOrOn || isServerMaintenanceOn) && <MaintenanceAlert />}
      <Grid container className={classes.coverSection}>
        <Grid className={classes.introTextContainer} item xs={12} md={5}>
          <Box className={classes.introSection}>
            <Typography className={classes.introSectionOne}>
              Plataforma de monitoreo y análisis de
              <Typography component={'span'} className={classes.introSectionTwo}>biodiversidad</Typography>
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography className={classes.subtitle} variant="h2" color="textSecondary">
              Monitorea todos tus proyectos desde un solo lugar
            </Typography>
          </Box>
          <Grid container className={classes.coverButtons}>
            <Grid item xs={12} md={6}>
              <Box mr={{ xs: 0, md: 2 }} mb={{ xs: 1, md: 0 }}>
                <NviroButton className={classes.button} color="black" onClick={goToDemo}>
                  Comenzar
                </NviroButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid item md={7}>
            <Box className={classes.planAreaImgBox}>
              <AnalyticsPageUI />
            </Box>
          </Grid>
        </Hidden>
      </Grid>
      <Grid container className={classes.darkBgSection}>
        <Grid container alignItems="center" direction="column" className={classes.textSection}>
          <Typography className={classes.titleSection}>Con Nviro Monitor puedes...</Typography>
          <Box className={classes.youCanContainer}>
            <Typography className={classes.contentText}>Compilar datos de biodiversidad de tus proyectos históricos y actuales</Typography>
            <Typography className={classes.contentText}>Complementar esos datos con información satelital multiespectral</Typography>
            <Typography className={classes.contentText}>Analizar con la variedad de opciones de gráficos que ofrecemos</Typography>
            <Typography className={classes.contentText}>Monitorear la evolución de tus proyectos a través del tiempo</Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container className={classes.magBgSection}>
        <Grid container alignItems="center" direction="column" className={classes.textSection}>
          <Typography className={classes.titleSection}>
            <span className={classes.whiteText}>
              Conecta con <span className={classes.highlightedTextForDarkBg}>Nviro Capture</span> y mejora tus monitoreos
            </span>
          </Typography>
          <Box className={classes.captureDescContainer}>
            <Typography className={classes.subsectionTitle}>
              <span className={classes.plusSign}>+</span> Rápido
            </Typography>
            <Typography className={classes.subsectionContentText}>Sigue los eventos de tus campañas y proyectos apenas suceden</Typography>
            <Typography className={classes.subsectionTitle}>
              <span className={classes.plusSign}>+</span> Detallado
            </Typography>
            <Typography className={classes.subsectionContentText}>
              Fotos de los registros y espacios, tracks recorridos durante las campañas,
              características y medidas biológicas más allá del formato SMA Darwin Core
            </Typography>
            <Typography className={classes.subsectionTitle}>
              <span className={classes.plusSign}>+</span> Integridad
            de datos</Typography>
            <Typography className={classes.subsectionContentText}>
              Con nuestras validaciones de nombres de especie y tipos de dato, evita errores de digitación difíciles de atrapar y corregir
            </Typography>
          </Box>
          <Typography className={classes.captureLinkContainer}>
            <Link target="_blank" className={classes.captureLink} href={captureUrl}>Quiero conocer Nviro Capure</Link>
            <Link target="_blank" href={captureUrl}>
              <img className={classes.captureLogo} src="/img/logos/capture-color-white-logo.svg"/>
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};


export { CoverPageContainer } ;