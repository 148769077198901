import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import { useRouteMatch } from 'react-router';

import { useSmaApi, useProjectApi } from 'src/services';
import { TitleContainer, ContentContainer, DialogWrap, ActionsBox, AccessControl } from 'src/components';
import { /* DocumentList, */ AuditableUnitList } from 'src/scenes/Project/scenes/Import/components';
import { upperCaseOnlyFirstLetter, upperCaseFirstLetter as ucfl } from 'src/utils/formatters';
import { Factory } from 'src/utils/mdIcons';
import { /* SmaFileImportDialog, SmaFileDeleteDialog, */ AuditableUnitLinkDialog, AuditableUnitUnlinkDialog }
  from 'src/scenes/Project/scenes/Import/components';


const SmaImportContainer = props => {

  const match = useRouteMatch();

  const { /* companyId , */ projectId } = match.params;

  // const [ documents /*, setDocuments */ ] = useState([]);
  const [ linkedAuditableUnits, setLinkedAuditaleUnits ] = useState([]);
  const smaApi = useSmaApi();
  const projectApi = useProjectApi();


  // const importFile = async file => {
  //   const xlsForm = new FormData();
  //   xlsForm.append('companyId', companyId);
  //   xlsForm.append('xlsFile', file, file.name);
  //   const { docName, documentId, stationReplicates, occurrences, campaigns } = await smaApi.importSmaDarwincore(xlsForm);
  //   setDocuments([ {
  //     id: documentId,
  //     name: docName,
  //     source: 'uploaded',
  //     rcaIds: [],
  //     nCampaigns: campaigns,
  //     nOcurrences: occurrences,
  //     nReplicaStations: stationReplicates,
  //   }, ...documents ]);
  // };

  // const deleteDocument = async documentId => {
  //   await smaApi.deleteDocument({ companyId, documentId });
  //   setDocuments(documents.filter(doc => doc.id !== documentId));
  // };

  const linkAuditableUnit = async ({ auditableUnitId, titleHolder, auditableUnitName }) => {
    await projectApi.linkAuditableUnit({ projectId, auditableUnitId, titleHolderId: titleHolder });
    // await smaApi.linkAuditableUnit({ companyId, auditableUnitId, titleHolder });
    // TODO: Falta saber sus documents
    setLinkedAuditaleUnits([ { id: auditableUnitId, titleHolder, name: auditableUnitName, documents: [] }, ...linkedAuditableUnits ]);
  };

  const unlinkAuditableUnit = async ({ auditableUnitId, titleHolder }) => {
    await projectApi.unlinkAuditableUnit({ projectId, auditableUnitId, titleHolderId: titleHolder });
    setLinkedAuditaleUnits(linkedAuditableUnits.filter(lau => !(lau.titleHolder === titleHolder && lau.id === auditableUnitId)));
  };

  const searchHolders = async ({ queryText }) =>
    (await smaApi.searchHolders({ queryText })).map(r => ({ label: ucfl(r.name), value: r.name }));

  const getAuditableUnits = async titleHolder => {
    const auditableUnits = await smaApi.getAuditableUnits({ titleHolder });
    return auditableUnits.map(au => ({ label: au.name, value: au.id }));
  };

  const [ dialog, setDialog ] = useState({ isOpen: false, type: '' });
  const closeDialog = () => setDialog({ isOpen: false, type: '' });
  const openDialog = (type, data = {}) => setDialog({ isOpen: true, type, data });

  const openUnlinkAuditableUnitDialog = ({ auditableUnitName, auditableUnitId, titleHolder }) =>
    openDialog('unlink-auditable-unit', { auditableUnitName, auditableUnitId, titleHolder });

  // const openDeleteFileDialog = documentId => openDialog('remove-imported-file', { documentId });

  const getDialogType = () => {
    switch (dialog.type) {
      // case 'import-file':
      //   return <SmaFileImportDialog actions={{ closeDialog, importFile }} />;
      // case 'remove-imported-file':
      //   return <SmaFileDeleteDialog actions={{ closeDialog, deleteDocument: () => deleteDocument(dialog.data.documentId) }} />;
      case 'link-auditable-unit':
        return <AuditableUnitLinkDialog actions={{ closeDialog, searchHolders, getAuditableUnits, linkAuditableUnit }} />;
      case 'unlink-auditable-unit':
        return <AuditableUnitUnlinkDialog
          auditableUnitName={dialog.data.auditableUnitName}
          auditableUnitId={dialog.data.auditableUnitId}
          titleHolder={dialog.data.titleHolder}
          actions={{ closeDialog, unlinkAuditableUnit }}
        />;
      default:
        break;
    }
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     // if (isImportFilesView) {
  //     //   const documents = (await smaApi.getDocuments({ companyId, source: sources[tab].id }));
  //     //   setDocuments(documents);
  //     // }
  //   };
  //   fetchData();
  // // eslint-disable-next-line
  // }, [ ]);

  useEffect(() => {
    const fetchData = async () => {
      const linkedAuditableUnits = await projectApi.getLinkedAuditableUnits(projectId);
      setLinkedAuditaleUnits(linkedAuditableUnits);
    };
    fetchData();
    // eslint-disable-next-line
  }, [ ]);

  return (
    <Grid container>
      <TitleContainer maxWidth="xl" breadcrumbs={[ 'projects', { name: `Importar a ${upperCaseOnlyFirstLetter(props.name)}` } ]}>
        Fuentes de datos {`>`} SMA Darwincore
      </TitleContainer>
      <ContentContainer maxWidth="lg">
        <AccessControl action='project:data-source-add'>
          <ActionsBox>
            <Button size="small" color="primary" startIcon={<Factory />}
              variant="contained" onClick={() => openDialog('link-auditable-unit')}>
              Importar desde SMA Darwin Core
            </Button>
          </ActionsBox>
        </AccessControl>
        <AuditableUnitList linkedAuditableUnits={linkedAuditableUnits} actions={{ openUnlinkAuditableUnitDialog }} />
        {/* {isImportFilesView && <DocumentList documents={documents} actions={{ openDeleteFileDialog }} />} */}
      </ContentContainer>
      <DialogWrap maxWidth='sm' fullWidth onClose={closeDialog} aria-labelledby='form-dialog-title' open={dialog.isOpen}>
        {getDialogType()}
      </DialogWrap>
    </Grid>
  );
};

SmaImportContainer.propTypes = {
  name: PropTypes.string,
};


export {
  SmaImportContainer,
};