import { useApi } from 'src/hooks/useApi';
import { urlJoin } from 'src/utils/util';


const resourceName = 'geo-info';

const useGeoInfoApi = () => {
  const { get, patch } = useApi();
  return {
    getTileOptions: async () => (await get(urlJoin(resourceName, `/tile-options`))).data,
    saveMapConfig: async ({ geoDataId, mapConfig }) => (await patch(urlJoin(resourceName, geoDataId, 'map-config'),
      { mapConfig })).data,
  };
};


export { useGeoInfoApi };