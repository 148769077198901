import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Box, Drawer, Hidden, IconButton, Grid, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Menu } from '@material-ui/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { MenuTitle } from 'src/scenes/Home/scenes/Header/components';
import { NviroButton } from 'src/components';


const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 2,
    backgroundColor: theme.palette.common.white,
  },
  toolbar: {
    height: theme.spacing(19),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    [theme.breakpoints.down(1500)]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(14),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  logoContainer: {
    cursor: 'pointer',
    marginRight: theme.spacing(16),
    [theme.breakpoints.down(1500)]: {
      marginRight: theme.spacing(7),
    },
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(2),
    },
  },
  logo: {
    height: theme.spacing(11),
    [theme.breakpoints.down(1500)]: {
      height: theme.spacing(9),
    },
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(7),
    },
  },
  nav: {
    paddingBottom: 0,
  },
  altMenuButton: {
    maxHeight: 'fit-content',
  },
  button: {
    height: '3rem',
    fontWeight: 'bold',
    width: '8.625rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.813rem',
      height: '2.5rem',
      width: '7rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.813rem',
      height: '2.5rem',
      width: '10rem',
    },
  },
  loginButton: {
    backgroundColor: theme.palette.secondary.main,
  },
  registerButton: {
    backgroundColor: '#40474B',
  },
  menuAltItem: {
    width: '100%',
  },
  menuAltLink: {
    color: theme.palette.common.black,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontSize: '0.813rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
}));


const HomeHeaderContainer = () => {

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const [ openDrawer, setOpenDrawer ] = useState(false);

  const toggleDrawer = event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenDrawer(prevState => !prevState);
  };

  const goTo = page => () => history.push(`/${page}`);

  const handleLogin = async () => {
    await loginWithRedirect({ appState: { target: '/web/sma' } });
  };

  return (
    <AppBar position="sticky" elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Link to='/' className={classes.logoContainer}>
          <img className={classes.logo} src={theme.logo} alt="nviro-logo" />
        </Link>
        <Hidden smDown>
          <MenuTitle onClick={goTo('about-us')}>Quiénes somos</MenuTitle>
          {/* <MenuTitle onClick={goTo('contact')}>Contacto</MenuTitle> */}
          <MenuTitle onClick={goTo('schedule-demo')}>Solicitar demo</MenuTitle>
          <Box flexGrow={1}></Box>
          {
            isAuthenticated ?
              <Box>
                <NviroButton className={classes.button} color="black" onClick={goTo('web')}>
                  Ir a trabajar
                </NviroButton>
              </Box> :
              <>
                <Box mx={2}>
                  <NviroButton className={classes.button} onClick={handleLogin}>
                    Iniciar Sesión
                  </NviroButton>
                </Box>
                {/* <Box>
                  <NviroButton className={classes.button} color="black" onClick={goTo('register')}>
                    Registrarse
                  </NviroButton>
                </Box> */}
              </>
          }
        </Hidden>
        <Hidden mdUp>
          <Box flexGrow={1}></Box>
          <IconButton onClick={toggleDrawer} edge="start" color="primary" aria-label="menu">
            <Menu fontSize="large"/>
          </IconButton>
        </Hidden>
      </Toolbar>
      <Drawer open={openDrawer} anchor="top" onClose={toggleDrawer}>
        <div className={classes.list} role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>
          <Box display="flex" alignItems="center" p={3}>
            <img className={classes.logo} src={theme.logo} alt="nviro-logo" onClick={() => history.push('/')} />
            <Box flexGrow={1}></Box>
            <IconButton onClick={e => { toggleDrawer(e); e.stopPropagation(); }} edge="start"
              className={classes.altMenuButton} color="primary" aria-label="menu">
              <Menu fontSize="large"/>
            </IconButton>
          </Box>
          <Grid container direction="column">
            {/* <Grid item className={classes.menuAltItem}>
              <Typography className={classes.menuAltLink} onClick={goTo('how-it-work')}>¿Cómo funciona?</Typography>
            </Grid> */}
            <Divider />
            <Divider />
            <Grid item className={classes.menuAltItem}>
              <Typography className={classes.menuAltLink} onClick={goTo('about-us')}>Quiénes somos</Typography>
            </Grid>
            {/* <Grid item className={classes.menuAltItem}>
              <Divider />
              <Typography className={classes.menuAltLink} onClick={goTo('contact')}>Contacto</Typography>
            </Grid> */}
            <Grid item className={classes.menuAltItem}>
              <Divider />
              <Box my={2} display="flex" justifyContent="center">
                {
                  isAuthenticated ?
                    <NviroButton className={classes.button} color="black" onClick={goTo('web')}>
                      Ir a trabajar
                    </NviroButton> :
                    <>
                      {/* <NviroButton className={classes.button} color="black" onClick={goTo('register')}>
                        Registrarse
                      </NviroButton>
                      <Box mx={1} /> */}
                      <NviroButton className={classes.button} onClick={goTo('login')}>
                        Iniciar Sesión
                      </NviroButton>
                    </>
                }
              </Box>
            </Grid>
          </Grid>
        </div>
      </Drawer>
    </AppBar>
  );
};


export { HomeHeaderContainer };
