import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { Route } from 'react-router-dom';


const ProtectedRoute = ({ component, ...args }) =>
  <Route
    component={withAuthenticationRequired(component, {
      onRedirecting: () => (
        <Box p={5}>
          <Typography variant="body1">Sin autorización, redirigiendo...</Typography>
        </Box>
      ),
    })}
    {...args}
  />;

ProtectedRoute.propTypes = {
  component: PropTypes.func,
};


export { ProtectedRoute };
