import { urlJoin } from 'src/utils/util';
import { useApi } from 'src/hooks/useApi';


const resourceName = 'smaData';

const useSmaApi = () => {
  const { get, post, del } = useApi();
  return {
    // traspasados (se borraran pronto)
    linkAuditableUnit: async ({ companyId, auditableUnitId, titleHolder }) =>
      (await post(urlJoin(resourceName, 'companies', companyId, 'link-title-holder'), { auditableUnitId, titleHolder })).data,
    unlinkAuditableUnit: async ({ companyId, auditableUnitId, titleHolder }) =>
      (await del(urlJoin(resourceName, 'companies', companyId, 'unlink-title-holder'), { auditableUnitId, titleHolder })).data,
    getIssues: async companyId => (await get(urlJoin(resourceName, 'companies', companyId, 'warning-data'))).data,
    rcaSearch: async ({ companyId, ...params }) => (await get(urlJoin(resourceName, 'companies', companyId, 'search/rca'), params)).data,
    monitoringPlanSearch: async ({ companyId, ...params }) =>
      (await get(urlJoin(resourceName, 'companies', companyId, 'search/monitoring-plan'), params)).data,
    getUnassignedCampaigns: async companyId => (await get(urlJoin(resourceName, 'companies', companyId, 'unassigned-campaigns'))).data,
    updateMonitoringInstance: async ({ monitoringInstanceId, monitoringPlanId, position }) =>
      (await post(urlJoin(resourceName, 'monitoring-instance', monitoringInstanceId, 'edit'), { monitoringPlanId, position })),
    createMonitoringInstance: async ({ monitoringPlanId, position, campaignId }) =>
      (await post(urlJoin(resourceName, 'monitoring-instance/create'), { monitoringPlanId, position, campaignId })).data,
    deleteMonitoringInstance: async ({ monitoringPlanId, position, monitoringInstanceId }) =>
      (await post(urlJoin(resourceName, 'monitoring-instance', monitoringInstanceId, 'delete'), { monitoringPlanId, position })).data,
    getChartTypes: async monitoringPlanId => (await get(urlJoin(resourceName, 'chart-types'), { monitoringPlanId })).data,

    getMonitoringPlanInstances: async params => (await get(urlJoin(resourceName, 'monitoring-instance'), params)).data,
    getReplicaStations: async campaignId => (await get(urlJoin(resourceName, 'station-replicate'), { campaignId })).data,
    getOccurrences: async campaignId => (await get(urlJoin(resourceName, 'occurrences'), { campaignId })).data,
    getCampaign: async campaignId => (await get(urlJoin(resourceName, 'campaign', campaignId))).data,
    getCampaigns: async params => (await get(urlJoin(resourceName, 'campaign'), params)).data,

    getGeneralInfo: async ({ companyId, ...params }) =>
      (await get(urlJoin(resourceName, 'companies', companyId, 'info'), { ...params })).data,
    getSpeciesInfo: async ({ companyId, ...params }) =>
      (await get(urlJoin(resourceName, 'companies', companyId, 'species-info'), { ...params })).data,
    getSpeciesAllocationInfo: async ({ companyId, ...params }) =>
      (await get(urlJoin(resourceName, 'companies', companyId, 'species-allocation-info'), { ...params })).data,
    getEeccOccurrences: async ({ companyId, ...params }) =>
      (await get(urlJoin(resourceName, 'companies', companyId, 'eecc-occurrences'), { ...params })).data,

    // traspados (se mantendran en este mismo archivo)
    searchHolders: async params => (await get(urlJoin(resourceName, 'title-holder/search'), params)).data,
    getAuditableUnits: async ({ titleHolder }) => (await get(urlJoin(resourceName, 'auditable-units'), { titleHolder })).data,
    importSmaDarwincore: async xlsForm =>
      (await post(urlJoin(resourceName, 'import-darwincore'), xlsForm, { isMultipart: true })).data,
    getLinkedAuditableUnits: async companyId => (await get(urlJoin(resourceName, 'companies', companyId, 'title-holder-and-units'))).data,
    getDocuments: async ({ companyId, source }) => (await get(urlJoin(resourceName, 'companies', companyId, 'document'), { source })).data,
    deleteDocument: async ({ companyId, documentId }) =>
      (await del(urlJoin(resourceName, 'companies', companyId, 'document'), { documentId })).data,

    // no se usan (?)
    getAuditableUnit: async auditableUnitId =>
      (await get(urlJoin(resourceName, `auditable-units/${auditableUnitId}`))).data,
  };
};


export { useSmaApi };