import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Delete, Visibility, VisibilityOff } from '@material-ui/icons';


// eslint-disable-next-line react/prop-types
const GroupTitle = ({ title }) =>
  <Typography style={{ fontWeight: 800, lineHeight: 2 }} variant="overline" component="div">{title}</Typography>;

const useStyle = makeStyles(theme => ({
  title: {
    fontWeight: 600,
  },
  box: {
    backgroundColor: 'rgb(51, 136, 255)',
    borderRadius: '3px',
  },
  occurrencesBox: {
    backgroundColor: '#5BD6E6',
    borderRadius: '3px',
  },
  icon: {
    fontSize: '18px',
  },
  actionIcons: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.gray600,
    cursor: 'pointer',
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
    '&:hover': {
      color: theme.palette.common.gray800,
      backgroundColor: theme.palette.common.lightGray,
    },
  },
}));

const SmaAnalyticMapLegend = props => {
  const { layers = [], hasOccurrencesLayer, deleteInterestArea, hasSatInfo = false, hideTileLayer, displayOnMap, deletePermission } = props;
  const classes = useStyle();

  const handleTileVisibility = e => {
    e.stopPropagation();
    hideTileLayer();
  };

  return (
    <Box p={0.5} marginLeft={0.5}>
      {hasOccurrencesLayer &&
        <>
          <GroupTitle title="Ocurrencias" />
          <Box display="flex" alignItems="center">
            <Box height={12} width={12} mr={0.5} className={classes.occurrencesBox} />
            <Typography variant="body2">Ocurrencias</Typography>
          </Box>
        </>
      }
      <GroupTitle title="Área de interéss" />
      <Box display="flex" justifyContent="center" flexDirection="column">
        {layers.map(l =>
          <Box key={l.options.id} display="flex" alignItems="center">
            <Box height={12} width={12} mr={0.5} style={{
              backgroundColor: l.options.color ? l.options.color : 'rgb(51, 136, 255)',
              borderRadius: '3px',
            }} className ={ classes.box} />
            <Typography variant="body2">{l.options.name}</Typography>
            <Box ml={1} flexGrow={1} />
            {deletePermission &&
              <Box display="flex" width="100%" alignItems="center" className={classes.actionIcons} px={0.1}>
                <Delete className={classes.icon} onClick={() => deleteInterestArea(l.options.id)} />
              </Box>
            }
          </Box>,
        )}
      </Box>
      {hasSatInfo &&
        <>
          <Box display="flex" alignItems="center">
            <GroupTitle title="Información Satelital"/>
            <Box ml={1} flexGrow={1} />
            <Box display="flex" width="100%" alignItems="center" className={classes.actionIcons} px={0.1}>
              {!displayOnMap.tileData ?
                <Visibility className={classes.icon} onClick={handleTileVisibility}/> :
                <VisibilityOff className={classes.icon} onClick={handleTileVisibility}/>
              }
            </Box>
          </Box>
        </>
      }
    </Box>
  );
};

SmaAnalyticMapLegend.propTypes = {
  layers: PropTypes.array,
  hasOccurrencesLayer: PropTypes.bool,
  deleteInterestArea: PropTypes.func,
  hideTileLayer: PropTypes.func,
  displayOnMap: PropTypes.object,
  deletePermission: PropTypes.bool,
  hasSatInfo: PropTypes.bool,
};


export {
  SmaAnalyticMapLegend,
};

