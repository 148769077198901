import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, TextField, ClickAwayListener, Popover, FormGroup, Divider, Button,
  CircularProgress, Chip, Popper, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';
import clsx from 'clsx';

import { ChipFilterWrapper } from 'src/components/filter/ChipFilterWrapper';
import { upperCaseFirstLetter as ucfl } from 'src/utils/formatters';
import { truncateWithEllipsis as twe } from 'src/utils/util';


const useStyles = makeStyles(theme => ({
  textField: {
    height: theme.spacing(4),
    fontSize: theme.typography.body2.fontSize,
    width: ({ chipWidth = '100%' }) => chipWidth,
  },
  popoverTextField: {
    width: 250,
  },
  fixShrink: {
    transform: `translate(-16px, -6px) scale(0.75) !important`,
  },
  floraLabel: {
    backgroundColor: theme.palette.flora.primary.main,
    color: theme.palette.common.white,
  },
  faunaLabel: {
    backgroundColor: theme.palette.fauna.primary.main,
    color: theme.palette.common.white,
  },
  ssNameLabel: {
    backgroundColor: theme.palette.common.gray600,
    color: theme.palette.common.white,
  },
  sourceLabel: {
    height: theme.spacing(2),
    fontSize: '0.75rem',
  },
  menuOptionsPopper: {
    width: `250px !important`,
  },
}));

const FilterText = ({ id, label, prevValue: prevOption, onChange, getOptions, inline = false }) => {

  const chipComponentRef = useRef(null);

  const [ showInput, setShowInput ] = useState(false);
  const handleChipClick = () => setShowInput(true);
  const handleClickAway = () => setShowInput(false);

  const [ isMenuOptionsOpen, setMenuOptionsToOpen ] = useState(false);
  const handleOpenMenuOptions = () => setMenuOptionsToOpen(true);
  const handleCloseMenuOptions = () => setMenuOptionsToOpen(false);

  const [ inputValue, setInputValue ] = useState('');
  const [ options, setOptions ] = useState([]);
  const [ isFetching, setIsFetching ] = useState(false);
  const [ selectedOption, setSelectedOption ] = useState(prevOption);

  const [ chipWidth, setChipWidth ] = useState();

  const [ anchorEl, setAnchorEl ] = useState(null);
  const isOpenPopover = Boolean(anchorEl);
  const popoverId = isOpenPopover ? `${id}-popover` : undefined;

  const classes = useStyles({ chipWidth });
  const clientWidth = chipComponentRef.current?.clientWidth;

  useEffect(() => {
    if (clientWidth) {
      setChipWidth(clientWidth);
    }
  }, [ clientWidth ]);

  const openPopup = event => setAnchorEl(event.currentTarget);

  const closePopup = () => setAnchorEl(null);

  const hasSomeSelection = prevOption?.value && prevOption.value !== '';

  const handleInputChange = async (event, newInputValue) => {
    setIsFetching(true);
    setInputValue(newInputValue);
    const options = await getOptions({ queryText: newInputValue, id });
    setOptions(options);
    setIsFetching(false);
  };

  const handleOptionSelected = (event, optionSelected) => setSelectedOption(optionSelected);

  const handleConfirm = (e, optionSelected) => {
    if (inline) {
      if (optionSelected) {
        const { value, label } = optionSelected;
        setSelectedOption(optionSelected);
        onChange({ id, value, label });
      }
    } else {
      if (selectedOption?.value) {
        const { value, label } = selectedOption;
        onChange({ id, value, label });
        closePopup();
      }
    }
  };

  const handleDelete = () => {
    setSelectedOption({ label: '', value: undefined });
    onChange({ id, value: [], label: '' });
  };

  const autoCompleteBaseProps = {
    autoComplete: true,
    freeSolo: true,
    value: selectedOption,
    open: isMenuOptionsOpen,
    onOpen: handleOpenMenuOptions,
    onClose: handleCloseMenuOptions,
    inputValue: inputValue,
    onInputChange: handleInputChange,
    getOptionSelected: (option, value) => option.value === value.value,
    getOptionLabel: option => option.label || '',
    options,
    loading: isFetching,
    size: 'small',
    // eslint-disable-next-line react/display-name
    renderOption: option =>
      <Box display="flex" width="100%">
        <Typography variant="body1" noWrap title={option.label}>{option.label}</Typography>
        <Box flexGrow={1} mr={1}></Box>
        {option.componentId &&
          <Box display="flex" alignItems="center">
            <Chip size="small" className={clsx(classes.sourceLabel, {
              [classes.floraLabel]: option.componentId === 'flora',
              [classes.faunaLabel]: option.componentId !== 'flora',
            // TODO: evaluar si dejar solo "componentId" (que es fauna o flora) o poner source en alguna
            // parte? (por el momento dejo componentId porque source es muy largo y se come el nombre de la especie)
            })} label={ucfl(option.componentId)} />
          </Box>
        }
        {option.samplingStationName &&
          <Box display="flex" alignItems="center">
            <Chip
              title={`Estación de muestreo: ${option.samplingStationName}`}
              size="small"
              className={clsx(classes.sourceLabel, classes.ssNameLabel)}
              label={`EM: ${twe(ucfl(option.samplingStationName), 7)}`}
            />
          </Box>
        }
      </Box>,
  };

  return (
    <>
      {inline ?
        (showInput ?
          <ClickAwayListener onClickAway={handleClickAway}>
            <Autocomplete
              {...autoCompleteBaseProps}
              className={classes.textField}
              onChange={handleConfirm}
              renderInput={params => (
                <TextField {...params} autoFocus label={label} variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{isFetching && <CircularProgress color="inherit" size={20} />}</>,
                    className: classes.textField,
                  }}
                  InputLabelProps={{ className: classes.textField, classes: { shrink: classes.fixShrink }, ...(selectedOption ? {} : {}) }}
                  className={classes.textField}
                />
              )}
              PopperComponent={params => <Popper {...params} className={classes.menuOptionsPopper} placement="bottom-start" />}
            />
          </ClickAwayListener> :
          <ChipFilterWrapper
            isActive={hasSomeSelection}
            icon={<Search fontSize="small" />}
            ref={chipComponentRef}
            label={<Box>{hasSomeSelection ? selectedOption.label : label}</Box>}
            onClick={handleChipClick}
            {...(hasSomeSelection ? { onDelete: handleDelete } : {})}
          />
        ) :
        <>
          <Popover
            id={popoverId}
            open={isOpenPopover}
            anchorEl={anchorEl}
            onClose={closePopup}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <Box px={2} pt={3} pb={1}>
              <FormGroup>
                <Autocomplete
                  {...autoCompleteBaseProps}
                  onChange={handleOptionSelected}
                  renderInput={params => (
                    <TextField {...params} autoFocus variant="outlined" label={label} color="secondary"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{isFetching && <CircularProgress color="inherit" size={20} />}</>,
                        className: classes.popoverTextField,
                      }}
                      InputLabelProps={{ ...(selectedOption ? { shrink: true } : {}) }}
                    />
                  )}
                />
                <Box mt={4}>
                  <Divider />
                </Box>
                <Box mt={1} display="flex" alignSelf="flex-end">
                  <Button color="default" size="small" onClick={handleConfirm}>Aplicar</Button>
                </Box>
              </ FormGroup>
            </Box>
          </Popover>
          <ChipFilterWrapper
            isActive={hasSomeSelection}
            isFocused={isOpenPopover && !hasSomeSelection}
            icon={<Search fontSize="small" />}
            label={hasSomeSelection ? prevOption.label : label}
            onClick={openPopup}
            {...(hasSomeSelection ? { onDelete: handleDelete } : {})}
          />
        </>
      }
    </>
  );
};

FilterText.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  prevValue: PropTypes.object.isRequired,
  getOptions: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  inline: PropTypes.bool,
};


export {
  FilterText,
};