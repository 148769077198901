import { useApi } from 'src/hooks/useApi';


const useCompanyApi = () => {
  const { get, post, del } = useApi();
  return {
    getCompanies: async companyId => (await get(`smaData/companies`, { ...(companyId ? { companyId } : {}) })).data,
    getInterestAreas: async () => (await get('company/area-of-interest')).data,
    getLinkCodes: async () => (await get('company/link-company-codes')).data,
    generateLinkCode: async captureCompanyName => (await post('company/generate-link-code', { captureCompanyName })).data,
    deleteLinkCode: async ({ linkCode }) => (await del('company/link-code', { linkCode })).data,
  };
};

export { useCompanyApi };