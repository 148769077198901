import { useApi } from 'src/hooks/useApi';


const usePermissionApi = () => {
  const { get } = useApi();
  return {
    getAll: async () => (await get(`permission/all`)).data,
  };
};


export { usePermissionApi };