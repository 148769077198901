import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, InputLabel, TextField, FormHelperText } from '@material-ui/core';

import { AlertWrapper, AutocompleteWrapper, DialogButton } from 'src/components';
import { useFetchStatus } from 'src/hooks';


const SmaGroupCreateDialog = ({ optionsTypeLabel, options, actions }) => {

  const [ name, setName ] = useState('');
  const [ selectedOptions, setSelectedOptions ] = useState([]);

  const { fetchStatus, errorMessage, handleConfirm, hasError, hasBadData } = useFetchStatus({
    confirmFn: () => actions.createGroup({ alias: name, values: selectedOptions.map(so => so.value) }),
    closeFn: actions.closeDialog,
    validationFn: () => hasName && hasSelectedOptions,
  });

  const hasName = Boolean(name);
  const hasSelectedOptions = selectedOptions.length >= 1;

  return (
    <>
      <DialogTitle id="form-dialog-title">
        Crear grupo
      </DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Box my={2}>
            <InputLabel required shrink>Nombre del grupo</InputLabel>
            <TextField
              value={name}
              variant="outlined"
              size="small"
              onChange={e => setName(e.target.value)}
              fullWidth
              autoFocus autoComplete="off"
            />
            {hasBadData && !hasName && <FormHelperText error>Debes ingresar un nombre</FormHelperText>}
          </Box>
          <Box my={2}>
            <InputLabel required shrink>{optionsTypeLabel}</InputLabel>
            <AutocompleteWrapper
              multiple
              value={selectedOptions}
              options={options}
              onChange={(_, selectedOptions) => {
                setSelectedOptions(selectedOptions);
              }}
              getOptionSelected={(option, value) => option.value === value.value}
              clearable
            />
            {hasBadData && !hasSelectedOptions && <FormHelperText error>Debes seleccionar a lo menos una opción</FormHelperText>}
          </Box>
          {hasError && <AlertWrapper severity="error">{errorMessage}</AlertWrapper>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Crear grupo</DialogButton>
      </DialogActions>
    </>
  );
};

SmaGroupCreateDialog.propTypes = {
  actions: PropTypes.object,
  options: PropTypes.array,
  optionsTypeLabel: PropTypes.string,
};


export {
  SmaGroupCreateDialog,
};
