import React, { useState, useEffect } from 'react';
import { Grid, Button, Typography, Box } from '@material-ui/core';
import { AddBox, Delete, Terrain } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';

import { useMonitoringPlanApi } from 'src/services';
import { TitleContainer, ContentContainer, Cards, ActionsBox, DialogWrap, AlertWrapper, AccessControl } from 'src/components';
import { ChartBar, FormatListGroup, ViewColumnOutline } from 'src/utils/mdIcons';
import { SmaMonitoringPlanCreateDialog, SmaMonitoringPlanDeleteDialog, SmaMonitoringPlanCard }
  from 'src/scenes/Project/scenes/MonitoringPlans/components';
import { upperCaseOnlyFirstLetter } from 'src/utils/formatters';


const useStyles = makeStyles(theme => ({
  content: {
    maxWidth: theme.spacing(140),
  },
  mainContainer: {
    width: 'calc(100%)',
  },
  comparisonBtn: {
    marginRight: theme.spacing(1),
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    cursor: 'pointer',
  },
}));

const SmaMonitoringPlansContainer = props => {

  const match = useRouteMatch();
  const history = useHistory();
  const classes = useStyles();
  const monitoringPlanApi = useMonitoringPlanApi();

  const [ dialog, setDialog ] = useState({ isOpen: false, data: {}, type: '' });

  const goToPlanComparison = () => history.push('monitoring-plans/comparison');

  const { projectId } = match.params;

  const [ monitoringPlans, setMonitoringPlans ] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const monitoringPlans = await monitoringPlanApi.getMonitoringPlans({ folderId: projectId });
      setMonitoringPlans(monitoringPlans);
    };
    fetchData();
  // eslint-disable-next-line
  }, [ projectId ]);

  const goToAnalytics = monitoringPlanId => history.push(`monitoring-plans/${monitoringPlanId}/analytics`);

  const goToCampaigns = monitoringPlanId => history.push(`campaigns?monitoringPlan=${monitoringPlanId}`);

  const goToGroups = monitoringPlanId => history.push(`monitoring-plans/${monitoringPlanId}/groups`);

  const cardActions = itemData => ([
    { Icon: Terrain, tooltip: 'Ver campañas', onClick: () => goToCampaigns(itemData.id), badgeNumber: itemData.instances.length },
    { Icon: ChartBar, tooltip: 'Ver analíticas', onClick: () => goToAnalytics(itemData.id), badgeNumber: itemData.nCharts },
    { Icon: FormatListGroup, tooltip: 'Ver grupos', onClick: () => goToGroups(itemData.id) },
    { Icon: Delete, tooltip: 'Borrar', onClick: () => openDeleteMonitoringPlanDialog(itemData.id),
      accessPermission: 'monitoring-plan:delete' },
  ]);

  const createMonitoringPlan = async name => {
    const [ monitoringPlan ] = await monitoringPlanApi.createMonitoringPlan({ name, folderId: projectId });
    setMonitoringPlans(prevMonitoringPlans => [ monitoringPlan, ...prevMonitoringPlans ]);
  };

  const deleteMonitoringPlan = async monitoringPlanId => {
    await monitoringPlanApi.deleteMonitoringPlan(monitoringPlanId);
    setMonitoringPlans(prevMonitoringPlans => prevMonitoringPlans.filter(mp => mp.id !== monitoringPlanId));
  };

  const closeDialog = () => setDialog({ isOpen: false, type: '' });

  const openCreateMonitoringPlanDialog = () => setDialog({ isOpen: true, type: 'create' });

  const openDeleteMonitoringPlanDialog = monitoringPlanId => setDialog({ isOpen: true, type: 'delete', data: monitoringPlanId });

  const getDialogType = () => {
    switch (dialog.type) {
      case 'create' : {
        return <SmaMonitoringPlanCreateDialog
          actions={{ createMonitoringPlan: name => createMonitoringPlan(name), closeDialog }}
        />;
      }
      case 'delete': {
        return <SmaMonitoringPlanDeleteDialog
          actions={{ deleteMonitoringPlan: () => deleteMonitoringPlan(dialog.data), closeDialog }}
        />;
      }
      default:
        break;
    }
  };

  return (
    <>
      <Grid container>
        <TitleContainer maxWidth="xl"
          breadcrumbs={[ 'projects', { name: `Planes de monitoreo de ${upperCaseOnlyFirstLetter(props.name)}` } ]}>
          Planes de monitoreo
        </TitleContainer>
        <ContentContainer maxWidth="xl" className={classes.content}>
          <ActionsBox>
            <AccessControl action='monitoring-plan:manage'>
              <Button className={classes.comparisonBtn} size="small" color="primary" startIcon={<ViewColumnOutline />}
                variant="outlined" onClick={goToPlanComparison}>
                Gestionar planes de monitoreo
              </Button>
            </AccessControl>
            <AccessControl action='monitoring-plan:create'>
              <Button size="small" color="primary" startIcon={<AddBox />}
                variant="contained" onClick={openCreateMonitoringPlanDialog}>
                Crear plan de monitoreo
              </Button>
            </AccessControl>
          </ActionsBox>
          {monitoringPlans.length > 0 ?
            <Cards
              alwaysShowActions={true}
              values={monitoringPlans}
              actions={cardActions}
              Content={({ itemData }) => <SmaMonitoringPlanCard {...itemData} />}
            />
            : <Box>
              <AlertWrapper severity="info">
                <Typography variant="body2">
                  No se han creado planes de monitoreo,
                  puedes <Box component="span" className={classes.link} display="inline" onClick={openCreateMonitoringPlanDialog}>crear un
                  plan</Box> o ir al <Box component="span" className={classes.link} display="inline" onClick={goToPlanComparison}>módulo de
                  gestión</Box>
                </Typography>
              </AlertWrapper>
            </Box>

          }
        </ContentContainer>
      </Grid>
      <DialogWrap maxWidth='sm' fullWidth onClose={closeDialog} open={dialog.isOpen}>
        {getDialogType()}
      </DialogWrap>
    </>
  );
};

SmaMonitoringPlansContainer.propTypes = {
  name: PropTypes.string,
};


export {
  SmaMonitoringPlansContainer,
};