import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';

import { AlertWrapper, DialogButton } from 'src/components';
import { useFetchStatus } from 'src/hooks';


const ProjectDeleteDialog = ({ project, actions }) => {

  const { fetchStatus, errorMessage, handleConfirm, hasError } = useFetchStatus({
    confirmFn: () => actions.deleteProject(project.id),
    closeFn: actions.closeDialog,
  });

  return (
    <>
      <DialogTitle id="form-dialog-title">
        Eliminar proyecto
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="textPrimary">
          Estás eliminando el proyecto <strong>{project.name}</strong>
        </Typography>
        <Box py={2} />
        <Typography variant="body1" color="textPrimary">
          ¿Realmente deseas eliminar el proyecto?
        </Typography>
        <Box p={1}>
          {hasError && <AlertWrapper severity="error">{errorMessage}</AlertWrapper>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Sí, eliminar proyecto</DialogButton>
      </DialogActions>
    </>
  );
};

ProjectDeleteDialog.propTypes = {
  actions: PropTypes.object,
  project: PropTypes.object,
};


export {
  ProjectDeleteDialog,
};