import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography, InputLabel, TextField, CircularProgress, FormControlLabel,
  Checkbox, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ScrollContainer } from 'react-indiana-drag-scroll';

import { useMonitoringPlanApi } from 'src/services';
import { AlertWrapper, ChartWrapper, DialogButton, SelectChip } from 'src/components';
import { buildChartData, buildChartTitle, buildUrl, chartDefaultOptions, htmlLegendPlugin }
  from 'src/scenes/Project/scenes/Analytics/smaAnalyticsHelper';
import { SearchParam } from 'src/scenes/Project/scenes/Analytics/components';
import { useRouteMatch } from 'react-router-dom';
import { upperCaseFirstLetter as ucfl } from 'src/utils/formatters';
import { useFetchStatus, usePrevious } from 'src/hooks';
import { arraysAreEqual, deepObjClone } from 'src/utils/util';


const useStyles = makeStyles(theme => ({
  group: {
    padding: '3px',
    borderRadius: theme.shape.borderRadius,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.gray,
  },
  chartGroup: {
    borderRadius: theme.shape.borderRadius,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.gray,
  },
  scrollbar: {
    '&::-webkit-scrollbar': {
      width: theme.spacing(1),
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '6px',
      backgroundColor: theme.palette.common.gray300,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '6px',
      backgroundColor: '#555',
    },
  },
  label: {
    fontSize: '0.875rem',
  },
  inputLabelShrink: {
    transformOrigin: 'bottom right',
    marginRight: theme.spacing(1) * -1,
    marginTop: '-10px',
    marginBottom: '2px',
  },
}));

const monitoringKey = 'instances';
const stationKey = 'stations';
const speciesKey = 'species';

const emptyOption = { label: '', value: '' };
const emptyChartData = { labels: [], datasets: [] };

const chartJsTypeOptions = [ { label: 'Barra', value: 'bar' }, { label: 'Linea', value: 'line' } ];

const getIsMainChartType = selectedChartTypeValue => selectedChartTypeValue === monitoringKey
|| selectedChartTypeValue === stationKey || selectedChartTypeValue === speciesKey;

const SmaAnalyticCreateDialog = props => {
  const { actions, options: { chartTypeOptions } } = props;

  const match = useRouteMatch();
  const classes = useStyles();
  const monitoringPlanApi = useMonitoringPlanApi();

  const { monitoringPlanId } = match.params;

  const { fetchStatus, errorMessage, hasError, handleConfirm, setFetchStatus, setErrorMessage, fetchStatusEnum } = useFetchStatus({
    confirmFn: () => {
      const instances = selectedMonitoringInstances.length ? (isHidingMonitoringInstances ?
        monitoringInstancesOptions.filter(mio => !selectedMonitoringInstances.map(smi => smi.value).includes(mio.value))
        : selectedMonitoringInstances) : undefined;

      const stations = selectedReplicaStations.length ? (isHidingReplicaStations ?
        replicaStationsOptions.filter(rso => !selectedReplicaStations.map(srs => srs.value).includes(rso.value))
        : selectedReplicaStations) : undefined;

      const species = selectedSpecies.length ? (isHidingSpecies ?
        speciesOptions.filter(so => !selectedSpecies.map(ss => ss.value).includes(so.value))
        : selectedSpecies) : undefined;

      const requestData = hasGroupsSelected ?
        [ ...(instances ? instances.map(e => ({ ...e, type: 'monitoringInstanceId' })) : []),
          ...(stations ? stations.map(e => ({ ...e, type: 'stationReplicateId' })) : []),
          ...(species ? species.map(e => ({ ...e, type: 'species' })) : []),
        ].map(r =>
          ({
            id: r.value,
            isGroup: r.data.isAlias,
            type: r.type,
          }),
        )
        :
        {
          monitoringInstanceId: instances?.map(i => i.value),
          stationReplicateId: stations?.map(s => s.value),
          species: species?.map(s => s.value),
        };

      return actions.saveAnalytic({
        monitoringPlanId,
        chartData: {
          name: chartPreview.title,
          requestData,
          config: {
            valuesToChart: selectedValuesToChart,
            separatedKey,
            separatedInfo: isMultiVariable ?
              selectedValuesToChart.reduce((obj, id) => ({ ...obj, [id]: valueToChartOptions.find(vtco => vtco.value === id).label }), {})
              : chartTypeMapper[separatedKey]?.selectedOptions.reduce((obj, opt) => ({ ...obj, [opt.value]: opt.label }), {}),
            isStacked,
            chartJsType: chartPreview.chartJsType,
            sort: sortType,
            isLogarithmic,
            ...(isMainChartType ? {} : { columnName: selectedChartType.value }),
          },
          chartTypeId: isMainChartType ? chartTypeMapper[selectedChartType.value].backEnum : 'features',
        },
        chartPreview,
      });
    },
    closeFn: actions.closeDialog,
  });

  const [ isChartLoading, setIsChartLoading ] = useState(true);

  const [ selectedChartType, setSelectedChartType ] = useState(chartTypeOptions[0]);
  const [ valueToChartOptions, setValueToChartOptions ] = useState([ { label: 'Riqueza', value: 'richness' } ]);
  const [ selectedValuesToChart, setSelectedValuesToChart ] = useState([ valueToChartOptions[0].value ]);

  const [ chartPreview, setChartPreview ] = useState({
    title: '',
    chart: emptyChartData,
    originData: '',
    options: chartDefaultOptions,
    chartJsType: chartJsTypeOptions[0].value,
  });

  const [ selectedMonitoringInstances, setSelectedMonitoringInstances ] = useState([]);
  const [ selectedReplicaStations, setSelectedReplicaStations ] = useState([]);
  const [ selectedSpecies, setSelectedSpecies ] = useState([]);

  const [ replicaStationsOptions, setReplicaStationsOptions ] = useState([ emptyOption ]);
  const [ speciesOptions, setSpeciesOptions ] = useState([ emptyOption ]);
  const [ monitoringInstancesOptions, setMonitoringInstancesOptions ] = useState([ emptyOption ]);

  const [ separatedKey, setSeparatedKey ] = useState();
  const [ isStacked, setIsStacked ] = useState(false);
  const [ sortType, setSortType ] = useState();
  const [ isLogarithmic, setIsLogarithmic ] = useState(false);

  const [ isHidingMonitoringInstances, setIsHidingMonitoringInstances ] = useState(false);
  const [ isHidingReplicaStations, setIsHidingReplicaStations ] = useState(false);
  const [ isHidingSpecies, setIsHidingSpecies ] = useState(false);

  const [ isMultiVariableAllowed, setIsMultivariableAllowed ] = useState(false);
  const [ hasGroupsSelected, setHasGroupsSelected ] = useState(false);

  const prevSeparatedKey = usePrevious(separatedKey);
  const prevSelectedValuesToChart = usePrevious(selectedValuesToChart);

  const isMultiVariable = selectedValuesToChart.length > 1;

  useEffect(() => {
    const fetchData = async () => {
      if (monitoringPlanId) {
        const [ monitoringInstances, replicaStations, species, parameters ] = await Promise.all([
          monitoringPlanApi.getMonitoringPlanInstances({ monitoringPlanId, includeAlias: true }),
          monitoringPlanApi.getReplicaStationsByMonitoringPlan({ monitoringPlanId, includeAlias: true }),
          monitoringPlanApi.searchSpecies({ queryText: '', monitoringPlanId, includeAlias: true }),
          monitoringPlanApi.getParametersByMonitoringPlan(monitoringPlanId),
        ]);

        setSpeciesOptions(species.map(r => ({ label: ucfl(r.occurrenceSpecies), value: r.occurrenceSpecies, data: { ...r } })));
        setReplicaStationsOptions(replicaStations.map(rs => ({ label: rs.stationName, value: rs.id, data: rs })));

        const valuesToChartOptions = parameters.map(({ id, name }) => ({ label: name, value: id }));
        setValueToChartOptions(valuesToChartOptions);

        const sortedData = monitoringInstances.sort((a, b) => b.position - a.position);
        const monitoringInstancesOptions = sortedData.map(m => ({ label: m.name, value: m.id, data: m }));
        setMonitoringInstancesOptions(monitoringInstancesOptions);

      }
    };
    fetchData();
    //eslint-disable-next-line
  }, [ monitoringPlanId ]);

  const previousController = useRef();

  useEffect(() => {

    const fetchData = async () => {

      if (previousController.current) {
        previousController.current.abort();
      }

      const controller = new AbortController();
      const signal = controller.signal;
      previousController.current = controller;

      if (monitoringPlanId) {

        setIsChartLoading(true);

        const monitoringInstances = isHidingMonitoringInstances ?
          monitoringInstancesOptions.filter(mop => !selectedMonitoringInstances.map(smi => smi.value).includes(mop.value))
          : selectedMonitoringInstances;

        const replicaStations = isHidingReplicaStations ?
          replicaStationsOptions.filter(rso => !selectedReplicaStations.map(srs => srs.value).includes(rso.value))
          : selectedReplicaStations;

        const species = isHidingSpecies ?
          speciesOptions.filter(so => !selectedSpecies.map(ss => ss.value).includes(so.value))
          : selectedSpecies;

        const query = buildUrl({
          monitoringInstances,
          replicaStations,
          species,
          ...(isMainChartType ? {} : { columnName: selectedChartType.value }),
        });

        const chartTypeObject = chartTypeMapper[selectedChartType.value];

        try {

          const chartData = isMainChartType ?
            await chartTypeObject.getData(query, { signal })
            : await monitoringPlanApi.getOccurrenceByFeature({
              monitoringPlanId,
              ...(query ? { params: query } : {}),
              options: { signal },
            });

          const separatedInfo = isMultiVariable ?
            selectedValuesToChart.reduce((obj, id) => ({ ...obj, [id]: valueToChartOptions.find(vtco => vtco.value === id).label }), {})
            : chartTypeMapper[separatedKey]?.selectedOptions.reduce((obj, opt) => ({ ...obj, [opt.value]: opt.label }), {});

          const chart = buildChartData({
            valuesToChart: selectedValuesToChart,
            separatedKey,
            separatedInfo,
            dataToChart: chartData,
            hasSomeSeparatedData: chartTypeOptions.map(cto => cto.value).includes(separatedKey) || isMultiVariable,
            sortType,
          });

          const title = buildChartTitle({
            selectedChartType,
            hasResults: chart.datasets[0]?.data.some(d => d !== 0),
            selectedSpecies,
            selectedReplicaStations,
            selectedMonitoringInstances,
            valuesToChartLabel: selectedValuesToChart.map(svtc => valueToChartOptions.find(vtco => vtco.value === svtc).label),
          });

          const hasGroupsSelected = [ ...selectedMonitoringInstances, ...selectedReplicaStations, ...selectedSpecies ]
            .some(s => s.data.isAlias);
          setHasGroupsSelected(prev => {
            if (hasGroupsSelected && prev !== hasGroupsSelected) {
              selectedChartType.value === speciesKey ?
                setSelectedValuesToChart([ 'occurrences' ])
                : setSelectedValuesToChart([ 'richness' ]);
            }
            return hasGroupsSelected;
          });

          setChartPreview(prev => ({ ...prev, title, chart, originData: chartData }));

          setIsChartLoading(false);

          previousController.current = null;
        } catch (e) {
          if (e.message === 'Se canceló la petición de datos') {
            return;
          }
          setFetchStatus(fetchStatusEnum.ERROR);
          setErrorMessage('No se pudieron cargar los datos del gráfico');
        }
      }
    };
    fetchData();

    // eslint-disable-next-line
  }, [ monitoringPlanId, selectedChartType.value, selectedMonitoringInstances.length, selectedSpecies.length, selectedReplicaStations.length,
    isHidingMonitoringInstances, isHidingReplicaStations, isHidingSpecies ]);

  // NOTA: parece codigo duplicado, pero en realidad optimiza que no se haga una petición nueva al "separar las barras" o "cambiar
  // el valor a graficar", ya que no es necesario porque el endpoint que trae los datos a graficar envia todo y es posible elegir
  // entre todo junto y separadas, otra via de optimizar podria ser que el endpoint no enviara todo (asi seria más rapida la petición,
  // en especial con muchos datos)
  useEffect(() => {

    if (
      prevSeparatedKey !== separatedKey
      || (prevSelectedValuesToChart?.length > 0 && !arraysAreEqual(prevSelectedValuesToChart, selectedValuesToChart))
    ) {

      const selectedOptions = separatedKey ?
        chartTypeMapper[separatedKey].selectedOptions.length === 0 ?
          chartTypeMapper[separatedKey].options
          : chartTypeMapper[separatedKey].selectedOptions
        : [];

      const separatedInfo = isMultiVariable ?
        selectedValuesToChart.reduce((obj, id) => ({ ...obj, [id]: valueToChartOptions.find(vtco => vtco.value === id).label }), {})
        : selectedOptions.reduce((obj, opt) => ({ ...obj, [opt.value]: opt.label }), {});

      const chart = buildChartData({
        valuesToChart: selectedValuesToChart,
        separatedKey,
        separatedInfo,
        dataToChart: chartPreview.originData,
        hasSomeSeparatedData: chartTypeOptions.map(cto => cto.value).includes(separatedKey) || isMultiVariable,
        sortType,
      });

      const title = buildChartTitle({
        selectedChartType,
        hasResults: chart.datasets[0]?.data.some(d => d !== 0),
        selectedSpecies,
        selectedReplicaStations,
        selectedMonitoringInstances,
        valuesToChartLabel: selectedValuesToChart.map(svtc => valueToChartOptions.find(vtco => vtco.value === svtc).label),
      });

      if (isMultiVariableAllowed) {
        setIsStacked(false);
        setSeparatedKey(undefined);
      }

      const chartOptions = deepObjClone(chartDefaultOptions);
      chartOptions.scales.x.stacked = isStacked;
      chartOptions.scales.y = { stacked: isStacked, ...(isLogarithmic ? { type: 'logarithmic' } : {}) };

      setChartPreview(prev => ({ ...prev, title, chart, options: chartOptions }));
    }
    // eslint-disable-next-line
  }, [ separatedKey, selectedValuesToChart ]);

  useEffect(() => {
    const chartPreviewOptions = { ...chartDefaultOptions };
    chartPreviewOptions.scales.x.stacked = isStacked;
    chartPreviewOptions.scales.y = { stacked: isStacked, ...(isLogarithmic ? { type: 'logarithmic' } : {}) };

    setChartPreview(prev => ({ ...prev, options: chartPreviewOptions }));
    // eslint-disable-next-line
  }, [ isStacked, isLogarithmic ]);

  useEffect(() => {

    if (chartPreview.originData) {
      const selectedOptions = separatedKey ?
        chartTypeMapper[separatedKey].selectedOptions.length === 0 ?
          chartTypeMapper[separatedKey].options
          : chartTypeMapper[separatedKey].selectedOptions
        : [];

      const separatedInfo = isMultiVariable ?
        selectedValuesToChart.reduce((obj, id) => ({ ...obj, [id]: valueToChartOptions.find(vtco => vtco.value === id).label }), {})
        : selectedOptions.reduce((obj, opt) => ({ ...obj, [opt.value]: opt.label }), {});

      const chart = buildChartData({
        valuesToChart: selectedValuesToChart,
        separatedKey,
        separatedInfo,
        dataToChart: chartPreview.originData,
        hasSomeSeparatedData: chartTypeOptions.map(cto => cto.value).includes(separatedKey) || isMultiVariable,
        sortType,
      });

      setChartPreview(prev => ({ ...prev, chart }));
    }

    // eslint-disable-next-line
  }, [ sortType ]);

  const handleChartTypeChange = option => {
    const isNewSpeciesSeparated = option.value === speciesKey;
    const currentValueToChartOptions = valueToChartOptions
      .map(o => (isNewSpeciesSeparated && o.value === 'richness') ? { ...o, disabled: true } : { ...o, disabled: false });
    setValueToChartOptions(currentValueToChartOptions);
    setSelectedValuesToChart([ currentValueToChartOptions[isNewSpeciesSeparated ? 1 : 0].value ]);
    setSelectedChartType(option);
    setSeparatedKey(undefined);
    setSelectedMonitoringInstances([]);
    setSelectedSpecies([]);
    setSelectedReplicaStations([]);
  };

  const commonGetters = { separatedKey, isStacked, sortType, isMultiVariableAllowed };
  const commontHandlers = { setSeparatedKey, setIsStacked, setSortType };

  const chartTypeMapper = {
    [monitoringKey]: {
      Component: () => <SearchParam
        id={monitoringKey}
        label="Monitoreos"
        options={monitoringInstancesOptions}
        getters={{
          ...commonGetters,
          selectedChartTypeValue: selectedChartType.value,
          selectedOptions: selectedMonitoringInstances,
          chartJsType: chartPreview.chartJsType,
          isHiding: isHidingMonitoringInstances,
        }}
        handlers={{
          ...commontHandlers,
          setSelectedOptions: setSelectedMonitoringInstances,
          setIsHiding: setIsHidingMonitoringInstances,
        }}
      />,
      getData: (params, options) =>
        monitoringPlanApi.getOccurrencesByMonitoringInstance({ monitoringPlanId, ...(params ? { params } : {}), options }),
      selectedOptions: selectedMonitoringInstances,
      options: monitoringInstancesOptions,
      backEnum: 'instances',
    },
    [stationKey]: {
      Component: () => <SearchParam
        id={stationKey}
        label="Estaciones replica"
        options={replicaStationsOptions}
        getters={{
          ...commonGetters,
          selectedChartTypeValue: selectedChartType.value,
          selectedOptions: selectedReplicaStations,
          chartJsType: chartPreview.chartJsType,
          isHiding: isHidingReplicaStations,
        }}
        handlers={{
          ...commontHandlers,
          setSelectedOptions: setSelectedReplicaStations,
          setIsHiding: setIsHidingReplicaStations,
        }}
      />,
      getData: (params, options) => monitoringPlanApi
        .getOccurrencesByReplicaStation({ monitoringPlanId, ...(params ? { params } : {}), options }),
      selectedOptions: selectedReplicaStations,
      options: replicaStationsOptions,
      backEnum: 'stations',
    },
    [speciesKey]: {
      Component: () => <SearchParam
        id={speciesKey}
        label="Especies"
        options={speciesOptions}
        getters={{
          ...commonGetters,
          selectedChartTypeValue: selectedChartType.value,
          selectedOptions: selectedSpecies,
          chartJsType: chartPreview.chartJsType,
          isHiding: isHidingSpecies,
        }}
        handlers={{
          ...commontHandlers,
          setSelectedOptions: setSelectedSpecies,
          setSeparatedKey,
          handleSeparation: () => {
            const isNewSpeciesSeparated = separatedKey !== speciesKey;
            setValueToChartOptions(
              ps => ps.map(o => (isNewSpeciesSeparated && o.value === 'richness') ? { ...o, disabled: true } : { ...o, disabled: false }),
            );
            setSeparatedKey(prevKey => prevKey ? undefined : speciesKey);
          },
          setIsHiding: setIsHidingSpecies,
        }}
      />,
      getData: (params, options) => monitoringPlanApi.getOccurrencesBySpecies({ monitoringPlanId, ...(params ? { params } : {}), options }),
      selectedOptions: selectedSpecies,
      options: speciesOptions,
      backEnum: 'species',
    },
  };

  const chartPreviewOptions = { ...chartDefaultOptions };
  chartPreviewOptions.scales.x.stacked = isStacked;
  chartPreviewOptions.scales.y = { stacked: isStacked, ...(isLogarithmic ? { type: 'logarithmic' } : {}) };

  const hasMonitorings = monitoringInstancesOptions.length > 0;
  const hasReplicStations = replicaStationsOptions.length > 0;
  const hasSpecies = speciesOptions.length > 0;

  const hasAll = hasMonitorings && hasReplicStations && hasSpecies;

  const isMainChartType = getIsMainChartType(selectedChartType.value);

  return (
    <>
      <DialogTitle id="form-dialog-title">Agregar analítica</DialogTitle>
      <DialogContent>
        <Box p={1}>
          {!hasAll ?
            <AlertWrapper severity="error">
              <Typography variant="body2">
                <strong>No se encontraron monitoreos, estaciones réplica y/o especies</strong> en el plan de
                monitoreo, <strong>te aconsejamos revisar las campañas</strong> que componen el plan de
                monitoreo <strong>para verificar que contengan datos.</strong> Si al menos una campaña contiene
                datos (monitoreos, estaciones réplica y especies) deberías poder realizar gráficos.
              </Typography>
            </AlertWrapper>
            :
            <>
              <Box display="flex">
                <Box width="50%" mr={1}>
                  {/* <Box my={2}>
                    <InputLabel required disabled shrink>Plan de monitoreo</InputLabel>
                    <AutocompleteWrapper
                      disabled
                      value={selectedMonitoringPlan}
                      options={monitoringPlansOptions}
                      onChange={(event, selectedOption) => setSelectedMonitoringPlan(selectedOption)}
                      getOptionSelected={(option, value) => option.value === value.value}
                    />
                  </Box> */}
                  <Box my={2}>
                    <InputLabel shrink>Tipo de analítica (Eje X)</InputLabel>
                    <Box className={classes.group}>
                      <SelectChip
                        returnFullOption
                        value={selectedChartType.value}
                        options={chartTypeOptions}
                        onChange={handleChartTypeChange}
                      />
                    </Box>
                  </Box>
                  <Box my={2}>
                    <InputLabel shrink>Tipo de gráfico</InputLabel>
                    <Box className={classes.group}>
                      <SelectChip
                        value={chartPreview.chartJsType}
                        options={chartJsTypeOptions}
                        onChange={option => {
                          if (option.value === 'line') {
                            setIsStacked(false);
                          }
                          setChartPreview(ps => ({ ...ps, chartJsType: option.value }));
                        }}
                      />
                    </Box>
                  </Box>
                  <Box my={1}>
                    {isMainChartType && chartTypeMapper[selectedChartType.value].Component()}
                  </Box>
                  {Object
                    .keys(chartTypeMapper)
                    .filter(key => key !== selectedChartType.value)
                    .map(key => key !== selectedChartType.value ?
                      <Box key={key} my={1}>{chartTypeMapper[key].Component()}</Box> :
                      <></>,
                    )}
                  <Box my={1}>
                    <Box display="flex" alignItems="flex-end">
                      <InputLabel required shrink>Valor a graficar (Eje Y)</InputLabel>
                      <Box flexGrow={1} />
                      <InputLabel shrink classes={{ shrink: classes.inputLabelShrink }}>
                        <FormControlLabel
                          control={
                            <Switch size="small" checked={isMultiVariableAllowed} disabled={Boolean(separatedKey)} onChange={() => {
                              setIsMultivariableAllowed(p => {
                                const isAllowerd = !p;
                                if (!isAllowerd) {
                                  setSelectedValuesToChart(prev => [ prev[0] ]);
                                }
                                return !p;
                              });
                            }} />
                          }
                          label="Habilitar graficar múltiples valores"
                        />
                      </InputLabel>
                    </Box>
                    <Box pl={1} className={classes.group}>
                      <SelectChip
                        value={isMultiVariableAllowed ? selectedValuesToChart : selectedValuesToChart[0]}
                        options={
                          valueToChartOptions.filter(o => (isMainChartType && !hasGroupsSelected) ?
                            true : (o.value === 'richness' || o.value === 'occurrences'),
                          )
                        }
                        onChange={option => isMultiVariableAllowed ?
                          setSelectedValuesToChart(option.value)
                          : setSelectedValuesToChart([ option.value ])
                        }
                        isMulti={isMultiVariableAllowed}
                      />
                    </Box>
                    <FormControlLabel
                      classes={{ label: classes.label }}
                      control={
                        <Checkbox
                          checked={isLogarithmic}
                          size="small"
                          onChange={() => setIsLogarithmic(ps => !ps)}
                          color="secondary"
                        />
                      }
                      label="¿Usar escala logarítmica?"
                    />
                  </Box>
                </Box>
                <Box width="50%" ml={1}>
                  <Box my={2}>
                    <InputLabel required shrink>Nombre</InputLabel>
                    <TextField
                      value={chartPreview.title}
                      onChange={e => setChartPreview(ps => ({ ...ps, title: e.target.value }))}
                      fullWidth
                      autoFocus
                      required
                      autoComplete="off"
                      variant="outlined"
                      size="small"
                      placeholder="Ej: Riqueza de vachellia caven en temporada invierno"
                    />
                  </Box>
                  <Box my={2}>
                    <InputLabel required shrink>Previsualización</InputLabel>
                    <Box className={classes.chartGroup} p={2}>
                      {!isChartLoading ?
                        <>
                          <Box textAlign="center" display="flex" justifyContent="center">
                            <Typography variant="h5">{chartPreview.title}</Typography>
                          </Box>
                          <br />
                          <ScrollContainer hideScrollbars={false} className={classes.scrollbar}>
                            <Box id="legend-container" maxHeight={104} />
                          </ScrollContainer>
                          <ChartWrapper
                            type={chartPreview.chartJsType}
                            options={chartPreview.options}
                            data={chartPreview.chart}
                            plugins={[ htmlLegendPlugin ]}
                          />
                        </>
                        :
                        <Box display="flex" alignItems="center" flexDirection="column">
                          <CircularProgress size={25} thickness={4.8} />
                          <Box mt={0.5}>
                            <Typography variant="body2"><strong>Cargando gráfico...</strong></Typography>
                          </Box>
                        </Box>
                      }
                    </Box>
                  </Box>
                </Box>
              </Box>

              {hasError &&
                <>
                  <br />
                  <AlertWrapper severity="error">{errorMessage}</AlertWrapper>
                </>
              }
            </>
          }
        </Box>

      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        {hasAll && <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color="primary">Guardar analítica</DialogButton>}
      </DialogActions>
    </>
  );
};

SmaAnalyticCreateDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
};


export { SmaAnalyticCreateDialog };
