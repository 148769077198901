import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@material-ui/core';
import { useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';

import { useCampaignApi } from 'src/services';
import { TitleContainer, ContentContainer, Cards } from 'src/components';
import { getBaseBreadcrumbs } from 'src/scenes/Project/scenes/Campaign/SmaCampaignHelper';


const ReplicaStationsContainer = ({ campaign }) => {
  const match = useRouteMatch();
  const campaignApi = useCampaignApi();

  const { campaignId, projectId } = match.params;

  const { name: campaignName } = campaign;

  const [ replicaStations, setReplicaStations ] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const replicaStations = await campaignApi.getReplicaStations(campaignId);
      setReplicaStations(replicaStations);
    };
    fetchData();
  // eslint-disable-next-line
  }, [ campaignId ]);

  const columns = [
    { title: 'Nombre', field: 'stationName' },
    { title: 'Tipo de monitoreo', field: 'monitoringType' },
    { title: 'Ecosistema 1', field: 'ecosystemLvl1' },
    { title: 'Ecosistema 2', field: 'ecosystemLvl2' },
  ];

  return (
    <Grid container>
      <TitleContainer
        maxWidth="xl"
        breadcrumbs={[
          ...getBaseBreadcrumbs({ projectId }),
          { name: `Estaciones replica de ${campaignName}` },
        ]}
      >
        Estaciones replica
      </TitleContainer>
      <ContentContainer maxWidth="xl">
        <Box p={4}>
          <Cards values={replicaStations} columns={columns} variant="condensed" />
        </Box>
      </ContentContainer>
    </Grid>
  );
};

ReplicaStationsContainer.propTypes = {
  campaign: PropTypes.object,
};


export {
  ReplicaStationsContainer,
};