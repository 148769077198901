import React from 'react';
import PropTypes from 'prop-types';
import { Box, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  title: {
    fontSize: theme.typography.subtitle1.fontSize,
    color: theme.palette.common.gray600,
    textTransform: 'uppercase',
    fontWeight: 600,
  },
}));

const AnalyticCard = props => {
  const { title, children, ActionComponent } = props;
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Box display="flex" flexDirection="column">
        <Box display="flex">
          {title &&
            <Box mb={1}>
              <Typography className={classes.title} noWrap>{title}</Typography>
            </Box>
          }
          {ActionComponent && <><Box flexGrow={1} /><ActionComponent /></>}
        </Box>
        {children}
      </Box>
    </Paper>
  );
};

AnalyticCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  ActionComponent: PropTypes.func,
};


export { AnalyticCard };