import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Mail, Phone } from '@material-ui/icons';
import { useHistory } from 'react-router';


const useStyles = makeStyles(theme => ({
  container: {
    height: theme.spacing(40),
    color: theme.palette.common.white,
    backgroundColor: '#40474B',
    paddingLeft: theme.spacing(25),
    paddingRight: theme.spacing(10),
    [theme.breakpoints.down(1500)]: {
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(0),
    },
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'wrap',
      height: 'fit-content',
      paddingBottom: theme.spacing(5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  logoAndInfoContainer: {
    paddingTop: theme.spacing(5),
    minWidth: theme.spacing(49),
    [theme.breakpoints.down('md')]: {
      minWidth: theme.spacing(32),
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(5),
    },
  },
  infoContainer: {
    maxWidth: theme.spacing(31),
  },
  siteMapContainer: {
    fontSize: '1.125rem',
    fontWeight: '500',
    paddingTop: theme.spacing(13),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(7),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(0),
    },
  },
  logo: {
    height: theme.spacing(6),
  },
  title: {
    fontSize: '1.125rem',
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  link: {
    cursor: 'pointer',
    fontSize: '1.125rem',
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  icons: {
    fontSize: theme.spacing(2.5),
  },
}));

const HomeFooterContainer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  return (
    <Box className={classes.container} display="flex">
      <Box className={classes.logoAndInfoContainer} display="flex" flexDirection="column" alignItems="flex-start">
        <img src={theme.logos.nviroWhite} className={classes.logo} alt="Nviro Logo" />
        <Box className={classes.infoContainer} pl={5} mt={2} display="flex" flexDirection="column">
          <Box mb={2}>
            <Typography variant="body2"><strong>© Copyright 2021</strong></Typography>
          </Box>
          <Box my={{ xs: 0, md: 2 }} display="flex">
            <Mail className={classes.icons} />
            &nbsp;
            <Typography variant="body2">contacto@nviro.cl</Typography>
          </Box>
          <Box my={{ xs: 0, md: 2 }} display="flex">
            <Phone className={classes.icons} />
            &nbsp;
            <Typography variant="body2">+56 2 3224 9095</Typography>
          </Box>
        </Box>
      </Box>
      <Grid container className={classes.siteMapContainer}>
        <Grid item xs={6} sm={6} md={3}>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Box mb={{ xs: 3, sm: 3, md: 5 }} mt={{ xs: 3, sm: 0, md: 0 }}>
            <Typography variant="body2" className={classes.title} color="secondary">COMPAÑÍA</Typography>
          </Box>
          <Box my={{ xs: 1, sm: 1, md: 2 }}>
            <Typography variant="body2" className={classes.link} onClick={() => history.push('/about-us')}>Quiénes somos</Typography>
          </Box>
          {/* <Box my={{ xs: 1, sm: 1, md: 2 }}>
            <Typography variant="body2" className={classes.link} onClick={() => history.push('/contact')}>Contacto</Typography>
          </Box> */}
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Box mb={{ xs: 3, sm: 3, md: 5 }} mt={{ xs: 3, sm: 0, md: 0 }}>
            <Typography variant="body2" className={classes.title} color="secondary" >LEGAL</Typography>
          </Box>
          <Box my={{ xs: 1, sm: 1, md: 2 }}>
            <Typography variant="body2" className={classes.link} onClick={() => history.push('/terms')}>Términos y condiciones</Typography>
          </Box>
          <Box my={{ xs: 1, sm: 1, md: 2 }}>
            <Typography variant="body2" className={classes.link} onClick={() => history.push('/privacy')}>
              Políticas de privacidad
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};


export { HomeFooterContainer };