import { post } from 'src/services/request';
import config from 'src/config/local';


const contact = async contactData => await post(config.contactUrl, contactData, { credentials: 'omit' });

const requestDemo = async data => await post('/web/request-demo', data, { credentials: 'omit' });

export {
  contact,
  requestDemo,
};
