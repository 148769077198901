import { urlJoin } from 'src/utils/util';


const getBaseBreadcrumbs = ({ projectId }) =>
  [
    'projects',
    { name: `Campañas`, url: urlJoin('/web', 'projects', projectId, 'campaigns') },
  ];


export { getBaseBreadcrumbs };