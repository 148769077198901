import React, { useState } from 'react';
import { Grid, Box, Card, CardHeader, CardContent, CardActions, Button, Typography, Tooltip } from '@material-ui/core';
import { useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import config from 'src/config/local';
import { useAuth0 } from '@auth0/auth0-react';

import { TitleContainer, ContentContainer } from 'src/components';
import { getBaseBreadcrumbs } from 'src/scenes/Project/scenes/Campaign/SmaCampaignHelper';
import { Info, OpenInNew } from '@material-ui/icons';
import { urlJoin } from 'src/utils/util';


const { smacoreLink, serverUrl } = config;

const useStyles = makeStyles(theme => ({
  info: {
    cursor: 'pointer',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    flexGrow: 1,
  },
  linkIcon: {
    marginTop: theme.spacing(-0.1),
    marginLeft: theme.spacing(0.5),
    fontSize: '16px',
  },
}));

const DownloadablesContainer = ({ campaign }) => {
  const match = useRouteMatch();

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const { projectId } = match.params;

  const { name: campaignName } = campaign;

  const classes = useStyles();

  const hasSmaDarwincore = campaign.docUrl;
  const hasSnifaReport = campaign.snifaUrl;

  const [ inProgress, setInProgress ] = useState(false);

  const handleClick = async () => {
    let token;
    try {
      setInProgress(true);
      try {
        token = await getAccessTokenSilently();
      } catch (e) {
        console.error(e);
        await loginWithRedirect();
        return;
      }
      const endpointUrl = urlJoin(serverUrl, 'api/', 'capture', 'smacore', campaign.id);
      const response = await fetch(endpointUrl, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error('Error on get smacore');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'smacore.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      setInProgress(false);
    } catch (e) {
      setInProgress(false);
      console.error(e);
      alert('Error al obtener el documento.');
    }
  };

  return (
    <Grid container>
      <TitleContainer
        maxWidth="xl"
        breadcrumbs={[
          ...getBaseBreadcrumbs({ projectId }),
          { name: `Descargables de ${campaignName}` },
        ]}
      >
        Descargables
      </TitleContainer>
      <ContentContainer maxWidth="xl">
        <Box p={4}>
          <Grid container spacing={2}>
            {hasSmaDarwincore &&
              <Grid item xs={12} sm={6} md={4} lg={3} >
                <Card classes={{ root: classes.card }}>
                  <CardHeader title="SMA-Darwincore" />
                  <CardContent classes={{ root: classes.content }}>
                    <Typography variant="body2">
                      Archivo con formato <a href={smacoreLink} target="_blank" rel="noreferrer">SMA-Darwincore</a> desde
                      el cual se obtuvo la campaña.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Box display="flex" alignItems="center" width="100%">
                      <Box flexGrow={1}>
                        { campaign.createdByNviro ?
                          <Button size="small" color="primary" onClick={handleClick} disabled={inProgress}>
                            XLSX
                          </Button> :
                          <Button size="small" color="primary" target="_blank" href={campaign.docUrl}>
                            XLSX
                          </Button>
                        }
                      </Box>
                      <Tooltip title="El SMA-Darwincore podria contener más campañas ademas de esta" interactive>
                        <Info color="disabled" fontSize="small" className={classes.info} />
                      </Tooltip>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
            }
            {hasSnifaReport &&
              <Grid item xs={12} sm={6} md={4} lg={3} >
                <Card classes={{ root: classes.card }}>
                  <CardHeader title="Enlace a Snifa" />
                  <CardContent classes={{ root: classes.content }}>
                    <Typography variant="body2">
                      Enlace a unidad fiscalizable en Snifa desde la cual se obtuvo el SMA-Darwincore.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Box display="flex" alignItems="center" width="100%">
                      <Box flexGrow={1}>
                        <Button size="small" color="primary" target="_blank" href={campaign.snifaUrl}>
                          Enlace <OpenInNew className={classes.linkIcon} fontSize="small" color="primary" />
                        </Button>
                      </Box>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
            }
          </Grid>
        </Box>
      </ContentContainer>
    </Grid>
  );
};

DownloadablesContainer.propTypes = {
  campaign: PropTypes.object,
};


export {
  DownloadablesContainer,
};