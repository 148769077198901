import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';

import { AlertWrapper, DialogButton } from 'src/components';
import { useFetchStatus } from 'src/hooks';


const ImportedCampaignRemoveDialog = ({ actions }) => {

  const { fetchStatus, errorMessage, handleConfirm, hasError } = useFetchStatus({
    confirmFn: () => actions.removeImportedCampaign(),
    closeFn: actions.closeDialog,
  });

  return (
    <>
      <DialogTitle id="form-dialog-title">
        Desvincular campaña
      </DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Typography variant="body1" color="textPrimary">
            Al desvincular esta campaña <strong>perderás la información asociada a los planes de monitoreo y/o
            analíticas </strong>que estén utlizando esta campaña.
          </Typography>
          <Box py={2} />
          <Typography variant="body1" color="textPrimary">
            ¿Realmente deseas desvincular la campaña?
          </Typography>
          {hasError && <><Box py={1}><AlertWrapper severity="error">{errorMessage}</AlertWrapper></Box></>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Si, desvincular</DialogButton>
      </DialogActions>
    </>
  );
};

ImportedCampaignRemoveDialog.propTypes = {
  actions: PropTypes.object,
};


export {
  ImportedCampaignRemoveDialog,
};
