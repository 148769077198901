import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, InputLabel, TextField, FormHelperText } from '@material-ui/core';

import { AlertWrapper, DialogButton } from 'src/components';
import { useFetchStatus } from 'src/hooks';


const ProjectCreateDialog = ({ actions }) => {

  const [ project, setProject ] = useState({ name: '' });

  const { fetchStatus, errorMessage, handleConfirm, hasError, hasBadData } = useFetchStatus({
    confirmFn: () => actions.createProject(project),
    closeFn: actions.closeDialog,
  });

  const hasName = Boolean(name);

  return (
    <>
      <DialogTitle id="form-dialog-title">
        Crear proyecto
      </DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Box my={2}>
            <InputLabel required shrink>Nombre del proyecto</InputLabel>
            <TextField
              value={project.name}
              variant="outlined"
              size="small"
              onChange={e => setProject({ ...project, name: e.target.value })}
              fullWidth
              autoFocus
              autoComplete="off"
            />
            {hasBadData && !hasName && <FormHelperText error>Debes ingresar un nombre</FormHelperText>}
          </Box>
          {hasError && <AlertWrapper severity="error">{errorMessage}</AlertWrapper>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Crear proyecto</DialogButton>
      </DialogActions>
    </>
  );
};

ProjectCreateDialog.propTypes = {
  actions: PropTypes.object,
  options: PropTypes.array,
  optionsTypeLabel: PropTypes.string,
};


export {
  ProjectCreateDialog,
};
