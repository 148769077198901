import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, TextField, InputLabel } from '@material-ui/core';

import { AlertWrapper, DialogButton } from 'src/components';
import { useFetchStatus } from 'src/hooks';


const ProjectEditDialog = ({ project, actions }) => {

  const [ editedProject, setEditedProject ] = useState(project);

  const { fetchStatus, errorMessage, handleConfirm, hasError } = useFetchStatus({
    closeFn: actions.closeDialog,
    confirmFn: () => actions.editProject(editedProject),
  });

  return (
    <>
      <DialogTitle id="form-dialog-title">
        Editar proyecto
      </DialogTitle>
      <DialogContent>
        <Box my={3}>
          <Box my={2}>
            <InputLabel required shrink>Nombre del proyecto</InputLabel>
            <TextField
              size="small"
              variant="outlined"
              name="name"
              value={editedProject.name}
              onChange={e => setEditedProject({ ...editedProject, name: e.target.value })}
              fullWidth
              autoFocus
              autoComplete="off"
            />
          </Box>
        </Box>

        <Box p={1}>
          {hasError && <AlertWrapper severity="error">{errorMessage}</AlertWrapper>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Editar proyecto</DialogButton>
      </DialogActions>
    </>
  );
};

ProjectEditDialog.propTypes = {
  actions: PropTypes.object,
  project: PropTypes.object,
};


export {
  ProjectEditDialog,
};