import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';


const PieChartSkeleton = () =>
  <Box display="flex" alignItems="center" flexDirection="column">
    <Box mb={2}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Skeleton animation="wave" variant="text" height={20} width={50} />
        </Grid>
        <Grid item xs>
          <Skeleton animation="wave" variant="text" height={20} width={50} />
        </Grid>
        <Grid item xs>
          <Skeleton animation="wave" variant="text" height={20} width={50} />
        </Grid>
      </Grid>
    </Box>
    <Skeleton animation="wave" variant="circle" height={160} width={160} />
  </Box>;


export { PieChartSkeleton };