import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Chip } from 'src/components';

const SpeciesTypeFilter = ({ speciesType, setSpeciesType, showAllOption = true }) => (
  <>
    {showAllOption &&
      <Chip
        color={speciesType === 'total' ? 'secondary' : 'gray'}
        label="Todo"
        size="small"
        onClick={() => setSpeciesType('total')}
      />
    }
    <Box mx={0.5} />
    <Chip
      color={speciesType === 'flora' ? 'flora.primary.main' : 'gray'}
      label="Flora"
      size="small"
      onClick={() => setSpeciesType('flora')}
    />
    <Box mx={0.5} />
    <Chip
      color={speciesType === 'fauna' ? 'fauna.primary.main' : 'gray'}
      label="Fauna"
      size="small"
      onClick={() => setSpeciesType('fauna')}
    />
  </>
);

SpeciesTypeFilter.propTypes = {
  speciesType: PropTypes.string,
  setSpeciesType: PropTypes.func,
  showAllOption: PropTypes.bool,
};


export { SpeciesTypeFilter };