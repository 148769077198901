import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ImageShowWrapper } from 'src/components';


const useStyles = makeStyles(() => ({
  root: {
    margin: '0px -24px -10px -20px',
  },
}));

const OccurrencePopup = ({ data, token }) => {

  const classes = useStyles();

  if (!data || !data[0] || !data[0].occurrences[0]) {
    return <></>;
  }
  const occurrences = data[0].occurrences;

  const images = [];
  occurrences.forEach(occurrence => {
    if (occurrence.images?.length > 0) {
      occurrence.images.forEach(url => {
        images.push({ url, label: occurrence.species });
      });
    } else {
      images.push({ url: null, label: occurrence.species });
    }
  });

  return (
    <Card elevation={0} className={classes.root}>
      <ImageShowWrapper images={images} token={token}/>
    </Card>
  );
};

OccurrencePopup.propTypes = {
  data: PropTypes.array,
  token: PropTypes.string.isRequired,
};


export {
  OccurrencePopup,
};