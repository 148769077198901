import React from 'react';
import { Typography, Box, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { LabelChip } from 'src/components';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const useStyles = makeStyles(theme => ({
  container: {
    width: '70%',
  },
  title: {
    fontWeight: 500,
  },
  icon: {
    marginLeft: theme.spacing(0.5),
    cursor: 'pointer',
    fontSize: '14px',
    color: theme.palette.common.gray600,
  },
  labelDate: {
    width: 'fit-content',
  },
}));

const LinkCodeCard = ({ code, tmpCompanyName, companyName }) => {
  const classes = useStyles();

  const handleCopyClick = () => {
    navigator.clipboard.writeText(code);
  };

  return (
    <Box display="flex" alignItems="center" flexGrow={1} className={classes.container}>
      <Box display='flex' flexDirection="column" sx={{ p: 1, width: companyName ? '100%' : '50%' }}>
        <Box display="flex" alignItems="center">
          <Typography noWrap className={classes.title} variant="h6" color="textPrimary">
            {companyName || tmpCompanyName}
          </Typography>
        </Box>
      </Box>
      {!companyName && <Box display='flex' flexDirection="column" alignItems="center" sx={{ p: 1, width: '50%' }}>
        <Typography variant="caption">Código de vinculación</Typography>
        <Box display="flex" alignItems="center">
          <LabelChip className={classes.labelDate} color="default" variant="outlined" label={code}></LabelChip>
          <IconButton onClick={handleCopyClick} aria-label="copy code" size="small">
            <ContentCopyIcon />
          </IconButton>
        </Box>
      </Box>}
    </Box>
  );
};

LinkCodeCard.propTypes = {
  code: PropTypes.string,
  tmpCompanyName: PropTypes.string,
  companyName: PropTypes.string,
};


export { LinkCodeCard };
