import React, { useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { NavigateNext } from '@material-ui/icons';

import { WebContext } from 'src/scenes/web-context';
import { arraysAreEqual } from 'src/utils/util';


const useStyles = makeStyles(theme => ({
  currentLink: {
    cursor: 'pointer',
    fontWeight: 500,
  },
  root: {
    ...theme.typography.body2,
  },
}));

const projectsBaseUrl = '/web/projects';

const baseUrl = '/web/summary';


const urls = {
  summary: () => ({ name: 'Inicio', url: baseUrl }),
  dashboard: () => ({ name: 'Resumen', url: projectsBaseUrl }), // TODO: arreglar this (buscar 'dashboard' en vscode)
  company: companyName => ({ name: companyName, url: projectsBaseUrl }),
  projects: () => ({ name: 'Proyectos', url: projectsBaseUrl }),
};

const NviroBreadcrumbs = ({ breadcrumbs, ...props }) => {
  const context = useContext(WebContext);
  const classes = useStyles();
  const breadcrumbsToShow = breadcrumbs.map(breadcrumb =>
    typeof breadcrumb === 'string' ?
      breadcrumb === 'company' ? urls[breadcrumb](context.selectedCompany.name) : urls[breadcrumb](context)
      : breadcrumb,
  );

  const lastBreadcrumbIndex = breadcrumbsToShow.length - 1;

  return (
    <Breadcrumbs maxItems={5} className={classes.root} separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb" {...props}>
      {breadcrumbsToShow.map((breadcrumb, idx) =>
        idx === lastBreadcrumbIndex ?
          <Link key={idx} color="textPrimary" className={classes.currentLink} >{breadcrumb.name}</Link> :
          <Link key={idx} color="inherit" {...(breadcrumb.url ? { to: breadcrumb.url, component: RouterLink } : {})}>
            {breadcrumb.name}
          </Link>,
      )}
    </Breadcrumbs>
  );
};

NviroBreadcrumbs.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.object,
  breadcrumbs: PropTypes.array.isRequired,
  history: PropTypes.any,
};

const areEqual = (prevProps, nextProps) => arraysAreEqual(prevProps.breadcrumbs, nextProps.breadcrumbs);

const MemoizedNviroBreadcrumbs = memo(NviroBreadcrumbs, areEqual);


export {
  MemoizedNviroBreadcrumbs as NviroBreadcrumbs,
};