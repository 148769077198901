import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';


const LineChartSkeleton = () =>
  <Box display="flex" alignItems="center" flexDirection="column">
    <Box my={1}>
      <Grid container spacing={5}>
        <Grid item xs>
          <Skeleton animation="wave" variant="text" height={20} width={50} />
        </Grid>
        <Grid item xs>
          <Skeleton animation="wave" variant="text" height={20} width={50} />
        </Grid>
        <Grid item xs>
          <Skeleton animation="wave" variant="text" height={20} width={50} />
        </Grid>
        <Grid item xs>
          <Skeleton animation="wave" variant="text" height={20} width={50} />
        </Grid>
        <Grid item xs>
          <Skeleton animation="wave" variant="text" height={20} width={50} />
        </Grid>
      </Grid>
    </Box>
    <Skeleton animation="wave" variant="rect" width={600} height={300} />
  </Box>;


export { LineChartSkeleton };