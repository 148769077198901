import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  containerContent: {
    minHeight: 'calc(100vh - 208px)',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}));

const ContentContainer = ({ children, className, ...props }) => {
  const classes = useStyles();
  const classNames = [ className, classes.containerContent ].join(' ');
  return (
    <Container className={classNames} maxWidth={ false } {...props}>
      {children}
    </Container>
  );
};

ContentContainer.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export {
  ContentContainer,
};