import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import urlJoin from 'url-join';

import { ProfileContainer, MobileNotYetContainer, CompanySummaryContainer, ProjectContainer,
  SmaCampaignContainer, ProjectsContainer } from 'src/scenes';


const routes = [
  {
    id: 'profile',
    path: 'profile',
    Component: ProfileContainer,
    noEmailVerification: true,
  },
  {
    id: 'summary',
    path: 'summary',
    Component: CompanySummaryContainer,
    // hasNestedRoutes: true,
  },
  {
    id: 'campaign',
    path: 'projects/:projectId/campaigns/:campaignId',
    Component: SmaCampaignContainer,
    hasNestedRoutes: true,
  },
  {
    id: 'project',
    path: 'projects/:projectId',
    Component: ProjectContainer,
    hasNestedRoutes: true,
  },
  {
    id: 'projects',
    path: 'projects',
    Component: ProjectsContainer,
  },
];

const WebRoutes = () => {

  const { path: basePath } = useRouteMatch();
  const homePath = urlJoin(basePath, 'summary');
  const mobilePath = urlJoin(basePath, 'mobile-not-yet');
  const redirectToHomePath = () => <Redirect to={homePath} />;
  const redirectToMobilePath = () => <Redirect to={mobilePath} />;

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  return (
    <Switch>
      <Route exact path={basePath} component={isMobile ? redirectToMobilePath : redirectToHomePath} />
      <Route exact path={mobilePath} component={isMobile ? MobileNotYetContainer : redirectToHomePath} />
      {routes.map(({ id, path, Component, hasNestedRoutes = false }) =>
        <Route key={id} exact={!hasNestedRoutes} path={`${basePath}/${path}`} render={props =>
          isMobile ? <MobileNotYetContainer /> : <Component {...props} />
        } />,
      )}
    </Switch>
  );
};


export { WebRoutes };
