const serverUrl = 'https://api.monitor.nviro.cl';
const webUrl = 'https://monitor.nviro.cl';
const contactUrl = 'https://mailer.csw.cl/contact';
const mapboxToken = 'pk.eyJ1Ijoic2ViYXBjYXJyb3phIiwiYSI6ImNsc3RlaWJtdjFhNzMyaXMzZ3pxZHRibmwifQ.WTJaQgCn51s86q7G7DeVQw';
const baseMapUrl = 'https://api.mapbox.com/styles/v1';
const satelliteStreetsBaseMap = `${baseMapUrl}/mapbox/satellite-streets-v9/tiles/256/{z}/{x}/{y}?&access_token=${mapboxToken}`;
const smacoreLink = 'https://portal.sma.gob.cl/index.php/2022/04/05/sma-dicta-instruccion-para-reporte-estandarizado-de-componente-de-biodiversidad/';
const maxSizeFileUpload = 5000000;
const maxKmlPoints = 10000;
const maxKmlLines = 10000;
const maxKmlPolygons = 10000;
const maintenanceDates = {
  start: '', // formato: "YYYY-MM-DD HH:mm:ss-Z", ejemplo: "2023-12-31 18:00:00-04:00"
  finish: '', // mismo formato, se deben ingresar ambas o ninguna fecha
};


export default {
  webUrl,
  serverUrl,
  contactUrl,
  baseMapUrl,
  satelliteStreetsBaseMap,
  maxSizeFileUpload,
  maxKmlPoints,
  maxKmlLines,
  maxKmlPolygons,
  maintenanceDates,
  smacoreLink,
};
