import React from 'react';
import PropTypes from 'prop-types';
import { Button, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';


const ProcessGeoInfoDialog = ({ actions }) =>
  <>
    <DialogTitle id="form-dialog-title">Procesando área de interés</DialogTitle>
    <DialogContent>
      <Typography variant="body1" color="textPrimary">
        Estamos obteniendo la información satelital para el área de interés correspondiente.
        Esto podría tardar unos minutos.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={actions.closeDialog}>Aceptar</Button>
    </DialogActions>
  </>;

ProcessGeoInfoDialog.propTypes = {
  actions: PropTypes.object,
};


export { ProcessGeoInfoDialog };