import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Tabs, Tab, makeStyles, Typography, Button, Box, Divider } from '@material-ui/core';
import { useRouteMatch } from 'react-router';
import { Folder, Terrain, Business } from '@material-ui/icons';

import { useCaptureApi, useProjectApi, useCompanyApi } from 'src/services';
import { TitleContainer, ContentContainer, DialogWrap, ActionsBox, AccessControl } from 'src/components';
import { upperCaseOnlyFirstLetter } from 'src/utils/formatters';
import { CaptureCampaignList, CampaignImportDialog, ImportedCampaignRemoveDialog, CaptureProjectList, ImportedProjectRemoveDialog,
  ProjectImportDialog, CaptureCompanyList, GenerateLinkCodeDialog,
  LinkCodeRemoveDialog } from 'src/scenes/Project/scenes/Import/components';


const useStyles = makeStyles(theme => ({
  tabs: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 'fit-content',
  },
}));

const sources = [
  {
    id: 'project',
    title: 'Proyectos vinculados',
    addLabel: 'Importar proyecto desde Nviro Capture',
    addAction: 'import-project',
    Icon: () => <Folder />,
  },
  {
    id: 'campaign',
    title: 'Campañas vinculadas',
    addLabel: 'Importar campaña desde Nviro Capture',
    addAction: 'import-campaign',
    Icon: () => <Terrain />,
  },
  {
    id: 'company',
    title: ' vinculadas',
    addLabel: 'Vincular empresa de Nviro Capture',
    addAction: 'generate-link-code',
    Icon: () => <Business />,
  },
];


const CaptureImportContainer = props => {

  const classes = useStyles();
  const match = useRouteMatch();
  const [ tab, setTab ] = useState(0);
  const [ importedCampaigns, setImportedCampaigns ] = useState([]);
  const [ importedProjects, setImportedProjects ] = useState([]);
  const [ linkedCompaniesOptions, setLinkedCompaniesOptions ] = useState([]);
  const [ linkCodes, setLinkCodes ] = useState([]);


  const { projectId } = match.params;

  const captureApi = useCaptureApi();
  const projectApi = useProjectApi();
  const companyApi = useCompanyApi();

  const [ dialog, setDialog ] = useState({ isOpen: false, type: '' });
  const closeDialog = () => setDialog({ isOpen: false, type: '' });
  const openDialog = (type, data = {}) => setDialog({ isOpen: true, type, data });

  const openRemoveImportedCampaignDialog = captureCampaignId => openDialog('remove-imported-campaign', captureCampaignId);
  const openRemoveImportedProjectDialog = captureProjectId => openDialog('remove-imported-project', captureProjectId);
  const openRemoveLinkCodeDialog = linkCode => openDialog('remove-link-code', linkCode);



  const getDialogType = () => {
    switch (dialog.type) {
      case 'import-campaign':
        return <CampaignImportDialog options={{ linkedCompaniesOptions }} actions={{ importCampaigns, closeDialog }} />;
      case 'remove-imported-campaign': {
        const campaignId = dialog.data;
        return <ImportedCampaignRemoveDialog actions={{ closeDialog, removeImportedCampaign: () => removeImportedCampaign(campaignId) }} />;
      }
      case 'import-project':
        return <ProjectImportDialog options={{ linkedCompaniesOptions }} actions={{ importProjects, closeDialog }} />;
      case 'remove-imported-project': {
        const projectId = dialog.data;
        return <ImportedProjectRemoveDialog actions={{ closeDialog, removeImportedProject: () => removeImportedProject(projectId) }} />;
      }
      case 'generate-link-code': {
        return <GenerateLinkCodeDialog options={{ linkedCompaniesOptions }} actions={{ generateLinkCode, closeDialog }} />;
      }
      case 'remove-link-code': {
        const linkCode = dialog.data;
        return <LinkCodeRemoveDialog actions={{ closeDialog, deleteLinkCode: () => deleteLinkCode(linkCode) }} />;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const [ linkedCompanies, importedCampaigns, importedProjects, linkCodes ] = await Promise.all([
        captureApi.getLinkedCompanies(),
        projectApi.getLinkedCampaigns(projectId),
        projectApi.getLinkedProjects(projectId),
        companyApi.getLinkCodes(),
      ]);
      setLinkedCompaniesOptions(linkedCompanies.map(lc => ({ label: lc.name, value: lc.id })));
      setImportedCampaigns(importedCampaigns.map(ic => ({ ...ic, name: ic.captureCampaignName })));
      setImportedProjects(importedProjects.map(ip => ({ ...ip, name: ip.captureProjectName })));
      setLinkCodes(linkCodes);
    };
    fetchData();
    // eslint-disable-next-line
  }, [ tab ]);

  const importCampaigns = async ({ campaigns, companyId }) => {
    await projectApi.importCampaigns({ projectId, companyId, campaignIds: campaigns.map(c => c.value) });
    const imported = campaigns.map(c => ({ name: c.label, captureCampaignId: c.value }));
    setImportedCampaigns(prev => [ ...imported, ...prev ]);
  };

  const removeImportedCampaign = async campaignId => {
    await projectApi.removeImportedCampaign({ campaignId, projectId });
    setImportedCampaigns(importedCampaigns.filter(ic => ic.captureCampaignId !== campaignId));
  };

  const importProjects = async ({ projects, companyId }) => {
    await projectApi.importProjects({ projectId, companyId, projectIds: projects.map(p => p.value) });
    const imported = projects.map(c => ({ name: c.label, captureProjectId: c.value }));
    setImportedProjects(prev => [ ...imported, ...prev ]);
  };

  const removeImportedProject = async captureProjectId => {
    await projectApi.removeImportedProject({ projectId, captureProjectId });
    setImportedProjects(importedProjects.filter(ic => ic.captureProjectId !== captureProjectId));
  };

  const generateLinkCode = async captureCompanyName => {
    const generatedCode = await companyApi.generateLinkCode(captureCompanyName);
    setLinkCodes(prev => [ generatedCode, ...prev ]);
  };

  const deleteLinkCode = async linkCode => {
    await companyApi.deleteLinkCode({ linkCode });
    setLinkCodes(linkCodes.filter(lc => lc.code !== linkCode));
  };

  const isProjectsTab = tab === 0;
  const isCampaignsTab = tab === 1;
  const isCampanyTab = tab === 2;

  return (
    <Grid container>
      <TitleContainer maxWidth="xl" breadcrumbs={[ 'projects', { name: `Importar a ${upperCaseOnlyFirstLetter(props.name)}` } ]}>
        Fuentes de datos {'>'} Nviro Capture
      </TitleContainer>
      <ContentContainer maxWidth="lg">
        <Paper className={classes.tabs}>
          <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)} indicatorColor="primary" textColor="primary" variant="standard">
            <Tab label={
              <Box display="flex">
                <Folder />
                <Box ml={1}>Proyectos vinculados</Box>
              </Box>
            }/>
            <Tab label={
              <Box display="flex">
                <Terrain />
                <Box ml={1}>Campañas vinculadas</Box>
              </Box>
            } />
            <Tab label={
              <Box display="flex">
                <Business />
                <Box ml={1}>Empresas vinculadas</Box>
              </Box>
            } />
          </Tabs>
        </Paper>
        <Divider />
        <Box mt={2}></Box>
        <Typography variant="h4" color="primary">{sources[tab].title}</Typography>
        <AccessControl action='project:data-source-add'>
          <ActionsBox>
            <Button size="small" color="primary" startIcon={sources[tab].Icon()}
              variant="contained" onClick={() => openDialog(sources[tab].addAction)}>
              {sources[tab].addLabel}
            </Button>
          </ActionsBox>
        </AccessControl>
        {isProjectsTab && <CaptureProjectList actions={{ openRemoveImportedProjectDialog }} projects={importedProjects} />}
        {isCampaignsTab && <CaptureCampaignList actions={{ openRemoveImportedCampaignDialog }} campaigns={importedCampaigns} />}
        {isCampanyTab && <CaptureCompanyList actions={{ openRemoveLinkCodeDialog }} linkCodes={linkCodes}/>}
      </ContentContainer>
      <DialogWrap maxWidth='sm' fullWidth onClose={closeDialog} aria-labelledby='form-dialog-title' open={dialog.isOpen}>
        {getDialogType()}
      </DialogWrap>
    </Grid>
  );
};

CaptureImportContainer.propTypes = {
  name: PropTypes.string,
};


export {
  CaptureImportContainer,
};