import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import { useRouteMatch } from 'react-router';

import { useProjectApi } from 'src/services';
import { TitleContainer, ContentContainer, Cards } from 'src/components';


const useStyles = makeStyles(themes => ({
  content: {
    maxWidth: themes.spacing(140),
  },
  issueLabel: {
    textTransform: 'lowercase',
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
}));

const SmaIssuesContainer = () => {

  const classes = useStyles();
  const projectApi = useProjectApi();
  const match = useRouteMatch();

  const { projectId } = match.params;

  const [ issues, setIssues ] = useState({ occurrence: [], campaigns: [], document: [] });

  useEffect(() => {
    const fetchData = async () => {
      const issues = await projectApi.getIssues(projectId);
      setIssues(issues);
    };
    fetchData();
  // eslint-disable-next-line
  }, [ ]);

  return (
    <Grid container>
      <TitleContainer maxWidth="xl" breadcrumbs={[ 'projects', { name: 'Incidencias' } ]}>
        Incidencias
      </TitleContainer>
      <ContentContainer maxWidth="xl" className={classes.content}>
        <Box my={3} />
        {issues?.campaigns.length > 0 &&
          <>
            <Typography variant="h6">En campañas</Typography>
            <Cards
              values={issues.campaigns}
              Content={({ itemData }) =>
                <Typography variant="body2" className={classes.issueLabel}>
                  Se han encontrado <strong>{itemData.value}</strong> {itemData.warningName}
                </Typography>
              }
            />
          </>
        }
        <Box my={3} />
        {issues?.occurrence.length > 0 &&
          <>
            <Typography variant="h6">En ocurrencias</Typography>
            <Cards
              values={issues.occurrence}
              Content={({ itemData }) =>
                <Typography variant="body2" className={classes.issueLabel}>
                  Se han encontrado <strong>{itemData.value}</strong> {itemData.warningName}
                </Typography>
              }
            />
          </>
        }
        <Box my={3} />
        {issues?.document.length > 0 &&
          <>
            <Typography variant="h6">En documentos</Typography>
            <Cards
              values={issues.document}
              Content={({ itemData }) =>
                <Typography variant="body2" className={classes.issueLabel}>
                  Se han encontrado <strong>{itemData.value}</strong> {itemData.warningName}
                </Typography>
              }
            />
          </>
        }
        <Box my={3} />
      </ContentContainer>
    </Grid>
  );
};

SmaIssuesContainer.propTypes = {
  name: PropTypes.string,
};


export {
  SmaIssuesContainer,
};