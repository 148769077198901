import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

import 'react-image-gallery/styles/scss/image-gallery.scss';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles(theme => ({
  footer: {
    fontSize: '0.7em',
    color: theme.palette.common.gray800,
  },
  imageGalleryContainer: { maxWidth: '800px', margin: 'auto' },
  imageGalleryImage: { width: '100%', maxHeight: '500px', objectFit: 'cover' },
  content: { padding: '16px', backgroundColor: '#fff' },
}));

const DialogExternalImage = ({ pictureData }) => {
  const classes = useStyles();
  const [ images, setImages ] = useState([]);

  useEffect(() => {
    const loadImages = () => {
      try {
        const imagesData = pictureData.pictures;
        const formattedImages = imagesData.map(image => ({
          original: image.url || image,
          title: image.title || pictureData.title,
          link: pictureData.link,
          licenseUrl: pictureData.licenseUrl,
          license: pictureData.license,
          author: pictureData.author,
        }));
        setImages(formattedImages);
      } catch (error) {
        console.error(error);
      }
    };

    loadImages();
  // eslint-disable-next-line
  }, []);

  const createMarkup = html => ({ __html: DOMPurify.sanitize(html) });

  const renderItem = item =>
    <Box className={classes.imageGalleryContainer}>
      <img
        src={item.original}
        alt={item.title}
        className={classes.imageGalleryImage}
      />
      <div className={classes.content}>
        <h3>{item.title}</h3>
        {item.link &&
          <Box display="flex" mt={2} justifyContent="center" width="100%">
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              Más Información
            </a>
          </Box>
        }
        {item.author &&
          <Box display="flex" mt={1} justifyContent="right" width="100%">
            <footer className={classes.footer}>
              <p>
                Autor: <span dangerouslySetInnerHTML={createMarkup(item.author)}/>,{' '}
                <a href={item.licenseUrl} target="_blank" rel="noopener noreferrer">{item.license}</a>.
              </p>
            </footer>
          </Box>
        }
      </div>
    </Box>;

  return (
    images.length > 0 && (
      <ImageGallery
        items={images}
        renderItem={renderItem}
        useBrowserFullscreen={false}
        showBullets={false}
        showIndex={false}
        showThumbnails={false}
        showPlayButton={false}
        showFullscreenButton={false}
        slideDuration={0}
        slideInterval={1000}
      />
    )
  );
};

DialogExternalImage.defaultProps = {
  index: 0,
};

DialogExternalImage.propTypes = {
  pictureData: PropTypes.object,
};


export { DialogExternalImage };