import React from 'react';
import PropTypes from 'prop-types';
import { Delete } from '@material-ui/icons';

import { Cards } from 'src/components';


const AuditableUnitList = ({ linkedAuditableUnits, actions }) => {

  const cardActions = itemData => ([
    {
      Icon: Delete,
      tooltip: 'Eliminar',
      onClick: () => actions.openUnlinkAuditableUnitDialog({
        auditableUnitName: itemData.name,
        auditableUnitId: itemData.id,
        titleHolder: itemData.titleHolder,
      }),
    },
  ]);

  return (
    <Cards values={linkedAuditableUnits} actions={cardActions} />
  );
};

AuditableUnitList.propTypes = {
  linkedAuditableUnits: PropTypes.array,
  actions: PropTypes.object,
};


export { AuditableUnitList };