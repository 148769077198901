import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';

import { SmaImportContainer, CaptureImportContainer } from 'src/scenes/Project/scenes/Import';

const ImportContainer = () => {
  const { pathname } = useLocation();

  const view = pathname.split('/').pop();

  return (
    <>
      {view === 'sma' && <SmaImportContainer />}
      {view === 'capture' && <CaptureImportContainer />}
    </>
  );
};

ImportContainer.propTypes = {
  name: PropTypes.string,
};


export {
  ImportContainer,
};