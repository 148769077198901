import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography } from '@material-ui/core';
import urlJoin from 'url-join';

import config from 'src/config/local';


const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(7),
    paddingLeft: theme.spacing(50),
    paddingRight: theme.spacing(50),
    color: theme.palette.common.black,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  mainTitle: {
    fontSize: '4.375rem',
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
      lineHeight: '58px',
      textAlign: 'center',
    },
  },
  mainSubtitle: {
    fontSize: '1.25rem',
    color: theme.palette.common.black,
    textAlign: 'center',
    fontWeight: 500,
    paddingBottom: theme.spacing(3),
  },
  chapter: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  chapterTitle: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    textTransform: 'uppercase',
    paddingBottom: theme.spacing(2),
  },
  paragraph: {
    paddingBottom: theme.spacing(2),
    textAlign: 'justify',
  },
  subChapterNumber: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    userSelect: 'none',
  },
  subParagraph: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  subChapterTitle: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
}));

const PrivacyPolicyContainer = () => {
  const classes = useStyles();

  useEffect(() => {
    document.title = 'Política de privacidad - Nviro Monitor';
  }, []);

  return (
    <Box className={classes.container}>
      <Grid container justifyContent="center" direction="column">
        <Grid item>
          <Typography className={classes.mainTitle} color="primary">Política de privacidad</Typography>
          <Typography className={classes.mainSubtitle}>Actualizado Julio, 2021</Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.paragraph}>
            Esta es la política de privacidad (la &quot;Política de privacidad&quot;) de Nviro SpA (en adelante
            indistintamente &quot;Nviro&quot;, &quot;Nosotros&quot;, &quot;Nos&quot; o &quot;Nuestro&quot;) que describe y regula
            cómo Nviro maneja la Información personal (como se define a continuación) que los
            usuarios (&quot;Usted&quot;, &quot;Su&quot; o &quot;Usuario&quot;) nos proporcionan, o que recopilamos de Usted mediante
            el uso de Nuestro sitio web y sus aplicaciones (la &quot;Aplicación (es)&quot;, y todo conjuntamente como
            el &quot;Sitio web&quot;).
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            1.&nbsp;&nbsp;Su consentimiento
          </Typography>
          <Typography className={classes.paragraph}>
            Debe leer esta Política de privacidad en su totalidad antes de enviarnos información o utilizar Nuestro Sitio web.
            Siempre que nos envíe información personal y no personal a través de Nuestro Sitio web o de otro modo, ya sea en línea o
            fuera de línea, usted da su consentimiento para la recopilación, divulgación de uso, transferencia y almacenamiento de
            esa información de acuerdo con esta Política de privacidad.
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            2.&nbsp;&nbsp;Tipos de información que recopilamos
          </Typography>
          <Typography className={classes.paragraph}>
            &quot;Información personal&quot; se refiere a información como su nombre o inicial del segundo nombre, apellido, dirección
            de correo electrónico, dirección postal, si es diferente, pueblo o ciudad, estado, código postal, número de teléfono, fotografía
            de perfil, Protocolo de Internet (Direcciones IP), información de tarjetas de crédito y cualquier otra información que pueda
            permitir que alguien lo identifique o se comunique con usted, incluida la información recopilada a través de cookies y
            otras tecnologías.
          </Typography>
          <Typography className={classes.paragraph}>
            Nviro recopila información personal del tipo que los navegadores web, el hardware, el software y los servidores suelen poner a
            disposición, como la dirección IP, la identificación del dispositivo, el tipo de navegador, la preferencia de idioma,
            el sitio de referencia, las acciones en el Sitio web, una o más cookies que pueden identificar de manera única su navegador, y
            la fecha y hora de cada solicitud de visitante.
          </Typography>
          <Typography className={classes.paragraph}>
            El propósito de Nviro al recopilar esta información es comprender mejor cómo los visitantes de Nviro utilizan el Sitio web.
            De vez en cuando, Nviro puede divulgar dicha información en conjunto, por ejemplo, mediante la publicación de un informe sobre
            las tendencias en el uso de su Sitio web.
          </Typography>
          <Typography className={classes.paragraph}>
            Asimismo, Nviro recopila información cuando usted realiza registros de datos u observaciones en Nviro (&quot;Registro(s)&quot;),
            para realizar modelaciones, análisis o levantar estadísticas, con el objeto de mejorar la operación del Sitio web. Cuando
            realiza un Registro en Nviro, podemos recopilar su ID de usuario, latitud y longitud del sitio de la observación, el nombre
            del lugar del sitio de la observación, la fecha y hora de la observación, los metadatos asociados con la imagen o el sonido
            o los archivos, la aplicación que utilizó para aportar datos y la zona horaria del sitio de la observación. Esta recopilación
            es innominada y los Registro(s) jamás serán objeto de publicación o revelación a terceros.
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            3.&nbsp;&nbsp;Cómo recopilamos la información personal
          </Typography>
          <Typography className={classes.paragraph}>
            Nviro puede recopilar información personal cuando Usted elige interactuar con Nviro de formas que requieran que Nviro
            recopile dicha información, como si navega en Nviro, se registra como usuario de Nviro, actualiza o agrega información
            a su perfil de Nviro, proporciona contenido a Nviro a través de Su computadora o teléfono, realice un pago en el Sitio web
            de Nviro, cuando realice los Registros en Nviro (&quot;Observación&quot;) o cuando se comunique
            con Nosotros acerca de los servicios de Nviro (colectivamente, las &quot;Acciones&quot;).
          </Typography>
          <Typography className={classes.paragraph}>
            La cantidad y el tipo de información que recopila Nviro depende de la naturaleza de la Acción. En cada caso, Nviro
            recopila dicha información solo en la medida en que sea necesario o apropiado para cumplir con el propósito de Su
            interacción con Nviro. Nviro no divulga información personal que no sea la que se describe en este documento. Usted puede
            negarse a proporcionar información personal, con la salvedad de que hacerlo puede impedirle participar en determinadas
            actividades relacionadas con el Sitio web como registrarse como usuario de Nviro, mantener un perfil de Nviro o publicar
            contenido en Nviro.
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            4.&nbsp;&nbsp;Cómo utilizamos la información personal
          </Typography>
          <Typography className={classes.paragraph}>
            Podemos utilizar la Información personal que nos proporcione para prestar los Servicios de Nviro y administrar y
            mantener el Sitio web de Nviro, responder a Sus consultas, mejorar nuestros servicios, negocios y Sitio web,
            administrar nuestro negocio, incluida la detección de spam y la prevención de fraudes, con fines de marketing,
            legales y de investigación, y para comprender cómo utiliza nuestro Sitios web.
          </Typography>
          <Typography className={classes.paragraph}>
            Si se registra como usuario de Nviro, debe proporcionarnos información actualizada, completa y precisa y mantener
            actualizada la información que nos proporciona. No podemos y no seremos responsables de ningún problema o
            responsabilidad que pueda surgir si no nos proporciona información actual, precisa, veraz o completa o si no
            actualiza la información que nos proporciona.
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            5.&nbsp;&nbsp;Cuando divulgamos y compartimos información personal
          </Typography>
          <Typography className={classes.paragraph}>
            Nviro divulga información personal a contratistas, proveedores de servicios, consultores y organizaciones afiliadas que
            copulativamente (i) necesitan conocer esa información para procesarla en nombre de Nviro o para proporcionar Servicios
            disponibles en el Sitio web y aplicaciones de Nviro, y (ii) que han acordado no divulgarlo a otros.
          </Typography>
          <Typography className={classes.paragraph}>
            Al utilizar el Sitio web, Usted da su consentimiento para que se les transfiera dicha información. Nviro no alquilará
            ni venderá información personal a nadie. Compartimos su información personal con entidades que respaldan nuestras
            operaciones, como el alojamiento de nuestro Sitio web y datos, detección de fraude y spam, análisis de datos y otros
            fines comerciales legítimos.
          </Typography>
          <Typography className={classes.paragraph}>
            Aparte de lo descrito anteriormente, Nviro divulga Información personal solo cuando así lo requiera la ley, o cuando
            Nviro crea de buena fe que la divulgación es razonablemente necesaria para proteger la propiedad o los derechos de
            Nviro, terceros o el público en general. A menos que Usted lo autorice a través de su suscripción voluntaria, o
            según lo dispuesto anteriormente, pondremos su información personal a disposición de terceros no afiliados solo en
            las siguientes circunstancias:
          </Typography>
          <Typography className={classes.paragraph} component="div">
            <Box ml={{ xs: 2, md: 6 }} className={classes.subParagraph}>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  &#8226;
                </Box>
                <Box display="inline">
                  Si nos vemos obligados a hacerlo por una agencia gubernamental, un tribunal u otra entidad (por ejemplo,
                  para responder a citaciones, órdenes judiciales o procesos legales);
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  &#8226;
                </Box>
                <Box display="inline">
                  Si creemos que sus acciones violan una ley, reglamento, esta Política de privacidad o los términos de uso de
                  cualquier sitio web o aplicación aplicable, o si amenaza los derechos, la propiedad o la seguridad de nosotros,
                  nuestros sitios web, nuestras aplicaciones, cualquier otro usuario o tercero;
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  &#8226;
                </Box>
                <Box display="inline">
                  Si, a Nuestro exclusivo criterio, creemos que la divulgación es necesaria para investigar o resolver posibles
                  problemas o consultas, para proteger Nuestros activos, para defender Nuestros intereses o cumplir con Nuestras
                  obligaciones legales y reglamentarias; o para proteger la seguridad de un Usuario;
                </Box>
              </Box>
            </Box>
          </Typography>
          <Typography className={classes.paragraph}>
            Si es un usuario registrado de Nviro y ha proporcionado su dirección de correo electrónico, Nviro puede enviarle
            ocasionalmente un correo electrónico para informarle sobre nuevas funciones, solicitar su opinión o simplemente mantenerlo
            actualizado sobre lo que sucede con Nviro y nuestros servicios. Utilizamos principalmente blogs y grupos para comunicar
            este tipo de información, por lo que esperamos mantener este tipo de correo electrónico al mínimo. Si nos envía una solicitud
            (por ejemplo, a través de un correo electrónico de soporte o mediante uno de nuestros mecanismos de comentarios), nos reservamos
            el derecho de publicarla para ayudarnos a aclarar o responder a su solicitud o para ayudarnos a brindar soporte a
            otros usuarios.
          </Typography>
          <Typography className={classes.paragraph}>
            Nviro toma todas las medidas razonablemente necesarias para proteger contra el acceso, uso, alteración o destrucción no
            autorizados de la Información personal.
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            6.&nbsp;&nbsp;Base legal para el procesamiento de información personal
          </Typography>
          <Typography className={classes.paragraph}>
            La base legal de Nviro para recopilar y utilizar la Información personal descrita en esta Política de privacidad depende de
            la Información personal que recopilamos y del contexto específico en el que la recopilamos, de acuerdo a la Ley Nro. 19.628
            de la República de Chile y sus correspondientes actualizaciones. Además, procesamos la información personal con el principio
            de minimización de datos en mente. Esto significa que nos limitamos a la cantidad mínima de información personal que se necesita
            para lograr el propósito particular para el cual procesamos la información personal. A continuación se muestran algunos ejemplos
            de los fines para los que se puede procesar la información personal.
          </Typography>
          <Typography className={classes.paragraph}>
            Nviro puede procesar su información personal porque:
          </Typography>
          <Typography className={classes.paragraph} component="div">
            <Box ml={{ xs: 2, md: 6 }} className={classes.subParagraph}>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  &#8226;
                </Box>
                <Box display="inline">
                  El procesamiento es necesario para proporcionar los servicios y características que ha solicitado
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  &#8226;
                </Box>
                <Box display="inline">
                  El procesamiento es necesario para realizar un contrato con Usted
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  &#8226;
                </Box>
                <Box display="inline">
                  El procesamiento es necesario para los intereses legítimos de Nuestro negocio, como la prevención del fraude,
                  la seguridad de la información o para cumplir con la ley.
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  &#8226;
                </Box>
                <Box display="inline">
                  Usted ha dado su consentimiento para que lo hagamos.
                </Box>
              </Box>
            </Box>
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            7.&nbsp;&nbsp;Cómo retenemos la información personal
          </Typography>
          <Typography className={classes.paragraph}>
            Conservamos su información personal asociada con su registro durante el tiempo que siga siendo un usuario registrado de Nviro.
            Además, podemos retener información personal de cuentas cerradas para cumplir con las leyes nacionales, prevenir el fraude,
            cobrar las tarifas adeudadas, resolver disputas, solucionar problemas, ayudar con cualquier investigación, hacer cumplir
            Nuestro Acuerdo de usuario y tomar otras acciones permitidas o requeridas por las leyes.
          </Typography>
          <Typography className={classes.paragraph}>
            La Información personal asociada con las Observaciones (hora, fecha, ubicación, etc.) puede permanecer adjunta a las
            Observaciones, incluso después de que haya cerrado Su cuenta, a menos que solicite que la eliminemos.
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            8.&nbsp;&nbsp;Niñas (os)
          </Typography>
          <Typography className={classes.paragraph}>
            No recopilamos ni solicitamos información personal de niños a sabiendas ni permitimos que los niños realicen Acciones
            a través de nuestro Sitios web. Los visitantes de 12 años o menos deben obtener el permiso de un adulto antes
            de enviar información personal a nuestro Sitio web o aplicaciones.
          </Typography>
          <Typography className={classes.paragraph}>
            En el caso de que sepamos que hemos recibido información personal de un visitante de 12 años de edad o menos,
            y no recibimos el permiso de los padres dentro de un período de tiempo razonable de nuestra solicitud, eliminaremos
            esa información tan pronto como posible. Si cree que podríamos tener información personal de o sobre un niño de 12 años
            o menos, comuníquese con nosotros a contacto@nviro.cl. Para registrar una cuenta de Nviro para su hijo,
            consulte a contacto@nviro.cl.
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            9.&nbsp;&nbsp;Cookies
          </Typography>
          <Typography className={classes.paragraph}>
            La tecnología automatizada recopila información de su computadora o dispositivo móvil e incluye cookies, balizas web,
            objetos locales compartidos u otra tecnología similar. Una cookie es una cadena de información que un Sitio web almacena
            en la computadora de un visitante y que el navegador del visitante proporciona al Sitio web cada vez que el visitante regresa.
          </Typography>
          <Typography className={classes.paragraph}>
            Una &quot;baliza web&quot; es un pequeño objeto o imagen que está incrustado en una página web, aplicación o correo electrónico
            y se usa para rastrear la actividad. A veces también se les conoce como píxeles y etiquetas.
          </Typography>
          <Typography className={classes.paragraph}>
            A medida que accede o utiliza nuestro Sitio web, nosotros y / o terceros podemos recopilar información utilizando cookies,
            balizas web, píxeles, recopilación de datos de navegación y ubicación (flujo de clics, archivos de registro, registros
            del servidor) y otras tecnologías similares para los fines descritos en esta Política.
          </Typography>
          <Typography className={classes.paragraph}>
            Nviro utiliza cookies para ayudar a Nviro a identificar y rastrear a los visitantes, su uso de el Sitio web de Nviro y sus
            preferencias de acceso al Sitio web. Los visitantes de Nviro que no deseen que se coloquen cookies en sus computadoras deben
            configurar sus navegadores para rechazar las cookies antes de utilizar el Sitio web de Nviro. Ciertas características de el
            Sitio web de Nviro, como la capacidad de publicar Observaciones, pueden no funcionar correctamente sin la ayuda de cookies.
          </Typography>
          <Typography className={classes.paragraph}>
            Para obtener más información sobre las cookies, visite www.allaboutcookies.org. La mayoría de los navegadores están configurados
            inicialmente para permitir cookies, pero también ofrecen la opción de restringir las cookies o advertirle sobre su uso.
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            10.&nbsp;&nbsp;Cómo protegemos su información personal
          </Typography>
          <Typography className={classes.paragraph}>
            Nos comprometemos a proteger la información personal que comparte con nosotros mediante el uso de Nviro y mantenemos medidas
            de seguridad físicas, electrónicas y de procedimiento razonables para proteger su información personal. Limitamos el
            acceso a la Información personal por parte de Nuestros propios empleados a las personas que están autorizadas para el
            manejo adecuado de dicha información y cualquier empleado que infrinja Nuestros estándares de seguridad y confidencialidad
            está sujeto a Nuestros procesos disciplinarios. Solicitamos a nuestros proveedores de servicios que sigan la misma política.
            Desafortunadamente, no podemos garantizar que los datos transmitidos a través de Internet sean siempre seguros. Como
            resultado, aunque nos esforzamos por proteger su información personal, no podemos garantizar la seguridad de la información
            que transmita o que podamos conocer como resultado de su uso de Nviro, aunque nos comprometemos a notificarle cualquier
            problema de seguridad y tomar todos los resguardos razonablemente disponibles para evitar o limitar los perjuicios
            correspondientes, sin que esto implique que asumimos o reconocemos la responsabilidad del evento en cuestión. Si, por cualquier
            motivo, no está de acuerdo con esta Política de privacidad, no utilice ni intente aprovechar ninguna información,
            servicios, características o funciones de Nviro que puedan requerir que proporcione su información personal.
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            11.&nbsp;&nbsp;Sus derechos con su información personal
          </Typography>
          <Typography className={classes.paragraph}>
            Usted tiene ciertos derechos con respecto a su información personal. Aquellos incluyen:
          </Typography>
          <Typography className={classes.paragraph} component="div">
            <Box ml={{ xs: 2, md: 6 }} className={classes.subParagraph}>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  &#8226;
                </Box>
                <Box display="inline">
                  <strong>El derecho a acceder, actualizar o eliminar la información que tenemos sobre usted.</strong> En muchos casos,
                  puede acceder personalmente y actualizar su información personal accediendo a la configuración de su
                  cuenta en línea cuando inicie sesión en {urlJoin(config.webUrl, 'login')}. Si no puede realizar estas acciones usted
                  mismo, comuníquese con nosotros para ayudarlo.
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  &#8226;
                </Box>
                <Box display="inline">
                  <strong>El derecho de rectificación.</strong> Tiene derecho a que se corrija su información si dicha
                  información es inexacta o incompleta.
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  &#8226;
                </Box>
                <Box display="inline">
                  <strong>El derecho a oponerse.</strong> Tiene derecho a oponerse a nuestro procesamiento de su información personal.
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  &#8226;
                </Box>
                <Box display="inline">
                  <strong>El derecho de restricción.</strong> Tiene derecho a solicitar que restrinjamos el procesamiento de su
                  información personal.
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  &#8226;
                </Box>
                <Box display="inline">
                  <strong>El derecho a la portabilidad de datos.</strong> Tiene derecho a que se le proporcione una copia de la
                  información que tenemos sobre usted en un formato estructurado, legible por máquina y de uso común.
                </Box>
              </Box>
              <Box mt={2}>
                <Box mr={3} display="inline" className={classes.subChapterNumber}>
                  &#8226;
                </Box>
                <Box display="inline">
                  <strong>El derecho a retirar el consentimiento.</strong> Tiene derecho a retirar su consentimiento en
                  cualquier momento en el que Nviro confió en su consentimiento para procesar su información personal.
                </Box>
              </Box>
            </Box>
          </Typography>
          <Typography className={classes.paragraph}>
            Tenga en cuenta que, para su seguridad y protección, es posible que le pidamos que verifique su identidad antes de responder
            a dichas solicitudes. Para cualquier información o solicitud, comuníquese a contact@nviro.cl.
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            12.&nbsp;&nbsp;Retirar el consentimiento
          </Typography>
          <Typography className={classes.paragraph}>
            Si retira su consentimiento para el uso o la divulgación de su información personal para los fines establecidos en esta
            política de privacidad, es posible que no tenga acceso a todos nuestros servicios y es posible que no podamos brindarle
            todos los servicios y la atención al cliente que se ofrecen a nuestros usuarios y autorizados en virtud de este aviso de
            privacidad y Nuestro Acuerdo de usuario.
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            13.&nbsp;&nbsp;Enlaces y terceros
          </Typography>
          <Typography className={classes.paragraph}>
            Puede haber oportunidades a través de el Sitio web de Nviro de vez en cuando para optar por recibir información
            de entidades que no son de Nviro. Si opta por participar, puede recibir correspondencia de estas organizaciones
            sobre programas, productos o servicios que podrían interesarle. Su comunicación con estas entidades debe ser
            directamente con dichas entidades.
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            14.&nbsp;&nbsp;Cambios en la política de privacidad
          </Typography>
          <Typography className={classes.paragraph}>
            Aunque es probable que la mayoría de los cambios sean menores, Nviro puede cambiar su Política de privacidad
            de vez en cuando, ya su entera discreción. Publicaremos con 30 dias de anticipación y por medio del mismo Sitio web,
            la entrada en vigencia de la versión actualizada y revisada de esta Política de privacidad en el Sitio web de Nviro
            cuando se hayan realizado cambios importantes. El uso continuo o acceso al Sitio web después de la entrega en vigencia
            de la o las versiones así actualizadas constituye la aceptación de dichos cambios.
          </Typography>
        </Grid>
        <Grid item className={classes.chapter}>
          <Typography className={classes.chapterTitle}>
            15.&nbsp;&nbsp;Todas(os) las(os) usuarias(os)
          </Typography>
          <Typography className={classes.paragraph}>
            Si algún usuario de nuestros sitios web, aplicaciones o suscriptor tiene alguna pregunta sobre esta
            Política de privacidad, comuníquese a: contacto@nviro.cl.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};


export { PrivacyPolicyContainer };