import React, { useEffect, useState, useContext } from 'react';
import { Container, Button, TextField, Box, Paper, Grid, Typography } from '@material-ui/core';

import { useUserApi } from 'src/services';
import { StaticSnackbar, TitleContainer, ContentContainer, AutocompleteWrapper, AccessControl } from 'src/components';
import { useFetchStatus } from 'src/hooks';
import { WebContext } from 'src/scenes/web-context';


const ProfileContainer = () => {
  const { currentCompany: { companyId, companyName }, currentUser } = useContext(WebContext);
  const { email, userName, roleId } = currentUser;

  const roleMapper = {
    'consultant': 'Consultor',
    'user': 'Uruario',
    'admin': 'Administrador',
  };

  const userApi = useUserApi();

  const [ userData, setUserData ] = useState({
    userName: '',
    email: '',
    roleId: '',
  });

  const { fetchStatus, setFetchStatus, fetchStatusEnum } = useFetchStatus();
  const { LOADING, SUCCESS, ERROR, NOT_STARTED } = fetchStatusEnum;

  const [ companies, setCompanies ] = useState([]);
  // eslint-disable-next-line
  const [ currentCompany, setCurrentCompany ] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const [ companies ] = await Promise.all([
        userApi.getUserCompanies(),
      ]);
      setUserData(prevData => ({ ...prevData, email: email, userName: userName, roleId: roleMapper[roleId] }));
      setCompanies(companies.map(company => ({ value: company.id, label: company.name })));
      setCurrentCompany({ value: companyId, label: companyName });
    };
    if (companyId) {
      fetchData();
    }
  // eslint-disable-next-line
  }, [ companyId ]);


  const handleSubmit = async () => {
    setFetchStatus(LOADING);
    try {
      await userApi.changePassword();
      setFetchStatus(SUCCESS);
    } catch (e) {
      setFetchStatus(ERROR);
    }
  };

  const handleChangeCompany = async (_, selectedCompany) => {
    setCurrentCompany(selectedCompany);
  };

  const handleChangeCompanyButton = async () => {
    await userApi.changeCurrentCompany({ companyId: currentCompany.value });
    window.location.reload();
  };

  return (
    <Grid container>
      <TitleContainer>Perfil de usuario</TitleContainer>
      <ContentContainer>
        <Container maxWidth="md">
          <Box py={3} px={5}>
            <Paper style={{ paddingTop: '24px', paddingBottom: '24px' }}>
              <Box ml={3} mb={3}>
                <Typography variant="h5" color="textPrimary">Datos</Typography>
              </Box>
              <Grid container>
                <Grid item md={4} sm={6} xs={12} >
                  <Box mx={4}>
                    <TextField fullWidth disabled label="Nombre" value={userData.userName} margin="normal" />
                  </Box>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Box mx={4}>
                    <TextField fullWidth disabled label="Email" value={userData.email} margin="normal" />
                  </Box>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Box mx={4}>
                    <TextField fullWidth disabled label="Rol" value={userData.roleId} margin="normal" />
                  </Box>
                </Grid>
              </Grid>

              <Box ml={3} mt={8}>
                <Typography variant="h5" color="textPrimary">Cambiar contraseña</Typography>
              </Box>
              <Grid container>
                <Grid item md={4} sm={6} xs={12}>
                  <Box mx={4} mt={3} >
                    <Button color="primary" variant="contained" onClick={handleSubmit} disabled={NOT_STARTED !== fetchStatus}>
                      Cambiar contraseña
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  { fetchStatus === SUCCESS &&
                    <Box mx={4} mt={1}><StaticSnackbar variant="success"
                      message="Se ha enviado un email a su correo registrado para el cambio de contraseña." /> </Box>}
                  { fetchStatus === ERROR &&
                    <Box mx={4} mt={1}><StaticSnackbar variant="error"
                      message="Error en la generación de cambio de contraseña, intentelo más tarde." /> </Box>}
                </Grid>
              </Grid>
            </Paper>
          </Box>

          <AccessControl action='company:change'>
            <Box py={3} px={5}>
              <Paper style={{ paddingTop: '24px', paddingBottom: '24px' }}>
                <Box ml={3} mb={3}>
                  <Typography variant="h5" color="textPrimary">Compañía</Typography>
                </Box>
                <Grid container>
                  <Grid item md={8} sm={6} xs={12}>
                    <Box ml={3} mb={3}>
                      <Typography variant="body2" required>Titular</Typography>
                      <AutocompleteWrapper
                        value={currentCompany}
                        options={companies}
                        onChange={handleChangeCompany}
                        getOptionSelected={(option, value) => (option.value === value.value || value.value === '')}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <Box mx={4} mt={3} >
                      <Button color="primary" variant="contained" onClick={handleChangeCompanyButton} >Cambiar compañía</Button>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </AccessControl>
        </Container>
      </ContentContainer>
    </Grid>
  );
};

ProfileContainer.propTypes = {
};


export {
  ProfileContainer,
};