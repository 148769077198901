import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, Paper, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';

import { useCompanyApi, useProjectApi, useSmaApi } from 'src/services';
import { TitleContainer, ContentContainer, MapWrapper, ChartWrapper, DialogWrap, DialogExternalImage } from 'src/components';
import { useFetchStatus } from 'src/hooks';
import { removeUndefinedPropsFromObject as rupfo, removeDuplicatedFromArray as rdfa,
  transparentize, getRandomNviroColors, getRandomNviroColor } from 'src/utils/util';
import { AnalyticCard, ListIndicator, NumberIndicator, PieChartSkeleton, SpeciesTypeFilter, EeccList, LatestProjectList }
  from 'src/scenes/Project/scenes/Summary/components';
import { Skeleton } from '@material-ui/lab';
import { WebContext } from 'src/scenes/web-context';

import { buildLeafletLayer } from 'src/scenes/Project/scenes/Analytics/smaAnalyticsHelper';


const heightVH = n => document.documentElement.clientHeight * (n / 100);

const mapOptions = {
  center: [ -32.48431324745671, -71.25296320202341 ],
  zoomControl: true,
  dragging: true,
  zoom: 16,
  doubleClickZoom: false,
  attributionControl: false,
};

const useStyles = makeStyles(theme => ({
  mainContainer: {
    width: theme.spacing(180),
  },
  title: {
    fontSize: theme.typography.subtitle1.fontSize,
    color: ({ variant }) => (
      variant === 'light' ? theme.palette.common.gray600
      : variant === 'dark' ? theme.palette.common.lightGray
      : theme.palette.common.gray600
    ),
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  map: {
    marginTop: theme.spacing(0.5),
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  paper: {
    padding: theme.spacing(2),
  },
  number: {
    width: ({ isLoading }) => isLoading ? '40%' : '100%',
    fontSize: theme.typography.h5.fontSize,
    fontWeight: 600,
    color: theme.palette.common.black,
  },

  auditableUnitLabel: {
    backgroundColor: '#F7931E !important',
  },
  itemRootBorder: {
    borderRight: `1px solid ${theme.palette.common.gray}`,
  },
}));

const emptyChartData = {
  labels: [],
  datasets: [],
};

const speciesColors = getRandomNviroColors(5);
const buildSpeciesChart = ({ chartData, prefix }) => {
  const colors = speciesColors;
  const filterKeys = [ 'Occurrences', 'Species', 'EeccSpecies', 'NativeSpecies', 'EndemicSpecies' ]
    .map(f => `${prefix}${f}`);
  const chart = {
    labels: chartData.years,
    datasets: Object.keys(chartData).filter(key => filterKeys.includes(key)).map((key, idx) => (
      {
        data: chartData[key].data,
        label: chartData[key].label,
        backgroundColor: transparentize(colors[idx % 5]),
        borderColor: colors[idx % 5],
        tension: 0.1,
      }
    )),
    originData: chartData,
  };
  return chart;
};

const buildSpeciesIndicator = ({ indicatorsData, prefix }) =>
  ({
    occurrences: indicatorsData[`${prefix}Occurrences`],
    species: indicatorsData[`${prefix}Species`],
    eecc: indicatorsData[`${prefix}EeccSpecies`],
    natives: indicatorsData[`${prefix}NativeSpecies`],
    endemic: indicatorsData[`${prefix}EndemicSpecies`],
    lastYear: indicatorsData.lastYear,
    originData: indicatorsData,
  });

const buildSpeciesAllocationChart = info => {
  const colors = getRandomNviroColors();
  const { labels, data, backgroundColor } = info.reduce((obj, curr, index) => {
    obj.labels.push(curr.name);
    obj.data.push(curr.value);
    obj.backgroundColor.push(transparentize(colors[index % 7], 0.1));
    return obj;
  }, { labels: [], data: [], backgroundColor: [] });

  return {
    labels,
    datasets: [ {
      data,
      backgroundColor,
      borderColor: 'rgba(64, 71, 75, 1)',
      borderWidth: 1,
    } ],
  };
};

const CompanySummaryContainer = () => {

  const { currentCompany: { companyId, companyName } } = useContext(WebContext);
  const mapRef = useRef();
  const history = useHistory();
  const smaApi = useSmaApi();
  const companyApi = useCompanyApi();
  const projectApi = useProjectApi();

  const [ indicators, setIndicators ] = useState({});
  const [ speciesIndicators, setSpeciesIndicators ] = useState({});
  const [ speciesTypeFilter, setSpeciesTypeFilter ] = useState('total');
  const [ speciesByYearTypeFilter ] = useState('total');
  const [ eeccTypeFilter, setEeccTypeFilter ] = useState('flora');
  const [ speciesChart, setSpeciesChart ] = useState(emptyChartData);
  const [ speciesAllocationCharts, setSpeciesAllocationCharts ] = useState({
    kingdom: emptyChartData,
    flora: emptyChartData,
    fauna: emptyChartData,
  });
  const [ holders, setHolders ] = useState([]);
  const [ rcas, setRcas ] = useState([]);
  const [ eeccs, setEeccs ] = useState({});
  const [ latestProjects, setLatestProjects ] = useState([]);
  const [ layers, setLayers ] = useState([]);
  const [ dialog, setDialog ] = useState({ isOpen: false, data: {}, type: '' });

  const { setFetchStatus, fetchStatusEnum, isLoading } = useFetchStatus();

  const classes = useStyles({ isLoading });

  const { LOADING, SUCCESS } = fetchStatusEnum;

  useEffect(() => {
    const fetchData = async () => {
      setFetchStatus(LOADING);

      const [ generalInfo, { indicatorsData, chartData }, speciesAllocationInfo, auditableUnits, rcas, eeccOccurrences,
        { layers }, { projects, totalProjectCount } ] = await Promise
        .all([
          smaApi.getGeneralInfo(rupfo({ companyId })),
          smaApi.getSpeciesInfo(rupfo({ companyId })),
          smaApi.getSpeciesAllocationInfo(rupfo({ companyId })),
          smaApi.getLinkedAuditableUnits(companyId),
          smaApi.rcaSearch({ companyId, params: { limit: 0 } }),
          smaApi.getEeccOccurrences(rupfo({ companyId })),
          companyApi.getInterestAreas(),
          projectApi.getLatestProjects(),
        ]);

      if (generalInfo && totalProjectCount) {
        setIndicators({
          monitoringCount: generalInfo.nMonitoringPlans,
          ocurrencesCount: generalInfo.nOccurrences,
          campaignsCount: generalInfo.nCampaigns,
          replicaStationsCount: generalInfo.nStationReplicate,
          speciesCount: generalInfo.nSpecies,
          eeccCount: 0,
          nativeExoticCount: 0,
          endemicCount: 0,
          projectCount: totalProjectCount,
        });
      }

      if (indicatorsData) {
        const speciesIndicators = buildSpeciesIndicator({ indicatorsData, prefix: speciesTypeFilter });
        setSpeciesIndicators(speciesIndicators);
      }

      if (chartData) {
        const chart = buildSpeciesChart({ chartData, prefix: speciesTypeFilter });
        setSpeciesChart(chart);
      }

      if (speciesAllocationInfo) {
        const kingdom = buildSpeciesAllocationChart(speciesAllocationInfo.kingdomData);
        const flora = buildSpeciesAllocationChart(speciesAllocationInfo.floraData);
        const fauna = buildSpeciesAllocationChart(speciesAllocationInfo.faunaData);
        setSpeciesAllocationCharts({ kingdom, flora, fauna });
      }

      if (layers) {
        const leafletLayers = layers.map(amd => buildLeafletLayer({
          features: amd.geojson,
          id: amd.id,
          name: amd.name,
          color: getRandomNviroColor(),
        }));
        setLayers(leafletLayers);
      }

      setEeccs(eeccOccurrences);
      setRcas(rcas.map(r => r.id));
      setHolders(rdfa(auditableUnits.map(au => au.titleHolder)));
      setLatestProjects(projects);
      setFetchStatus(SUCCESS);
    };

    if (companyId) {
      fetchData();
    }

    //eslint-disable-next-line
  }, [ companyId ]);

  useEffect(() => {
    const { originData: indicatorsData } = speciesIndicators;
    if (indicatorsData) {
      const speciesIndicators = buildSpeciesIndicator({ indicatorsData, prefix: speciesTypeFilter });
      setSpeciesIndicators(speciesIndicators);
    }

    //eslint-disable-next-line
  }, [ speciesTypeFilter ]);

  useEffect(() => {
    const { originData: chartData } = speciesChart;
    if (chartData) {
      const chart = buildSpeciesChart({ chartData, prefix: speciesByYearTypeFilter });
      setSpeciesChart(chart);
    }
    //eslint-disable-next-line
  }, [ speciesByYearTypeFilter ]);

  const goToProjects = () => history.push('projects');
  const goToProject = project => history.push(`/web/projects/${project.id}`);

  const closeDialog = () => setDialog({ isOpen: false, type: '' });
  const toggleDialog = ({ data = {}, type = '' } = {}) => setDialog({ isOpen: !dialog.isOpen, data, type });

  const getDialogType = () => {
    switch (dialog.type) {
      case 'photo':
        return <DialogExternalImage
          pictureData={dialog.data.pictureData}
          additionalClass='image-species-container'
        />;
      default:
        break;
    }
  };

  return (
    <Grid container justifyContent="center" className={classes.mainContainer}>
      <TitleContainer>
        Resumen de {companyName}
      </TitleContainer>
      <ContentContainer maxWidth="xl">
        <br />
        <Grid container spacing={1}>
          <Grid container item xs={6} direction="column">
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Box className={classes.itemRootBorder} height="88px">
                    <ListIndicator
                      title="Titulares"
                      list={holders}
                      isLoading={isLoading}
                      labelChipProps={{ className: classes.auditableUnitLabel }}
                      hideCount
                      maxItems={3}
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box className={classes.itemRootBorder} height="88px">
                    <ListIndicator title="RCA's" list={rcas} color="primary" isLoading={isLoading} maxItems={5} />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box className={classes.itemRootBorder} height="88px">
                    <NumberIndicator
                      title="Proyectos"
                      value={indicators.projectCount}
                      isLoading={isLoading}
                      goTo={goToProjects}
                      goToLabel="Ver todos"
                      hasRate={false}
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box height="88px">
                    <NumberIndicator
                      title="Planes de monitoreo"
                      value={indicators.monitoringCount}
                      isLoading={isLoading}
                      hasRate={false}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Paper>
            <Box my={0.5} />
            <AnalyticCard title="Proyectos">
              <Divider />
              {isLoading && <Box mt={0.5}><Skeleton animation="wave" variant="rect" height={heightVH(50)} /></Box>}
              <MapWrapper
                id="sma-summary-map"
                className={classes.map}
                options={mapOptions}
                height={isLoading ? 0 : heightVH(50)}
                layers={layers}
                mapRef={mapRef}
                fitBoundsOnUpdate
              />
            </AnalyticCard>
            <Box my={0.5} />
            <AnalyticCard title="Últimos proyectos">
              <Divider />
              {isLoading && <Box mt={0.5}><Skeleton animation="wave" variant="rect" height={100} /></Box>}
              <Box my={0.5} />
              {!isLoading && <LatestProjectList projects={latestProjects} actions={{ goToProject }} />}
            </AnalyticCard>
          </Grid>

          <Grid container item xs={6} direction="column">
            <AnalyticCard
              title="Información de especies"
              ActionComponent={
                () => <SpeciesTypeFilter speciesType={speciesTypeFilter} setSpeciesType={setSpeciesTypeFilter} />
              }
            >
              <Divider />
              <Box my={1} />
              <Grid container spacing={2}>
                <Grid item xs>
                  <Box className={classes.itemRootBorder}>
                    <NumberIndicator
                      title="Nº de especies"
                      value={speciesIndicators.species?.total}
                      isLoading={isLoading}
                      hasRate={false}
                      size="small"
                    />
                  </Box>
                </Grid>
                <Grid item xs>
                  <Box className={classes.itemRootBorder}>
                    <NumberIndicator
                      title="EECC"
                      value={speciesIndicators.eecc?.total}
                      isLoading={isLoading}
                      hasRate={false}
                      size="small"
                    />
                  </Box>
                </Grid>
                <Grid item xs>
                  <Box className={classes.itemRootBorder}>
                    <NumberIndicator
                      title="Nativo"
                      value={speciesIndicators.natives?.total}
                      isLoading={isLoading}
                      hasRate={false}
                      size="small"
                    />
                  </Box>
                </Grid>
                <Grid item xs>
                  <NumberIndicator
                    title="Endémica"
                    value={speciesIndicators.endemic?.total}
                    isLoading={isLoading}
                    hasRate={false}
                    size="small"
                  />
                </Grid>

              </Grid>

            </AnalyticCard>
            <Box my={0.5} />

            <AnalyticCard
              title="Lista de EECC"
              ActionComponent={
                () => <SpeciesTypeFilter speciesType={eeccTypeFilter} setSpeciesType={setEeccTypeFilter} showAllOption={false} />
              }
            >
              <Divider />
              {isLoading && <Box mt={0.5}><Skeleton animation="wave" variant="rect" height={100} /></Box>}
              <Box my={0.5} />
              {!isLoading && <EeccList eeccs={eeccs} eeccTypeFilter={eeccTypeFilter} actions={{ toggleDialog }} />}
            </AnalyticCard>
            <Box my={0.5} />

            <Grid container item spacing={1}>
              <Grid item xs={4}>
                <AnalyticCard title="Distribución de reinos">
                  <Divider />
                  <Box my={1} />
                  {isLoading ?
                    <PieChartSkeleton />
                    : <ChartWrapper type="pie" options={{}} data={speciesAllocationCharts.kingdom} />
                  }
                </AnalyticCard>
              </Grid>
              <Grid item xs={4}>
                <AnalyticCard title="Clases de flora">
                  <Divider />
                  <Box my={1} />
                  {isLoading ?
                    <PieChartSkeleton />
                    : <ChartWrapper type="pie" options={{}} data={speciesAllocationCharts.flora} />
                  }
                </AnalyticCard>
              </Grid>
              <Grid item xs={4}>
                <AnalyticCard title="Clases de fauna">
                  <Divider />
                  <Box my={1} />
                  {isLoading ?
                    <PieChartSkeleton />
                    : <ChartWrapper type="pie" options={{}} data={speciesAllocationCharts.fauna} />
                  }
                </AnalyticCard>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
        <Box my={2} />
      </ContentContainer>
      <DialogWrap maxWidth='sm' fullWidth onClose={closeDialog} aria-labelledby='form-dialog-title'
        open={dialog.isOpen}>
        {getDialogType()}
      </DialogWrap>
    </Grid>
  );
};

CompanySummaryContainer.propTypes = {
  name: PropTypes.string,
};


export {
  CompanySummaryContainer,
};