import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';

import { AlertWrapper, DialogButton } from 'src/components';
import { useFetchStatus } from 'src/hooks';


const SmaGroupDeleteDialog = ({ group, actions }) => {

  const { fetchStatus, errorMessage, handleConfirm, hasError } = useFetchStatus({
    confirmFn: () => actions.deleteGroup(group.id),
    closeFn: actions.closeDialog,
  });

  return (
    <>
      <DialogTitle id="form-dialog-title">
        Eliminar grupo
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="textPrimary">
          Estás eliminando el grupo <strong>{group.alias}</strong>, esto provocará que se borre de los gráficos
          que estén utilizando dicho grupo.
        </Typography>
        <Box py={2} />
        <Typography variant="body1" color="textPrimary">
          ¿Realmente deseas eliminar el grupo?
        </Typography>
        <Box p={1}>
          {hasError && <AlertWrapper severity="error">{errorMessage}</AlertWrapper>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Eliminar grupo</DialogButton>
      </DialogActions>
    </>
  );
};

SmaGroupDeleteDialog.propTypes = {
  actions: PropTypes.object,
  group: PropTypes.object,
};


export {
  SmaGroupDeleteDialog,
};