import React, { useEffect, useState, Fragment } from 'react';
import { Box, List, ListItem, ListItemText, ListSubheader, ListItemIcon, Divider, Typography, Collapse, Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Terrain, Description, BugReport /*, StarBorder, ExpandLess, ExpandMore */ } from '@material-ui/icons';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

import { DrawerWrapper } from 'src/components';
import { urlJoin, truncateWithEllipsis as twe } from 'src/utils/util';
import { MapSearch, MonitorDashboard } from 'src/utils/mdIcons';


const useStyles = makeStyles(theme => ({
  titleBox: {
    height: '93px',
    textOverflow: 'ellipsis',
  },
  title: {
    color: theme.palette.common.gray800,
    lineHeight: 1.25,
    fontWeight: 500,
  },
  overTitle: {
    lineHeight: 1,
  },
  listItemSelected: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: theme.palette.common.white,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  listItem: {
    borderRadius: '6px',
  },
  list: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  listItemIcon: {
    minWidth: '36px',
  },
}));

const itemList = [
  {
    Icon: MonitorDashboard,
    label: 'Resumen',
    endPath: 'summary',
  },
  {
    Icon: Terrain,
    label: 'Campañas',
    endPath: 'campaigns',
  },
  {
    Icon: MapSearch,
    label: 'Planes de monitoreo',
    endPath: 'monitoring-plans',
    subPaths: [ 'comparison', 'analytics', 'groups' ],
  },
  {
    Icon: BugReport,
    label: 'Incidencias',
    endPath: 'issues',
  },
  {
    Icon: Description,
    label: 'Fuentes de datos',
    endPath: 'import',
    subItems: [
      {
        Icon: () => <Description fontSize="small" />,
        label: 'SMA DarwinCore',
        endPath: 'sma',
      },
      {
        Icon: () => <Description fontSize="small" />,
        label: 'Nviro Capture',
        endPath: 'capture',
      },
    ],
    subPaths: [ 'sma', 'capture' ],
  },
];

const getIndex = currentPathname => {
  const lastString = currentPathname.split('/').pop();
  const index = itemList.findIndex(item => item.endPath === lastString);
  let idx;
  if (index === -1) {
    itemList.forEach((item, index) => {
      if (item.subItems) {
        const subIndex = item.subItems.findIndex(si => si.endPath === lastString);
        if (subIndex !== -1) {
          idx = `${index}-${subIndex}`;
        }
      }
    });
  } else {
    idx = index;
  }
  return idx;
};

const ProjectDrawer = ({ name }) => {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const { pathname } = useLocation();

  const [ selectedIndex, setSelectedIndex ] = useState(getIndex(pathname));

  const { projectId, companyId } = match.params;

  const isCompany = Boolean(companyId);

  const baseUrl = `/web/projects/${projectId}/`;

  const handleSelectedIndex = index => () => {
    if (itemList[index]?.subItems) {
      // setOpen(prev => ({ ...prev, [index]: !prev[index] }));
      // history.push(urlJoin(baseUrl, itemList[index].endPath));
      setSelectedIndex(index);
    } else {
      if (typeof index === 'number') {
        history.push(urlJoin(baseUrl, itemList[index].endPath));
      } else {
        const [ str1, str2 ] = index.split('-');
        history.push(urlJoin(baseUrl, itemList[str1].endPath, itemList[str1].subItems[str2].endPath));
      }
      setSelectedIndex({ index });
      // setOpen(prev => ({ ...prev, [index]: !prev[index] }));
    }
  };


  useEffect(() => {
    setSelectedIndex(getIndex(pathname));
  }, [ pathname ]);

  const commonListItemProps = { button: true, component: 'a', classes: { root: classes.listItem, selected: classes.listItemSelected } };

  // const [ open, setOpen ] = useState(itemList.reduce((obj, item, index) => {
  //   if (item.subItems) {
  //     item.subItems.forEach((sub, subIdx) => {
  //       obj[`${index}-${subIdx}`] = false;
  //     });
  //   }
  //   obj[index] = false;
  //   return obj;
  // }, {}));

  return (
    <DrawerWrapper>
      <Box mt={12}>
        <Box ml={2} display="flex" alignItems="center">
          {isCompany && name === 'Caserones' && <Avatar variant="circle" alt="Caserones" src="/img/logos/caserones-avatar.png" />}
          <Box px={1} py={1} display="flex" flexDirection="column" justifyContent="center" className={classes.titleBox}>
            <Typography variant="caption" className={classes.overTitle}>{isCompany ? 'Compañía' : 'Unidad fiscalizable'}</Typography>
            <Typography className={classes.title} variant="body1">{twe(name, 70)}</Typography>
          </Box>
        </Box>
        <Divider />
        <List className={classes.list}>
          <ListSubheader color="primary">Acciones</ListSubheader>
          {itemList.map(({ Icon, label, subItems }, itemIndex) => (
            <Fragment key={itemIndex}>
              <ListItem selected={selectedIndex === itemIndex} onClick={handleSelectedIndex(itemIndex)} {...commonListItemProps}>
                <ListItemIcon classes={{ root: classes.listItemIcon }}>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={label} />
                {/* {subItems && (open[itemIndex] ? <ExpandLess /> : <ExpandMore />)} */}
              </ListItem>
              {subItems &&
                  <Collapse in={true} timeout="auto" unmountOnExit>
                    <List className={classes.list} disablePadding>
                      {subItems.map(({ Icon, label }, subitemIndex) =>
                        <ListItem key={`${itemIndex}-${subitemIndex}`} selected={selectedIndex === `${itemIndex}-${subitemIndex}`}
                          onClick={handleSelectedIndex(`${itemIndex}-${subitemIndex}`)} button
                          className={classes.nested} {...commonListItemProps}>
                          <ListItemIcon classes={{ root: classes.listItemIcon }}>
                            <Icon />
                          </ListItemIcon>
                          <ListItemText primary={label} />
                        </ListItem>,
                      )}
                    </List>
                  </Collapse>
              }
            </Fragment>
          ))}
        </List>
      </Box>
    </DrawerWrapper>
  );
};

ProjectDrawer.propTypes = {
  name: PropTypes.string,
};


export { ProjectDrawer };