import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, InputLabel, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ScrollContainer } from 'react-indiana-drag-scroll';

import { ChartWrapper, SelectChip } from 'src/components';
import { geoChartOptions, geoDataLegendPlugin, prepareGeoData, getChartsFromPreparedData }
  from 'src/scenes/Project/scenes/Analytics/smaAnalyticsHelper';
import { useFetchStatus } from 'src/hooks';


const useStyles = makeStyles(theme => ({
  group: {
    padding: '3px',
    borderRadius: theme.shape.borderRadius,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.gray,
  },
  chartGroup: {
    borderRadius: theme.shape.borderRadius,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.gray,
  },
  scrollbar: {
    '&::-webkit-scrollbar': {
      width: theme.spacing(1),
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '6px',
      backgroundColor: theme.palette.common.gray300,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '6px',
      backgroundColor: '#555',
    },
  },
  label: {
    fontSize: '0.875rem',
  },
  inputLabelShrink: {
    transformOrigin: 'bottom right',
    marginRight: theme.spacing(1) * -1,
    marginTop: '-10px',
    marginBottom: '2px',
  },
}));

const emptyChartData = { labels: [], datasets: [] };

const valuesToChartOptions = [
  'ndwi',
  'ndvi',
];

const chartTypeOptions = [
  { label: 'Histórico', value: 'historical' },
  { label: 'Por año', value: 'byYear' },
];

const SmaGeoInfo = props => {
  const { geoInfo } = props;

  const classes = useStyles();

  const { setFetchStatus, setErrorMessage, fetchStatusEnum } = useFetchStatus({
  });

  const [ isChartLoading, setIsChartLoading ] = useState(true);

  const [ selectedChartType, setSelectedChartType ] = useState(chartTypeOptions[0]);

  const [ chartPreview, setChartPreview ] = useState({
    title: '',
    chart: emptyChartData,
    originData: '',
    options: geoChartOptions,
    chartJsType: 'line',
  });


  useEffect(() => {

    const fetchData = async () => {
      setIsChartLoading(true);
      try {
        const indicators = geoInfo.data.mapConfig?.tile ? [ geoInfo.data.mapConfig.tile.toUpperCase() ] : [];
        const preparedData = prepareGeoData(geoInfo.dataToChart, [ 'historical', 'byYear' ], indicators);
        const chart = getChartsFromPreparedData(preparedData, selectedChartType.value, indicators);
        const title = 'Evolución área de trabajo';
        setChartPreview(prev => ({ ...prev, title, chart, originData: preparedData }));
        setIsChartLoading(false);
      } catch (e) {
        if (e.message === 'Se canceló la petición de datos') {
          return;
        }
        setFetchStatus(fetchStatusEnum.ERROR);
        setErrorMessage('No se pudieron cargar los datos del gráfico');
      }
    };
    fetchData();

    // eslint-disable-next-line
  }, [ selectedChartType.value, geoInfo ]);

  const handleChartTypeChange = option => {
    setSelectedChartType(option);
  };

  return (
    valuesToChartOptions.includes(geoInfo.data.mapConfig?.tile) ?
      <Box>
        <Box display="flex">
          <Box width="100%">
            <Box>
              <Box className={classes.chartGroup} p={2}>
                {!isChartLoading ?
                  <>
                    <Box textAlign="center" display="flex" justifyContent="center">
                      <Typography variant="h6">{chartPreview.title}</Typography>
                    </Box>
                    <ScrollContainer hideScrollbars={false} className={classes.scrollbar}>
                      <Box id="legend-container" maxHeight={104} />
                    </ScrollContainer>
                    <Box height="250px">
                      <ChartWrapper
                        type={chartPreview.chartJsType}
                        options={chartPreview.options}
                        data={chartPreview.chart}
                        plugins={[ geoDataLegendPlugin(selectedChartType) ]}
                      />
                    </Box>
                  </>
                  :
                  <Box display="flex" alignItems="center" flexDirection="column">
                    <CircularProgress size={25} thickness={4.8} />
                    <Box mt={0.5}>
                      <Typography variant="body2"><strong>Cargando gráfico...</strong></Typography>
                    </Box>
                  </Box>
                }
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex">
          <Box width="100%" display="flex">
            <Box my={2} mr={2} width="50%">
              <InputLabel shrink>Tipo de agrupación (Eje X)</InputLabel>
              <Box className={classes.group}>
                <SelectChip
                  returnFullOption
                  value={selectedChartType.value}
                  options={chartTypeOptions}
                  onChange={handleChartTypeChange}
                />
              </Box>
            </Box>
            <Box my={2} width="50%">
            </Box>
          </Box>
        </Box>
      </Box> : <></>
  );
};

SmaGeoInfo.propTypes = {
  geoInfo: PropTypes.object.isRequired,
};


export { SmaGeoInfo };
