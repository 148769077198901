import { useApi } from 'src/hooks/useApi';
import { urlJoin } from 'src/utils/util';


const useCampaignApi = () => {
  const { get } = useApi();
  return {
    getProjectCampaigns: async ({ projectId, params }) => (await get(urlJoin('folder', projectId, 'campaign'), params)).data,

    getReplicaStations: async campaignId => (await get(urlJoin('smaData', 'station-replicate'), { campaignId })).data,
    getOccurrences: async campaignId => (await get(urlJoin('smaData', 'occurrences'), { campaignId })).data,
    getCampaign: async campaignId => (await get(urlJoin('smaData', 'campaign', campaignId))).data,
  };
};


export { useCampaignApi };