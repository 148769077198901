import React from 'react';
import { Typography, Box, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { LabelChip } from 'src/components';
import { upperCaseOnlyFirstLetter as upofl } from 'src/utils/formatters';
import { Info } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
  container: {
    width: '70%',
  },
  title: {
    fontWeight: 500,
  },
  icon: {
    marginLeft: theme.spacing(0.5),
    cursor: 'pointer',
    fontSize: '14px',
    color: theme.palette.common.gray600,
  },
  labelDate: {
    width: 'fit-content',
  },
}));

const SmaCampaignCard = ({ name, rcaIds, instances, startDate, endDate, objective, type }) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" flexGrow={1} className={classes.container}>

      <Box display='flex' flexDirection="column" sx={{ p: 1, width: '60%' }}>
        <Box display="flex" alignItems="center">
          <Typography noWrap className={classes.title} variant="h6" color="textPrimary">
            {name}
          </Typography>
          <Tooltip title={
            <>
              <Box><strong>{name}</strong></Box>
              {objective && <Box>Objetivo: {objective}</Box>}
            </>
          }>
            <Info className={classes.icon} />
          </Tooltip>
        </Box>
        <Box pb={0.5} display="flex">
          <Box px={0.25}><LabelChip label={type} color="common.gray800" /></Box>
          {rcaIds.length > 0 &&
            rcaIds.map(rca => <Box key={rca} px={0.25}><LabelChip label={`RCA | ${rca}`} color="primary" /></Box>)
          }
          {instances.length > 0 &&
            instances.map(i => <Box key={i.id} px={0.25}><LabelChip label={`Plan | ${upofl(i.planName)}`} color="gray600" /></Box>)
          }
        </Box>
      </Box>
      <Box display='flex' flexDirection="column" alignItems="center" sx={{ p: 1, width: '40%' }}>
        <Typography variant="caption">Fecha de campaña</Typography>
        <LabelChip className={classes.labelDate} color="default" variant="outlined" label={`${startDate} - ${endDate}`}></LabelChip>
      </Box>
    </Box>
  );
};

SmaCampaignCard.propTypes = {
  name: PropTypes.string,
  rcaIds: PropTypes.array,
  instances: PropTypes.array,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  objective: PropTypes.string,
  type: PropTypes.string,
};


export { SmaCampaignCard };


