import { useEffect, useState } from 'react';

import { objectIsEmpty as oie } from 'src/utils/util';
import { useUserApi } from 'src/services';


const useFilters = ({ queryParams, history, location, filtersThatNeedLabels }) => {

  const [ filters, setFilters ] = useState([]);
  const [ filtersLabels, setFiltersLabels ] = useState();
  const userApi = useUserApi();

  const query = new URLSearchParams(location.search);

  const setFilter = async ({ id, value, label = '' }) => {
    const queryForPath = new URLSearchParams(location.search);

    const filter = { [id]: value };

    const filtersObj = { ...filter };

    if (id !== 'page') {
      filtersObj.page = 1;
    }

    addFiltersToQuery(queryForPath, filtersObj);
    setFilters(prev => ({ ...prev, ...filter }));
    label && setFiltersLabels(prev => ({ ...prev, [id]: label }));

    const queryForPathString = queryForPath.toString();
    await history.push({ pathname: location.pathname, search: `?${queryForPathString}` });
  };

  const addFiltersToQuery = (query, filters) => {
    Object.keys(filters).forEach(key => {
      if (Array.isArray(filters[key])) {
        query.delete(key);
        filters[key].forEach(val => query.append(key, val));
      } else if (typeof filters[key] === 'boolean') {
        filters[key] ? query.set(key, filters[key]) : query.delete(key);
      } else if (filters[key] !== null) {
        query.set(key, filters[key]);
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      Object.keys(filtersThatNeedLabels).forEach(key => filtersThatNeedLabels[key] === null ? delete filtersThatNeedLabels[key] : {});
      const filtersLabels = oie(filtersThatNeedLabels) ? {} : await userApi.getLabels(filtersThatNeedLabels);
      setFilters(queryParams);
      setFiltersLabels(filtersLabels);
    };
    fetchData();
    // eslint-disable-next-line
  }, [ ]);

  addFiltersToQuery(query, filters);

  return { setFilter, filtersLabels, filters, query };
};


export { useFilters };