import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Popover, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

import { LabelChip } from 'src/components';
import { upperCaseOnlyFirstLetter as ucofl } from 'src/utils/formatters';
import { truncateWithEllipsis, getRandomBetweenRange as grbr } from 'src/utils/util';


const useStyles = makeStyles(theme => ({
  title: {
    fontSize: theme.typography.subtitle1.fontSize,
    color: ({ variant }) => (
      variant === 'light' ? theme.palette.common.gray600
      : variant === 'dark' ? theme.palette.common.lightGray
      : theme.palette.common.gray600
    ),
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  numberTitle: {
    marginLeft: theme.spacing(0.5),
    borderRadius: '8px',
    fontWeight: 500,
  },
  typography: {
    width: ({ isLoading }) => isLoading ? '80%' : '100%',
  },
  numberExtra: {
    fontWeight: 600,
    cursor: 'pointer',
  },
}));

const ListIndicator = props => {
  const { title, list, isLoading, color, labelChipProps, hideCount = false, maxItems = 0 } = props;
  const [ anchorEl, setAnchorEl ] = useState(null);
  const classes = useStyles({ isLoading });
  const filteredList = maxItems === 0 ? list : list.filter((_, idx) => idx <= maxItems - 1);
  const extraElements = maxItems === 0 ? 0 : list.length - maxItems;

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Box display="flex" flexDirection="column">
      <Box mb={1} display="flex" alignItems="center">
        <Typography className={classes.title} noWrap>{title}</Typography>
        {!hideCount && <LabelChip className={classes.numberTitle} label={list.length} color="gray600" />}
      </Box>
      <Box display="flex" flexWrap="wrap" gridGap={2}>
        {filteredList.length > 0 ?
          <>
            {filteredList.map((name, idx) =>
              <Box key={`${idx}-${name}`} lineHeight={1}>
                <LabelChip label={ucofl(truncateWithEllipsis(name, 20))} color={color} {...labelChipProps} />
              </Box>,
            )}
            {extraElements > 0 &&
              <>
                <Box lineHeight={1}>
                  <LabelChip
                    className={classes.numberExtra}
                    label={`+ ${extraElements}`}
                    color={color}
                    onClick={handleClick}
                    variant="outlined"
                    {...labelChipProps}
                  />
                </Box>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  <Box p={2} display="flex" flexWrap="wrap" gridGap={2} width="400px">
                    {list.map((name, idx) =>
                      <Box key={`${idx}-${name}`} lineHeight={1}>
                        <LabelChip label={ucofl(truncateWithEllipsis(name, 30))} color={color} {...labelChipProps} />
                      </Box>,
                    )}
                  </Box>
                </Popover>
              </>
            }
          </>
          :
          <Typography variant="body2" className={classes.typography} component="div">
            {isLoading ?
              <Box display="flex" flexDirection="column">
                <Skeleton animation="wave" variant="text" width={`${grbr(50, 100)}%`} />
                <Skeleton animation="wave" variant="text" width={`${grbr(30, 80)}%`} />
              </Box>
              : <>Aún no tienes {title}</>
            }
          </Typography>
        }
      </Box>
    </Box>

  );
};

ListIndicator.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
  isLoading: PropTypes.bool,
  color: PropTypes.string,
  labelChipProps: PropTypes.object,
  hideCount: PropTypes.bool,
  maxItems: PropTypes.number,
};


export { ListIndicator };