import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@material-ui/core';
import { useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';

import { useCampaignApi } from 'src/services';
import { DialogImagePreviewer, TitleContainer, ContentContainer, DialogWrap } from 'src/components';
import { upperCaseFirstLetter as ucfl, upperCaseFirstLetter } from 'src/utils/formatters';
import { OccurrencesList } from 'src/scenes/Project/scenes/Campaign/scenes/Occurrences/OccurrencesList';
import { getBaseBreadcrumbs } from 'src/scenes/Project/scenes/Campaign/SmaCampaignHelper';


const OccurrencesContainer = ({ campaign }) => {
  const match = useRouteMatch();

  const { campaignId, projectId } = match.params;
  const { name: campaignName } = campaign;

  const [ occurrences, setOccurrences ] = useState([]);
  const campaignApi = useCampaignApi();

  useEffect(() => {
    const fetchData = async () => {
      const occurrences = await campaignApi.getOccurrences(campaignId);
      occurrences.forEach(o => o.name = ucfl(o.epithet));
      setOccurrences(occurrences);
    };
    fetchData();
  // eslint-disable-next-line
  }, [ campaignId ]);

  const [ dialog, setDialog ] = useState({ isOpen: false, data: {}, type: '' });

  const openViewPhotoDialog = picturesInfo => setDialog({ isOpen: true, type: 'view-photos', data: picturesInfo });

  const closeDialog = () => setDialog({ isOpen: false, type: '' });

  const getDialogType = () => {
    switch (dialog.type) {
      case 'view-photos':
        return <DialogImagePreviewer
          pictures={dialog.data.pictures}
          title={upperCaseFirstLetter(dialog.data.title)}
          additionalClass='image-species-container'
        />;
      default:
        break;
    }
  };

  return (
    <Grid container>
      <TitleContainer maxWidth="xl" breadcrumbs={[ ...getBaseBreadcrumbs({ projectId }), { name: `Ocurrencias de ${campaignName}` } ]}>
        Ocurrencias
      </TitleContainer>
      <ContentContainer maxWidth="xl">
        <Box p={4}>
          <OccurrencesList values={occurrences} actions={{ openViewPhotoDialog }} />
        </Box>
      </ContentContainer>
      <DialogWrap maxWidth='sm' fullWidth onClose={closeDialog} aria-labelledby='form-dialog-title'
        open={dialog.isOpen}>
        {getDialogType()}
      </DialogWrap>
    </Grid>
  );
};

OccurrencesContainer.propTypes = {
  campaign: PropTypes.object,
};


export {
  OccurrencesContainer,
};