import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Grid, Box, Button, IconButton, Paper, Typography, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { AddBox, ArrowBack, Delete } from '@material-ui/icons';
import MomentUtils from '@date-io/moment';
import { ScrollContainer } from 'react-indiana-drag-scroll';
import { useAuth0 } from '@auth0/auth0-react';
import { DatePicker } from '@material-ui/pickers';

import { WebContext } from 'src/scenes/web-context';
import { TitleContainer, ContentContainer, ActionsBox, DialogWrap, ChartWrapper, AlertWrapper, MapWrapper,
  AccessControl, AutocompleteWrapper } from 'src/components';
import { SmaAnalyticCreateDialog, SmaMapCreateDialog, SmaGeoInfo, ProcessGeoInfoDialog }
  from 'src/scenes/Project/scenes/Analytics/components';
import { useMonitoringPlanApi, useGeoInfoApi } from 'src/services';
import { buildChartData, chartDefaultOptions, buildLeafletLayer, getLegend, buildOccurrencesTimedimensionLayer,
  htmlLegendPlugin, buildLeafletLayerFromTile } from 'src/scenes/Project/scenes/Analytics/smaAnalyticsHelper';
import { deepObjClone, createForm, getRandomNviroColor } from 'src/utils/util';

import { upperCaseOnlyFirstLetter as ucofl } from 'src/utils/formatters';
import { useFetchStatus } from 'src/hooks';


const { moment } = new MomentUtils();

const useStyles = makeStyles(theme => ({
  backArrow: {
    color: theme.palette.common.gray700,
    padding: 0,
    marginRight: theme.spacing(1),
  },
  paper: {
    height: '100%',
    '&:hover': {
      '& $actionIcons': {
        visibility: 'visible',
        animationName: '$fadeIn',
        animation: 'linear',
        animationDuration: '300ms',
      },
    },
    padding: theme.spacing(2),
  },
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  actionIcons: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.gray600,
    cursor: 'pointer',
    visibility: 'hidden',
    margin: theme.spacing(0.25),
    '&:hover': {
      backgroundColor: theme.palette.common.gray400,
    },
  },
  map: {
    borderRadius: theme.shape.borderRadius,
  },
  scrollbar: {
    '&::-webkit-scrollbar': {
      width: theme.spacing(1),
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '6px',
      backgroundColor: theme.palette.common.gray300,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '6px',
      backgroundColor: '#555',
    },
  },
}));

const mapOptions = {
  zoom: 8,
  doubleClickZoom: true,
  attributionControl: false,

  timeDimension: true,
  timeDimensionControl: true,
};

const SmaAnalyticsContainer = props => {
  const classes = useStyles();

  const history = useHistory();
  const match = useRouteMatch();
  const monitoringPlanApi = useMonitoringPlanApi();
  const geoInfoApi = useGeoInfoApi();

  const minDate = useMemo(() => new Date(2017, 0, 1), []);
  const maxDate = useMemo(() => {
    const maxDate = new Date();
    maxDate.setMonth(maxDate.getMonth() - 2);
    return maxDate;
  }, []);

  const { fetchStatus, setFetchStatus, fetchStatusEnum, errorMessage, setErrorMessage } = useFetchStatus({});
  const { LOADING, SUCCESS, ERROR } = fetchStatusEnum;

  const { projectId, monitoringPlanId } = match.params;

  const webContext = useContext(WebContext);

  const [ monitoringPlan, setMonitoringPlan ] = useState();
  const [ analyticsCharts, setAnalyticsCharts ] = useState([]);
  const [ analyticMap, setAnalyticMap ] = useState({ layers: [], occurrencesLayer: {},
    displayOnMap: { tileData: false }, deletePermission: false, hasSatInfo: false });
  const [ chartTypeOptions, setChartTypeOptions ] = useState([ { label: '', value: '' } ]);
  const [ geoInfo, setGeoInfo ] = useState({ data: undefined, hasData: false, inProgress: false });
  const [ tileData, setTileData ] = useState({ data: undefined, hasData: false, inProgress: false });
  const [ tileOptions, setTileOptions ] = useState([ { label: '', value: '' } ]);
  const [ mapsGeoTiles, setMapsGeoTiles ] = useState({ leftDate: minDate, rightDate: maxDate, minDate, maxDate });
  const [ tilesComp, setTilesComp ] = useState({});

  const plansUrl = `/web/projects/${projectId}/monitoring-plans`;
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const fetchData = async () => {
      const [ monitoringPlan ] = await monitoringPlanApi.getMonitoringPlans({ monitoringPlanId });
      setMonitoringPlan(monitoringPlan);
    };
    fetchData();
    //eslint-disable-next-line
  }, [ monitoringPlanId ]);


  useEffect(() => {

    const fetchData = async () => {
      try {
        setFetchStatus(LOADING);

        const [ analyticsData, chartTypes, analyticsMapsData, geoInfo, tileData, tileOptions ] = await Promise.all([
          monitoringPlanApi.getAnalyticsData({ monitoringPlanId }),
          monitoringPlanApi.getChartTypes(monitoringPlanId),
          monitoringPlanApi.getAnalyticsMaps({ monitoringPlanId }),
          monitoringPlanApi.getGeoInfo({ monitoringPlanId }),
          monitoringPlanApi.getTileData({ monitoringPlanId }),
          geoInfoApi.getTileOptions(),
        ]);

        const chartTypeOptions = chartTypes.map(cto => ({ label: cto.name, value: cto.id }));
        setTileOptions(tileOptions);

        const analyticsCharts = analyticsData.map(analytic => {
          const { name: title } = analytic;
          const { separatedKey, separatedInfo, isStacked, chartJsType = 'bar', sort, isLogarithmic,
            valueToChart, valuesToChart } = analytic.config;
          const fixedValToChart = valueToChart ? [ valueToChart ] : valuesToChart;
          const chart = buildChartData({
            valuesToChart: fixedValToChart,
            separatedKey,
            separatedInfo,
            dataToChart: analytic.data,
            hasSomeSeparatedData: chartTypeOptions.map(cto => cto.value).includes(separatedKey) || fixedValToChart.length > 1,
            sortType: sort,
          });

          const chartOptions = deepObjClone(chartDefaultOptions);
          chartOptions.scales.x.stacked = isStacked;
          chartOptions.scales.y = { stacked: isStacked, ...(isLogarithmic ? { type: 'logarithmic' } : {}) };
          chartOptions.plugins.htmlLegend = { containerId: analytic.id };

          return { id: analytic.id, title, chart, options: chartOptions, chartJsType };
        });

        const layers = analyticsMapsData.layers.map(amd => buildLeafletLayer({
          features: amd.geojson,
          id: amd.id,
          name: amd.name,
          color: getRandomNviroColor(),
        }));
        const tilesComp = {
          hasData: tileData.hasData,
        };

        if (tileData.hasData) {
          tilesComp['display'] = true;
          const selectedTileData = tileOptions.find(tile => tile.value === geoInfo.data.mapConfig.tile);
          setMapsGeoTiles({
            leftDate: geoInfo.data.mapConfig.leftDate,
            rightDate: geoInfo.data.mapConfig.rightDate,
            tile: selectedTileData,
            minDate: selectedTileData.minDate ? new Date(selectedTileData.minDate) : minDate,
            maxDate: selectedTileData.maxDate ? new Date(selectedTileData.maxDate) : maxDate,
          });

          tilesComp['left'] = {
            satelital: buildLeafletLayerFromTile(tileData.tilesUrl.left.satelital),
            ...(tileData.tilesUrl.left.indicator ? { indicator: buildLeafletLayerFromTile(tileData.tilesUrl.left.indicator) } : {}),
          };
          tilesComp['right'] = {
            satelital: buildLeafletLayerFromTile(tileData.tilesUrl.right.satelital),
            ...(tileData.tilesUrl.right.indicator ? { indicator: buildLeafletLayerFromTile(tileData.tilesUrl.right.indicator) } : {}),
          };
        }
        setTilesComp(tilesComp);
        const timestampDates = analyticsMapsData.allDates.map(({ date }) => moment(date, 'DD/MM/YYYY').valueOf());

        setAnalyticsCharts(analyticsCharts);
        let token;
        try {
          token = await getAccessTokenSilently();
        } catch (e) {
          console.error(e);
          await loginWithRedirect();
          return;
        }
        const deletePermission = 'monitoring-plan:delete-interest-area';
        const { currentUser: { roleId }, permissions } = webContext;

        setAnalyticMap({
          layers,
          occurrencesLayer: buildOccurrencesTimedimensionLayer(analyticsMapsData.occurrences, token),
          hasSatInfo: tileData.hasData,
          dates: timestampDates,
          displayOnMap: { tileData: tilesComp.display },
          deletePermission: permissions?.includes(deletePermission),
          legend: getLegend({ deleteInterestArea, layers, hasOccurrencesLayer: analyticsMapsData.occurrences.length > 0,
            hideTileLayer, displayOnMap: { tileData: tilesComp.display },
            deletePermission: permissions[roleId]?.includes(deletePermission),
            hasSatInfo: tileData.hasData,
          }),
        });
        setChartTypeOptions(chartTypeOptions);
        setGeoInfo(geoInfo);

        setFetchStatus(SUCCESS);

      } catch (e) {
        console.error(e);
        setErrorMessage(e.serverMessage || e.message);
        setFetchStatus(ERROR);
      }
    };

    if (webContext.permissions) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [ monitoringPlanId, webContext.permissions ]);

  const [ dialog, setDialog ] = useState({ isOpen: false, data: {}, type: '' });

  const closeDialog = () => setDialog({ isOpen: false, type: '' });

  const openCreateAnalyticDialog = () => setDialog({ isOpen: true, type: 'create' });

  const openCreateMapDialog = () => setDialog({ isOpen: true, type: 'create-map' });

  const processInterestArea = async() => {
    await monitoringPlanApi.processInterestArea({ monitoringPlanId });
    setGeoInfo(prev => ({ ...prev, hasData: true, inProgress: true }));
    setDialog({ isOpen: true, type: 'geo-info' });
    setTilesComp({});
  };

  const getDialogType = () => {
    switch (dialog.type) {
      case 'create' : {
        return <SmaAnalyticCreateDialog actions={{ saveAnalytic, closeDialog }} options={{ chartTypeOptions }} />;
      }
      case 'create-map': {
        return <SmaMapCreateDialog actions={{ saveAnalyticMap, closeDialog }} />;
      }
      case 'geo-info': {
        return <ProcessGeoInfoDialog actions={{ closeDialog }}/>;
      }
      default:
        break;
    }
  };

  const saveAnalytic = async ({ monitoringPlanId, chartData, chartPreview }) => {
    const chart = await monitoringPlanApi.saveAnalytic({ monitoringPlanId, chartData });
    const options = deepObjClone(chartPreview.options);
    options.plugins.htmlLegend = { containerId: chart.id };
    setAnalyticsCharts([
      ...analyticsCharts,
      {
        id: chart.id,
        title: chartPreview.title,
        chart: chartPreview.chart,
        options,
        chartJsType: chartPreview.chartJsType,
      },
    ]);
  };

  const deleteAnalytic = async analyticId => {
    await monitoringPlanApi.deleteAnalytic(analyticId);
    setAnalyticsCharts(prevA => prevA.filter(analytic => analytic.id !== analyticId));
  };

  const saveAnalyticMap = async interestArea => {
    const form = createForm(interestArea.config);
    form.append('kmlFile', interestArea.kmlData.kmlFile, interestArea.kmlData.fileName);
    const { status, areaOfInterestKmlId } = await monitoringPlanApi.importInterestArea({ monitoringPlanId, interestArea: form });

    const leafletLayer = buildLeafletLayer({
      features: [ interestArea.layer.geojson ],
      id: areaOfInterestKmlId,
      name: interestArea.layer.name,
    });

    setAnalyticMap(prev => {
      const layers = status === 'created' ?
        [ leafletLayer, ...analyticMap.layers ]
        : prev.layers.map(layer => layer.options.id === areaOfInterestKmlId ? leafletLayer : layer);
      prev.legend.remove();
      return ({
        ...prev,
        layers,
        legend: getLegend({ deleteInterestArea, layers, hasOccurrencesLayer: Boolean(prev.occurrencesLayer),
          hideTileLayer, displayOnMap: prev.displayOnMap, deletePermission: prev.deletePermission, hasSatInfo: prev.hasSatInfo }),
      });
    });
  };

  const deleteInterestArea = async areaOfInterestKmlId => {
    await monitoringPlanApi.deleteInterestArea({ monitoringPlanId, areaOfInterestKmlId });
    setAnalyticMap(prev => {
      prev.legend.remove();
      const layers = prev.layers.filter(layer => layer.options.id !== areaOfInterestKmlId);
      return ({
        ...prev,
        layers,
        hasSatInfo: false,
        legend: getLegend({ deleteInterestArea, layers, hasOccurrencesLayer: Boolean(prev.occurrencesLayer),
          hideTileLayer, displayOnMap: prev.displayOnMap,
          deletePermission: prev.deletePermission, hasSatInfo: false }),
      });
    });
    setGeoInfo(prev => ({ ...prev, hasData: false }));
    setTilesComp({ hasData: false });
  };

  const hideTileLayer = () => {
    setTilesComp(prev => ({ ...prev, display: !prev.display }));
    setAnalyticMap(prev => {
      prev.legend.remove();
      return ({
        ...prev,
        displayOnMap: { tileData: !prev.displayOnMap.tileData },
        legend: getLegend({ deleteInterestArea, layers: prev.layers, hasOccurrencesLayer: Boolean(prev.occurrencesLayer),
          hideTileLayer, displayOnMap: { tileData: !prev.displayOnMap.tileData },
          deletePermission: prev.deletePermission, hasSatInfo: prev.hasSatInfo }),
      });
    });
  };

  const updateTileConfig = async mapsTilesConfig => {
    if (geoConfigIsValid(mapsTilesConfig)) {
      setTileData(prev => ({ ...prev, inProgress: true }));
      const mapConfig = {
        leftDate: mapsTilesConfig.leftDate,
        rightDate: mapsTilesConfig.rightDate,
        tile: mapsTilesConfig.tile.value,
      };
      await geoInfoApi.saveMapConfig({ geoDataId: geoInfo.data.id, mapConfig });
      const [ newGeoInfo, tiles ] = await Promise.all([
        monitoringPlanApi.getGeoInfo({ monitoringPlanId }),
        monitoringPlanApi.getTileData({ monitoringPlanId }),
      ]);
      setGeoInfo(newGeoInfo);
      const tilesComp = { hasData: tileData.hasData };
      if (tiles.hasData) {
        tilesComp['display'] = true;
        tilesComp['left'] = {
          satelital: buildLeafletLayerFromTile(tiles.tilesUrl.left.satelital),
          ...(tiles.tilesUrl.left.indicator ? { indicator: buildLeafletLayerFromTile(tiles.tilesUrl.left.indicator) } : {}),
        };
        tilesComp['right'] = {
          satelital: buildLeafletLayerFromTile(tiles.tilesUrl.right.satelital),
          ...(tiles.tilesUrl.right.indicator ? { indicator: buildLeafletLayerFromTile(tiles.tilesUrl.right.indicator) } : {}),
        };

        setAnalyticMap(prev => {
          prev.legend.remove();
          return ({
            ...prev,
            displayOnMap: { tileData: true },
            hasSatInfo: true,
            legend: getLegend({ deleteInterestArea, layers: prev.layers, hasOccurrencesLayer: Boolean(prev.occurrencesLayer),
              hideTileLayer, displayOnMap: { tileData: true },
              deletePermission: prev.deletePermission, hasSatInfo: true }),
          });
        });
      }
      setTilesComp(tilesComp);
      setTileData(prev => ({ ...prev, inProgress: false }));
    }
  };

  const handleChange = fieldKey => async (_, selectedValue) => {
    const selectedTileData = tileOptions.find(tile => tile.value === selectedValue.value);

    const newMapsGeoTiles = {
      ...mapsGeoTiles,
      minDate: selectedTileData.minDate ? new Date(selectedTileData.minDate) : mapsGeoTiles.minDate,
      maxDate: selectedTileData.maxDate ? new Date(selectedTileData.maxDate) : mapsGeoTiles.maxDate,
    };
    newMapsGeoTiles[fieldKey] = selectedValue;

    if (newMapsGeoTiles.leftDate < newMapsGeoTiles.minDate) {
      newMapsGeoTiles.leftDate = newMapsGeoTiles.minDate;
    } else if (newMapsGeoTiles.maxDate < newMapsGeoTiles.leftDate) {
      newMapsGeoTiles.leftDate = newMapsGeoTiles.maxDate;
    }

    if (newMapsGeoTiles.rightDate < newMapsGeoTiles.minDate) {
      newMapsGeoTiles.rightDate = newMapsGeoTiles.minDate;
    } else if (newMapsGeoTiles.maxDate < newMapsGeoTiles.rightDate) {
      newMapsGeoTiles.rightDate = newMapsGeoTiles.maxDate;
    }
    setMapsGeoTiles(newMapsGeoTiles);
    await updateTileConfig(newMapsGeoTiles);
  };

  const geoConfigIsValid = mapsGeoTiles =>
    mapsGeoTiles.leftDate && mapsGeoTiles.rightDate && mapsGeoTiles.tile;

  const changeTime = fieldKey => async time => {
    const newMapsGeoTiles = { ...mapsGeoTiles };
    if (mapsGeoTiles.minDate <= time && time <= mapsGeoTiles.maxDate) {
      newMapsGeoTiles[fieldKey] = time;
    } else if (time < mapsGeoTiles.minDate) {
      newMapsGeoTiles[fieldKey] = mapsGeoTiles.minDate;
    } else {
      newMapsGeoTiles[fieldKey] = mapsGeoTiles.maxDate;
    }
    setMapsGeoTiles(newMapsGeoTiles);
    await updateTileConfig(newMapsGeoTiles);
  };

  return (
    <>
      <Grid container>
        <TitleContainer
          maxWidth="xl"
          breadcrumbs={[
            'projects',
            { name: `Planes de monitoreo de ${ucofl(props.name)}`, url: plansUrl },
            { name: `Analíticas de ${ucofl(monitoringPlan?.name || '')}` },
          ]}
        >
          <Box display="flex" alignItems="center">
            <IconButton className={classes.backArrow} onClick={() => history.push(plansUrl)}>
              <ArrowBack fontSize='large' color="inherit" />
            </IconButton>
            Analíticas
          </Box>
        </TitleContainer>
        <ContentContainer maxWidth="xl">
          <ActionsBox mt={2} mb={1} display="flex" alignItems="flex-end">
            <AccessControl action='monitoring-plan:create-chart'>
              <Box display="flex" height="30px">
                <Button
                  disabled={fetchStatus === LOADING}
                  size="small"
                  color="primary"
                  startIcon={<AddBox />}
                  variant="contained"
                  onClick={openCreateAnalyticDialog}
                >
                  Agregar analítica
                </Button>
              </Box>
            </AccessControl>
            <AccessControl action='monitoring-plan:upload-interest-area'>
              <Box ml={1} flexGrow={1} display="flex" height="30px">
                <Button
                  disabled={fetchStatus === LOADING}
                  size="small"
                  color="primary"
                  startIcon={<AddBox />}
                  variant="contained"
                  onClick={openCreateMapDialog}
                >
                  Agregar área de interés
                </Button>
              </Box>
            </AccessControl>
            {analyticMap.layers.length > 0 &&
              <AccessControl action='monitoring-plan:process-interest-area'>
                <Box display="flex" height="30px">
                  <Button
                    disabled={fetchStatus === LOADING || geoInfo.inProgress}
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={processInterestArea}
                  >
                    {`${geoInfo.inProgress ? 'Procesando área de interés...' : 'Procesar área de interés'}` }
                  </Button>
                </Box>
              </AccessControl>
            }
          </ActionsBox>
          <Grid container spacing={1}>
            {fetchStatus === ERROR && <Box py={1}><AlertWrapper severity="error">{errorMessage}</AlertWrapper></Box>}

            {analyticMap.layers.length > 0 &&
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  {(geoInfo.hasData && !geoInfo.inProgress) &&
                    <Box display="flex">
                      <Box width="100%" display="flex">
                        <Grid container>
                          <Box width="100%" display="flex">
                            <Grid item xs={4}>
                              <Box mb={3}>
                                <Typography variant="body2" required>Fecha sección izquierda</Typography>
                                <DatePicker value={mapsGeoTiles?.leftDate} selected={mapsGeoTiles?.leftDate} autoOk
                                  TextFieldComponent={props => <TextField {...props} variant="outlined" size="small" />}
                                  views={[ 'year', 'month' ]}
                                  minDate={mapsGeoTiles.minDate}
                                  maxDate={mapsGeoTiles.maxDate}
                                  format="MM-YYYY"
                                  onChange={changeTime('leftDate')}
                                  disabled={tileData.inProgress}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={4}>
                              <Box ml={3} mb={3}>
                                <Typography variant="body2" required>Fecha sección derecha</Typography>
                                <DatePicker value={mapsGeoTiles?.rightDate} selected={mapsGeoTiles?.rightDate} autoOk
                                  TextFieldComponent={props => <TextField {...props} variant="outlined" size="small" />}
                                  views={[ 'year', 'month' ]}
                                  minDate={mapsGeoTiles.minDate}
                                  maxDate={mapsGeoTiles.maxDate}
                                  format="MM-YYYY"
                                  onChange={changeTime('rightDate')}
                                  disabled={tileData.inProgress}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={4}>
                              <Box ml={3} mb={3}>
                                <Typography variant="body2" required>Indicador</Typography>
                                <AutocompleteWrapper
                                  disabled={tileData.inProgress}
                                  value={mapsGeoTiles?.tile || {}}
                                  options={tileOptions}
                                  onChange={handleChange('tile')}
                                  getOptionSelected={(option, value) => (option.value === value.value || value.value === '')}
                                />
                              </Box>
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    </Box>
                  }
                  <MapWrapper
                    id="map"
                    className={classes.map}
                    options={{
                      ...mapOptions,
                      timeDimensionOptions: {
                        currentTime: analyticMap.dates[0],
                        times: analyticMap.dates,
                      },
                      timeDimensionControl: analyticMap.dates.length > 1,
                    }}
                    height={400}
                    layers={[
                      ...analyticMap.layers,
                      ...(analyticMap.occurrencesLayer ? [ analyticMap.occurrencesLayer ] : []),
                    ]}
                    legend={analyticMap.legend}
                    fitBoundsOnUpdate
                    comp={ tilesComp }
                  />
                  {(geoInfo.hasData && !geoInfo.inProgress) &&
                    <Box marginTop={2}>
                      <Grid item xs={12}>
                        <SmaGeoInfo geoInfo={geoInfo} />
                      </Grid>
                    </Box>
                  }
                </Paper>
              </Grid>
            }



            {analyticsCharts.map(ac =>
              <Grid key={`ac-${ac.id}`} item xs={6}>
                <Paper className={classes.paper}>
                  <Box p={2} display="flex" flexDirection="column" height="100%">
                    <AccessControl action='monitoring-plan:delete-chart'>
                      <Box display="flex" alignSelf="end">
                        <Delete fontSize="small" className={classes.actionIcons} onClick={() => deleteAnalytic(ac.id)} />
                      </Box>
                    </AccessControl>
                    <Box textAlign="center" display="flex" justifyContent="center">
                      <Typography variant="h6">{ac.title}</Typography>
                    </Box>
                    <br />
                    <Box display={ac.chart.datasets.length === 1 ? 'flex' : 'block' } height="100%" alignItems="flex-end">
                      <ScrollContainer hideScrollbars={false} className={classes.scrollbar}>
                        <Box id={ac.id} maxHeight={104} />
                      </ScrollContainer>
                      <ChartWrapper type={ac.chartJsType} options={ac.options} data={ac.chart} plugins={[ htmlLegendPlugin ]} />
                    </Box>
                  </Box>
                </Paper>
              </Grid>,
            )}
          </Grid>
        </ContentContainer>
      </Grid>
      <DialogWrap maxWidth="lg" fullWidth onClose={closeDialog} open={dialog.isOpen}>
        {getDialogType()}
      </DialogWrap>
    </>
  );
};

SmaAnalyticsContainer.propTypes = {
  name: PropTypes.string,
};


export {
  SmaAnalyticsContainer,
};