import React from 'react';
import PropTypes from 'prop-types';
import { Delete } from '@material-ui/icons';

import { Cards } from 'src/components';


const CaptureProjectList = ({ projects, actions }) => {

  const cardActions = itemData => ([
    {
      Icon: Delete,
      tooltip: 'Desvincular',
      onClick: () => actions.openRemoveImportedProjectDialog(itemData.captureProjectId),
      accessPermission: 'project:data-source-delete',
    },
  ]);

  return (
    <Cards values={projects} actions={cardActions} />
  );
};

CaptureProjectList.propTypes = {
  projects: PropTypes.array,
  actions: PropTypes.object,
};


export { CaptureProjectList };