import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  aboutUsSection: {
    paddingTop: theme.spacing(23),
    paddingBottom: theme.spacing(23),
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  aboutUsTitle: {
    fontSize: '4.375rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
      lineHeight: '58px',
      textAlign: 'center',
    },
  },
  aboutUsSubtitle: {
    paddingTop: theme.spacing(2),
    fontSize: '1.875rem',
    fontWeight: 500,
    maxWidth: '750px',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 400,
      fontSize: '1.75rem',
      lineHeight: '40px',
    },
  },
  aboutUsDescription: {
    paddingTop: theme.spacing(3),
    fontSize: '1.25rem',
    maxWidth: '900px',
    lineHeight: '40px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      fontSize: '1rem',
      textAlign: 'start',
      lineHeight: '32px',
    },
  },
  gridItem1: {
    order: 1,
  },
  gridItem2: {
    order: 2,
  },
  gridItem3: {
    order: 3,
    [theme.breakpoints.down('sm')]: {
      order: 4,
    },
  },
  gridItem4: {
    order: 4,
    [theme.breakpoints.down('sm')]: {
      order: 3,
    },
  },
  questionsSection: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  question: {
    paddingTop: theme.spacing(1),
    fontSize: '3.75rem',
    fontWeight: 600,
    color: theme.palette.common.gray600,
    width: '700px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.75rem',
      width: '100%',
    },
  },
  ask: {
    paddingTop: theme.spacing(4),
    fontSize: '1.25rem',
    lineHeight: '40px',
    width: '450px',
    color: theme.palette.common.black,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      textAlign: 'start',
      lineHeight: '32px',
      width: '100%',
    },
  },
  icon: {
    [theme.breakpoints.down('sm')]: {
      height: '75px',
    },
  },
  imageOne: {
    marginLeft: theme.spacing(8),
    height: '472px',
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
      width: '90%',
      height: 'unset',
    },
  },
  imageTwoContainer: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  imageTwo: {
    marginRight: theme.spacing(8),
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
      width: '90%',
      height: 'unset',
    },
  },
  ourValuesSection: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(12),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  ourValuesTitle: {
    fontSize: '3.75rem',
    fontWeight: 600,
    color: theme.palette.common.gray600,
    paddingBottom: theme.spacing(14),
  },
  valueCard: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    maxWidth: '310px',
    backgroundColor: 'rgba(47, 214, 229, 0.1)',
  },
  valueCardTitle: {
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
    fontSize: '2.25rem',
    fontWeight: 600,
  },
  valueCardText: {
    marginTop: theme.spacing(2),
    color: theme.palette.common.black,
    lineHeight: '40px',
    fontSize: '1.25rem',
  },
}));

const AboutUsContainer = () => {
  const classes = useStyles();

  useEffect(() => {
    document.title = 'Quiénes somos - Nviro Monitor';
  }, []);

  return (
    <Box className={classes.container}>
      <Grid className={classes.aboutUsSection} container alignItems="center" justifyContent="center" direction="column">
        <Grid item>
          <Typography className={classes.aboutUsTitle} color="primary">Acerca de nosotros</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.aboutUsSubtitle}>
            Somos una empresa concebida para dar soluciones tecnológicas a la industria ambiental.
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.aboutUsDescription}>
            Nuestro equipo está conformado por profesionales del área del medio ambiente y la informática, de espíritu joven,
            apasionados por vincular la naturaleza y la tecnología, con una dinámica de trabajo horizontal, en donde se
            considera la opinión y propuestas de cada uno de sus integrantes
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.questionsSection} container alignItems="center" justifyContent="center">
        <Grid className={classes.gridItem1} item xs={12} md={6}>
          <Box display="flex">
            <Box flexGrow={1}></Box>
            <Box mr={{ xs: 0, md: 5 }} mt={{ xs: 0, md: 5 }} display="flex" alignItems="center" flexDirection="column">
              <img className={classes.icon} src="/img/home/search-location.svg" alt="icon-one" />
              <Typography className={classes.question}>
                ¿Cuál es nuestra visión?
              </Typography>
              <Typography className={classes.ask}>
                Después de varios años de conocimiento, desarrollando soluciones tecnológicas y observar de cerca su evolución,
                hemos llegado al pleno convencimiento de que la transformación digital de la industria ambiental nacional e
                internacional es un hecho inminente, y ante ello, buscamos ser la empresa que lidere este proceso de transformación.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid className={classes.gridItem2} item xs={12} md={6} justifyContent="center" container>
          <img className={classes.imageOne} src="/img/home/about-us-question-one.svg" alt="question-one" />
        </Grid>
        <Grid className={classes.gridItem3} item xs={12} md={6}>
          <Box display="flex" mt={{ xs: 0, md: 15 }} className={classes.imageTwoContainer}>
            <img className={classes.imageTwo} src="/img/home/about-us-question-two.svg" alt="question-two" />
          </Box>
        </Grid>
        <Grid className={classes.gridItem4} item xs={12} md={6}>
          <Box display="flex">
            <Box ml={{ xs: 0, md: 5 }} mt={{ xs: 10, md: 6 }} display="flex" alignItems="center" flexDirection="column">
              <img className={classes.icon} src="/img/home/capture.svg" alt="icon-two" />
              <Typography className={classes.question}>
                ¿Qué hacemos?
              </Typography>
              <Typography className={classes.ask}>
                Nuestros desarrollos tienen como eje central incentivar y promover la transformación de la industria ambiental,
                haciendo que tanto los usuarios como nuestros clientes puedan utilizar de manera eficaz sus propios recursos.
                Estamos convencidos de que, si promovemos el uso de tecnologías aplicadas a la industria ambiental, sin duda ayudaremos
                a generar procesos más limpios, más democráticos y socioambientalmente más racionales.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};


export { AboutUsContainer };