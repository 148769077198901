import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';


const CaptureImageWrapper = ({ imageUrl, token }) => {

  const [ imageSrc, setImageSrc ] = useState('');

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(imageUrl, { headers: { 'Authorization': `Bearer ${token}` } });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        const imageObjectURL = URL.createObjectURL(blob);
        setImageSrc(imageObjectURL);
      } catch (error) {
        console.error('Error fetching the image:', error);
      }
    };

    fetchImage();
  }, [ imageUrl, token ]);

  return (
    <img
      src={imageSrc}
      alt="Occurrence image"
      height={150}
      style={{ border: '1px solid lightgrey', borderRadius: '6px', padding: '4px' }}
    />
  );
};

CaptureImageWrapper.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,

};

export { CaptureImageWrapper } ;