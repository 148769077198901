import React from 'react';
import PropTypes from 'prop-types';
import { Delete } from '@material-ui/icons';

import { Cards } from 'src/components';
import { LinkCodeCard } from 'src/scenes/Project/scenes/Import/components';


const CaptureCompanyList = ({ actions, linkCodes }) => {
  const cardActions = itemData => ([
    {
      Icon: Delete,
      tooltip: 'Desvincular',
      onClick: () => actions.openRemoveLinkCodeDialog(itemData.code),
      accessPermission: 'company:delete-link-code',
    },
  ]);

  return (
    <Cards
      values={linkCodes}
      actions={cardActions}
      Content={({ itemData }) => <LinkCodeCard {...itemData} />}
    />
  );
};

CaptureCompanyList.propTypes = {
  linkCodes: PropTypes.array,
  actions: PropTypes.object,
};


export { CaptureCompanyList };