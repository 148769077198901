import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';
import config from 'src/config/local';

import { AlertWrapper, DialogButton } from 'src/components';
import { useFetchStatus } from 'src/hooks';


const { maxSizeFileUpload } = config;

const SmaFileImportDialog = ({ actions }) => {

  const [ file, setFile ] = useState();

  const { fetchStatus, setFetchStatus, errorMessage, handleConfirm, fetchStatusEnum, hasError } = useFetchStatus({
    confirmFn: () => actions.importFile(file),
    validationFn: () => file.size < maxSizeFileUpload,
    closeFn: actions.closeDialog,
  });

  const { NOT_STARTED } = fetchStatusEnum;

  const handleChangeFile = e => {
    setFetchStatus(NOT_STARTED);
    setFile(e.target.files[0]);
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">
        Importar SMA Darwincore desde archivo
      </DialogTitle>
      <DialogContent>
        <Box p={1}>
          <input accept=".xls, .xlsx" id="contained-button-file" type="file" onChange={handleChangeFile} hidden/>
          <Box display="flex" flexDirection="column">
            <label htmlFor="contained-button-file">
              <Button variant="contained" color="primary" component="span">
                Seleccionar archivo Sma Darwincore
              </Button>
            </label>
            {file && <Box mt={1}><Typography variant="body2">{file.name}</Typography></Box>}
          </Box>
          {hasError &&
            <Box mt={4}>
              <AlertWrapper severity="error">
                {errorMessage}
              </AlertWrapper>
            </Box>
          }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Sí, importar</DialogButton>
      </DialogActions>
    </>
  );
};

SmaFileImportDialog.propTypes = {
  actions: PropTypes.object,
};


export {
  SmaFileImportDialog,
};
