import React from 'react';
import PropTypes from 'prop-types';
import { Delete } from '@material-ui/icons';

import { Cards } from 'src/components';


const CaptureCampaignList = ({ campaigns, actions }) => {

  const cardActions = itemData => ([
    {
      Icon: Delete,
      tooltip: 'Desvincular',
      onClick: () => actions.openRemoveImportedCampaignDialog(itemData.captureCampaignId),
      accessPermission: 'project:data-source-delete',
    },
  ]);

  return (
    <Cards values={campaigns} actions={cardActions} />
  );
};

CaptureCampaignList.propTypes = {
  campaigns: PropTypes.array,
  actions: PropTypes.object,
};


export { CaptureCampaignList };