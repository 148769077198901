import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography, Tooltip } from '@material-ui/core';
import { useRouteMatch } from 'react-router';

import { useCaptureApi } from 'src/services';
import { AlertWrapper, AutocompleteWrapper, DialogButton, LabelChip } from 'src/components';
import { useFetchStatus } from 'src/hooks';
import { truncateWithEllipsis as twe } from 'src/utils/util';


const emptyOption = { label: '', value: '' };

const CampaignImportDialog = ({ options, actions }) => {

  const [ selectedCompany, setSelectedCompany ] = useState(emptyOption);
  const [ selectedCampaigns, setSelectedCampaigns ] = useState([]);
  const [ importableCampaignsOptions, setImportableCampaignsOptions ] = useState([]);

  const match = useRouteMatch();
  const { projectId } = match.params;

  const hasSelectedCompany = Boolean(selectedCompany.value);

  const { linkedCompaniesOptions } = options;

  const captureApi = useCaptureApi();

  const { fetchStatus, setFetchStatus, errorMessage, fetchStatusEnum, handleConfirm, hasError, setErrorMessage } = useFetchStatus({
    confirmFn: () => actions.importCampaigns({ campaigns: selectedCampaigns, companyId: selectedCompany.value }),
    closeFn: actions.closeDialog,
  });

  const { NOT_STARTED, ERROR } = fetchStatusEnum;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const importableCampaigns = await captureApi.getImportableCampaigns({
          monitorProjectId: projectId,
          ...(hasSelectedCompany ? { captureCompanyId: selectedCompany.value } : {}),
        });

        setSelectedCampaigns([]);
        setImportableCampaignsOptions(importableCampaigns.map(lc => ({ label: lc.campaignName, value: lc.id, data: lc })));
      } catch (e) {
        console.error(e);
        setErrorMessage(e.serverMessage || e.message);
        setFetchStatus(ERROR);
      }
    };
    fetchData();
  // eslint-disable-next-line
  }, [ selectedCompany ]);

  const handleCompany = (_, selectedCompany) => {
    setSelectedCompany(selectedCompany);
    setFetchStatus(NOT_STARTED);
  };

  const handleCampaign = (_, selectedCampaigns) => {
    setSelectedCampaigns(selectedCampaigns);
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">
        Campañas a importar
      </DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Box my={2} >
            <Typography variant="body2" required>Compañía</Typography>
            <AutocompleteWrapper
              value={selectedCompany}
              options={linkedCompaniesOptions}
              onChange={handleCompany}
              getOptionSelected={(option, value) => (option.value === value.value || value.value === '')}
            />
          </Box>
          <Box my={2} >
            <Typography variant="body2" required>Campañas</Typography>
            <AutocompleteWrapper
              openOnFocus
              value={selectedCampaigns}
              options={importableCampaignsOptions}
              onChange={handleCampaign}
              getOptionSelected={(option, value) => option.value === value.value}
              renderOption={option => (
                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                  {option.label}
                  <Tooltip title={option.data?.projectName} placement="right">
                    <Box>
                      <LabelChip size="small" label={twe(`Proyecto | ${option.data?.projectName}`, 20)} color="gray800" />
                    </Box>
                  </Tooltip>
                </Box>
              )}
              multiple
            />
          </Box>
          {hasError && <AlertWrapper severity="error">{errorMessage}</AlertWrapper>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Vincular</DialogButton>
      </DialogActions>
    </>
  );
};

CampaignImportDialog.propTypes = {
  actions: PropTypes.object,
  options: PropTypes.object,
};


export {
  CampaignImportDialog,
};
