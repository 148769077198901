import React from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Delete, Terrain, Grain, Spa } from '@material-ui/icons';

import { Cards } from 'src/components';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles(theme => ({
  infoIcon: {
    fontSize: '14px',
    marginRight: theme.spacing(0.5),
  },
}));

const DocumentList = ({ documents, actions }) => {
  const classes = useStyles();

  const cardActions = itemData => ([
    {
      Icon: Delete,
      tooltip: 'Eliminar',
      onClick: () => actions.openDeleteFileDialog(itemData.id),
    },
  ]);

  const columns = [
    { title: 'Nombre de archivo', render: ({ name, nCampaigns, nReplicaStations, nOcurrences }) =>
      <Box display="flex" width="100%">
        <Box width="70%">
          <Tooltip title={name}>
            <Typography variant="body2" noWrap>{name}</Typography>
          </Tooltip>
        </Box>
        <Box flexGrow={1}></Box>
        <Box display="flex" mr={6} alignItems="flex-start">
          <Tooltip title="Campañas">
            <Box display="flex" alignItems="center" width="40px">
              <Terrain className={classes.infoIcon} />
              <Typography variant="caption" noWrap>{nCampaigns}</Typography>
            </Box>
          </Tooltip>
          <Tooltip title="Estaciones replica">
            <Box mx={1} display="flex" alignItems="center" width="40px">
              <Grain className={classes.infoIcon} />
              <Typography variant="caption" noWrap>{nReplicaStations}</Typography>
            </Box>
          </Tooltip>
          <Tooltip title="Ocurrencias">
            <Box display="flex" alignItems="center" width="48px">
              <Spa className={classes.infoIcon} />
              <Typography variant="caption" noWrap>{nOcurrences}</Typography>
            </Box>
          </Tooltip>
        </Box>
      </Box>,
    },
  ];

  return (
    <Cards values={documents} variant="condensed" columns={columns} actions={cardActions} />
  );
};

DocumentList.propTypes = {
  documents: PropTypes.array,
  actions: PropTypes.object,
};


export { DocumentList };