import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowForward } from '@material-ui/icons';
import { useHistory, useRouteMatch, useLocation } from 'react-router';

import { useCampaignApi, useProjectApi } from 'src/services';
import { TitleContainer, ContentContainer, Cards, Collapser, FilterText, FilterSelect } from 'src/components';
import { upperCaseFirstLetter, upperCaseOnlyFirstLetter } from 'src/utils/formatters';
import { removeUndefinedPropsFromObject as rupfo } from 'src/utils/util';
import { useFilters } from 'src/hooks';
import { SmaCampaignCard } from 'src/scenes/Project/scenes/Campaigns/components';
import { Pagination, PaginationItem } from '@material-ui/lab';


const rcaFilterKey = 'rca';
const monitoringPlanKey = 'monitoringPlan';
const campaignTypeKey = 'type';

const campaignTypesOptions = [
  { value: 'monitoring', label: 'Monitoreo' },
  { value: 'baseline', label: 'Linea base' },
];

const useStyles = makeStyles(theme => ({
  content: {
    maxWidth: theme.spacing(140),
  },
  defaultItem: {
    padding: theme.spacing(1),
  },
}));

const SmaCampaignsContainer = props => {
  const match = useRouteMatch();
  const history = useHistory();
  const classes = useStyles();
  const { search } = useLocation();
  const campaignApi = useCampaignApi();
  const projectApi = useProjectApi();

  const { projectId } = match.params;

  const [ campaigns, setCampaigns ] = useState([]);
  const [ campaignsCount, setCampaignsCount ] = useState(0);

  const getQueryParams = () => {
    const query = new URLSearchParams(location.search);

    const fixedSize = 10;
    query.set('size', fixedSize);
    const page = parseInt(query.get('page') || 1, 10);
    const rca = query.get(rcaFilterKey);
    const monitoringPlan = query.get(monitoringPlanKey);
    const type = query.getAll(campaignTypeKey).length ? query.getAll(campaignTypeKey) : [];

    return { page, size: fixedSize, rca, monitoringPlan, type };
  };

  const { page, size, rca, monitoringPlan, type } = getQueryParams();

  const { setFilter, filtersLabels, filters, query } = useFilters({
    queryParams: { rca, type },
    history,
    location,
    filtersThatNeedLabels: { monitoringPlan },
  });

  const transformToOptions = ({ searchResult }) =>
    searchResult.map(r => ({ ...r, label: upperCaseFirstLetter(r.name || r.id), value: r.id }));

  const getOptions = key => async ({ queryText, ...filters }) => {
    const searchApi = {
      rca: projectApi.rcaSearch,
      monitoringPlan: projectApi.monitoringPlanSearch,
    };
    const searchResult = await searchApi[key]({ queryText, projectId, ...filters });
    return transformToOptions({ searchResult });
  };

  useEffect(() => {
    const fetchData = async () => {
      const params = rupfo({ rcaId: rca, monitoringPlanId: monitoringPlan });
      query.delete('rca');
      query.delete('monitoringPlan');
      Object.keys(params).map(key => query.set(key, params[key]));
      const { totalCampaignsCount, campaignsList } = await campaignApi.getProjectCampaigns({ projectId, params: query.toString() });
      setCampaigns(campaignsList);
      setCampaignsCount(totalCampaignsCount);
    };
    fetchData();
  // eslint-disable-next-line
  }, [ /* companyId, */ search ]);

  const goToCampaign = campaign => {
    history.push(`/web/projects/${projectId}/campaigns/${campaign.id}`);
  };

  const cardActions = itemData => ([
    {
      Icon: ArrowForward,
      tooltip: 'Ver campaña',
      onClick: () => goToCampaign(itemData),
    },
  ]);

  const totalPages = Math.ceil(campaignsCount / size);

  return (
    <Grid container>
      <TitleContainer maxWidth="xl" breadcrumbs={[
        'projects', { name: `Campañas de ${upperCaseOnlyFirstLetter(props.name)}`, url: 'campaigns' } ]}>
        Campañas
      </TitleContainer>
      <ContentContainer maxWidth="xl" className={classes.content}>
        <Box my={1}>
          <Collapser title="Filtros">
            {filtersLabels &&
              <>
                <Grid container spacing={1}>
                  <Grid item>
                    <FilterText
                      id={rcaFilterKey}
                      label="RCA"
                      prevValue={{ label: rca, value: rca }}
                      getOptions={getOptions(rcaFilterKey)}
                      onChange={setFilter}
                    />
                  </Grid>
                  <Grid item>
                    <FilterText
                      id={monitoringPlanKey}
                      label="Plan de monitoreo"
                      prevValue={{ label: filtersLabels[monitoringPlanKey], value: monitoringPlan }}
                      getOptions={getOptions(monitoringPlanKey)}
                      onChange={setFilter}
                    />
                  </Grid>
                  <Grid item>
                    <FilterSelect
                      id={campaignTypeKey}
                      label="Tipo de campaña"
                      prevValue={filters[campaignTypeKey]}
                      options={campaignTypesOptions}
                      onChange={setFilter}
                    />
                  </Grid>
                </Grid>
              </>
            }
          </Collapser>
        </Box>
        <Divider />
        <br />
        <Cards
          values={campaigns}
          actions={cardActions}
          Content={({ itemData }) => <SmaCampaignCard {...itemData} />}
          classes={{ defaultItem: classes.defaultItem }}
        />
        {campaignsCount > size &&
            <Box pt={3} display="flex" justifyContent="center">
              <Pagination
                page={page}
                count={totalPages}
                variant="outlined"
                shape="rounded"
                color="primary"
                onChange={(_, page) => setFilter({ id: 'page', value: page })}
                renderItem={item => (
                  <PaginationItem {...item} />
                )}
              />
            </Box>
        }
        <br />
      </ContentContainer>
    </Grid>
  );
};

SmaCampaignsContainer.propTypes = {
  name: PropTypes.string,
};


export {
  SmaCampaignsContainer,
};