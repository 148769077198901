import { isEmpty } from 'src/utils/util';


const upperCaseFirstLetter = (str = '') => str.replace(/^(.)/, m => m.toUpperCase());

const species = ({ genus, species, subspecies, variety, family }) => upperCaseFirstLetter(
  [ genus, species, subspecies, variety, family ]
    .join(' ')
    .replace(/ +/g, ' ')
    .trim());

const upperCaseOnlyFirstLetter = string => !isEmpty(string) ? string[0].toUpperCase() + string.slice(1).toLowerCase() : '';


export {
  upperCaseFirstLetter,
  species,
  upperCaseOnlyFirstLetter,
};