import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';

import { AlertWrapper, DialogButton } from 'src/components';
import { useFetchStatus } from 'src/hooks';


const AuditableUnitUnlinkDialog = ({ auditableUnitId, titleHolder, auditableUnitName, actions }) => {

  const { fetchStatus, errorMessage, handleConfirm, hasError } = useFetchStatus({
    confirmFn: () => actions.unlinkAuditableUnit({ auditableUnitId, titleHolder }),
    closeFn: actions.closeDialog,
  });

  return (
    <>
      <DialogTitle id="form-dialog-title">
        Desvincular {auditableUnitName}
      </DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Typography variant="body1" color="textPrimary">
            Al desvincular esta unidad fiscalizable <strong>perderás la información asociada a los planes de monitoreo y/o
            analíticas </strong>que estén utlizando campañas pertenecientes a esta unidad fiscalizable.
          </Typography>
          <Box py={2} />
          <Typography variant="body1" color="textPrimary">
            ¿Realmente deseas desvincular la unidad fiscalizable?
          </Typography>
          {hasError && <><Box py={1}><AlertWrapper severity="error">{errorMessage}</AlertWrapper></Box></>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Si, desvincular</DialogButton>
      </DialogActions>
    </>
  );
};

AuditableUnitUnlinkDialog.propTypes = {
  actions: PropTypes.object,
  auditableUnitId: PropTypes.string,
  titleHolder: PropTypes.string,
  auditableUnitName: PropTypes.string,
};


export {
  AuditableUnitUnlinkDialog,
};
